import React from "react";
import { Modal } from "react-bootstrap";
import "./CenterModel.scss";

const CenterModel = ({
  modelTitle,
  show,
  handleClose,
  onUpload,
  children,
  modalTitleIcon,
  className = "",
}) => {
  return (
    <>
      <Modal
        className={`model-center-main w-35 ${className}`}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <span className="modal-icon">
            {/* <Image imagePath={modalTitleIcon} altText="Title Icon"></Image> */}
          </span>
          <Modal.Title>{modelTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you are reading this text in a modal! */}
          <div className="body-part">
            {children}
          </div>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};
export default CenterModel;