import { GridColumnType } from "../../../data/gridColumnType";

export const ReportListTableConfig = {
    columns: [
        {
            name: "Generation Date & Time",
            fieldName: "createdAt",
            colType: GridColumnType.DATE,
            allowShort: false,
            colSettings: {
                format: "DD MMM YYYY HH:mm",
            },
        },
        // {
        //     name: "Start Date",
        //     fieldName: "startDate",
        //     colType: GridColumnType.DATE,
        //     allowShort: false,
        //     colSettings: {
        //         format: "DD MMM YYYY ",
        //     },
        // },
        // {
        //     name: "End Date",
        //     fieldName: "endDate",
        //     colType: GridColumnType.DATE,
        //     allowShort: false,
        //     colSettings: {
        //         format: "DD MMM YYYY ",
        //     },
        // },
        {
            name: "Type Of Membership",
            fieldName: "membershipType",
            allowShort: false,
        },

        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
                allowDownload: true,
            },
        },
    ],
};