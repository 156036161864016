import { FormFieldTypes } from "../../../../../data/formFieldType";

const InstitutionDetailsData = {
    name: "InstitutionDetailsData",
    initialState: {
        nameOfInstitutionAsinAcra: "",
        uenNo: "",
        institutionAddressLine1: "",
        institutionAddressLine2: "",
        institutionAddressLine3: "",
        postalCode: "",
        acraBizFile: "",
    },
    formFields: [
        {
            id: "nameOfInstitutionAsinAcra",
            lable: "Name of Institution as in ACRA",
            Field_Name: "Name of Institution as in ACRA",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameOfInstitutionAsinAcra",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "uenNo",
            lable: "UEN No.",
            Field_Name: "UEN No.",
            fieldType: FormFieldTypes.INPUT,
            dataField: "uenNo",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "institutionAddressLine1",
            lable: "Institution Address Line 1",
            Field_Name: "Institution Address Line 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionAddressLine1",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "institutionAddressLine2",
            lable: "Institution Address Line 2",
            Field_Name: "Institution Address Line 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionAddressLine2",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "institutionAddressLine3",
            lable: "Institution Address Line 3",
            Field_Name: "Institution Address Line 3",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionAddressLine3",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "postalCode",
            lable: "Postal Code",
            Field_Name: "Postal Code",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "postalCode",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "acraBizFile",
            lable: "ACRA BizFile (please upload in pdf format max 2mb)",
            Field_Name: "ACRA BizFile (please upload in pdf format max 2mb)",
            fieldType: FormFieldTypes.FILE,
            dataField: "acraBizFile",
            fieldSetting: {
                isDownloadable: true,
                acceptedFiles: '.pdf',
                // maxFileSizeInKB: 2048,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },


    ],
};

export default InstitutionDetailsData;



