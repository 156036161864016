import { FormFieldTypes } from "../../../../../data/formFieldType";

const PointOfContactData = {
    name: "PointOfContactData",
    initialState: {
        salutationsID: "",
        // nameOfContactPerson: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        designation: "",
        joblevelId: "",
        emailAddress: "",
    },
    formFields: [
        // {
        //     id: "nameOfContactPerson",
        //     lable: "Name of Contact Person as in NRIC/FIN/Passport",
        //     Field_Name: "Name of Contact Person as in NRIC/FIN/Passport",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "nameOfContactPerson",
        //     fieldSetting: {
        //         // placeholder: "",
        //         allowSpace: true,
        //     },
        //     style: {
        //         containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
        //     }
        // },
        {
            id: "salutationsID",
            lable: "First Name",
            Field_Name: ["Salutations", "& First Name"],
            fieldType: FormFieldTypes.INPUTGROUPSELECT,
            dataField: ["salutationsID", "firstName"],
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
                options: [],
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "lastName",
            lable: "Last Name",
            Field_Name: "Last Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "lastName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "contactNumber",
            lable: "Contact Number",
            Field_Name: "Contact Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "contactNumber",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "designation",
            lable: "Job Designation",
            Field_Name: "Job Designation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "designation",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "joblevelId",
            lable: "Job Level",
            Field_Name: "Job Level",
            fieldType: FormFieldTypes.SELECT,
            dataField: "joblevelId",
            fieldSetting: {
                allowSpace: true,
                options: [],
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "emailAddress",
            lable: "Email Address",
            Field_Name: "Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "emailAddress",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" },{ type: "email" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },

    ],
};
export default PointOfContactData;