import React from 'react'
import './CreateAdminAccount.scss'
import CreateAdminAccountForm from '../../features/createAdminAccount/CreateAdminAccountForm'

const CreateAdminAccount = () => {
    return (
        <div><CreateAdminAccountForm /></div>
    )
}

export default CreateAdminAccount