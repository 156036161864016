import React from "react";
import "./Login.scss";
import ResetPasswordForm from "../../features/resetPassword/ResetPassword";

const ResetPassword = () => {
  return (
    <div className="login-section">
      <div className="right-form-section">
        <ResetPasswordForm />
      </div>
    </div>
  );
};

export default ResetPassword;