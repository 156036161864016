import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";

const CorporateTaskListApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'CorporateTaskListApi',
    endpoints: (builder) => ({
        getCorporateTaskList: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/GetAllCorporateMembershipTaskList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetAllDropdownsForCorporateMember: builder.query({
            query: () => ({
                url: '/Common/GetAllDropdownsForCorporateMember',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetAllCorporateInformationDetailsById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CorporateMember/GetAllCorporateInformationDetailsById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UpdateCorporateMemberDataById: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/UpdateCorporateMemberDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        RejectCorporateMemberApplication: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/RejectCorporateMemberApplication',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SentPaymentLinkCorporateMember: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/SentPaymentLinkCorporateMember',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditMembershipPricingByAdmin: builder.mutation({
            query: (details) => ({
                url: '/MembershipPayment/AddEditMembersPricingFromAdminByMemberId',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
})

export const {
    useGetCorporateTaskListMutation,
    useLazyGetAllDropdownsForCorporateMemberQuery,
    useLazyGetAllCorporateInformationDetailsByIdQuery,
    useUpdateCorporateMemberDataByIdMutation,
    useRejectCorporateMemberApplicationMutation,
    useSentPaymentLinkCorporateMemberMutation,
    useAddEditMembershipPricingByAdminMutation
} = CorporateTaskListApi;

export default CorporateTaskListApi;
//commit production