import React, { useEffect } from 'react'
import UploadMemberExcelFile from './components/UploadMemberExcelFile'
import { setAllDropDownField } from './utils/setAllDropDownFields';
import { useLazyGetAllDropdownsForIndividualMembersQuery } from '../../app/services/Individual/IndividualTaskListApi';

const EDMUploadMemberForm = () => {    
    const [getAllDropDowns, { data: dropdownResponse, isSuccess: isSuccess, isLoading: isLoading, }] = useLazyGetAllDropdownsForIndividualMembersQuery();

    useEffect(() => {
        getAllDropDowns()
    }, [])


    // Effect to set dropdown fields once data is loaded
    useEffect(() => {
        if (!isLoading && isSuccess && dropdownResponse) {
            setAllDropDownField(dropdownResponse);
        }
    }, [isLoading, isSuccess, dropdownResponse])

    return (
        <div className='container-fluid'>

            {isSuccess && <UploadMemberExcelFile
            />}
        </div>
    )
}

export default EDMUploadMemberForm