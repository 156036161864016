import React, { useState } from "react";
const Input = React.lazy(() => import('../../ui/inputs/input/Input.js'));
const Label = React.lazy(() => import('../../ui/label/Label.js'));
const ValidationText = React.lazy(() => import('../../ui/inputs/validation/ValidationText.js'))


const FormLowerCaseInputFields = ({
  labelName,
  name,
  onChange,
  onValidation,
  dataField,
  error,
  formSetting,
  isStar,
  ...inputProps
}) => {
  const [selectedFile, setSelectedFile] = useState(null)


  const handleInputChange = (e) => {
    if (onChange) {
      if (e.target.type !== 'file') {
        setSelectedFile(null)
        onChange(dataField, e.target.value);
      }
      else if (e.target.files[0]) {
        const fileObj = e.target.files[0];
        setSelectedFile(fileObj)
        // const reader = new FileReader();
        // reader.onload = (event) => {
        //   const fileContent = event.target.result;
        //   onChange(dataField, fileContent)
        // }

        // reader.readAsText(fileObj);

        onChange(dataField, fileObj);
      }
    }
  };

  const handleOnBlur = () => {
    if (onValidation) {
      onValidation(dataField);
    }
  };

  return (
    <>
      <div className="input-label-part">
        {labelName && labelName !== "" && <Label labelName={labelName} for={name} isRequired={inputProps.isRequired || isStar === true} />}
        {inputProps.hasMainTitle ?
          <div className="section-title">
            <h5>{inputProps.hasMainTitle}</h5>
          </div>
          : ""}
        <Input
          {...inputProps}
          filename={selectedFile}
          onChange={handleInputChange}
          onBlur={handleOnBlur}
          isDisable={formSetting?.isViewOnly || inputProps?.isDisable || false}
        />
      </div>
      <ValidationText error={error || ""} />
    </>
  );
};

export default FormLowerCaseInputFields;
