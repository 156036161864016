import React from 'react'
import "./Footer.scss";
import Image from "../../../components/image/Image";
import { AppIcons } from "../../../data/appIcons";

function Footer() {
    return (
        <div className='footer-main'>
            <div className='custom-width-foot'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <p>© 2008 – 2024 Association of Information Security Professionals (AiSP). All rights reserved.</p>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <div className='footer-social'>
                            <ul>
                                <li>
                                    <a href='https://www.facebook.com/AiSP.sg/' rel='noopener' className=''>
                                        <Image
                                            imagePath={AppIcons.facebook}
                                            imgCustomClassName="open-bar"
                                            altText="Icon"
                                        ></Image>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.instagram.com/aisp.sg/' rel='noopener' className=''>
                                        <Image
                                            imagePath={AppIcons.footinstagram}
                                            imgCustomClassName="open-bar"
                                            altText="Icon"
                                        ></Image>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.linkedin.com/company/aisp-sg/' rel='noopener' className=''>
                                        <Image
                                            imagePath={AppIcons.footlinkedin}
                                            imgCustomClassName="open-bar"
                                            altText="Icon"
                                        ></Image>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.youtube.com/channel/UCtwrsYLVHLJwd96NKQX-L4g' rel='noopener' className=''>
                                        <Image
                                            imagePath={AppIcons.footyoutube}
                                            imgCustomClassName="open-bar"
                                            altText="Icon"
                                        ></Image>
                                    </a>
                                </li>
                                <li>
                                    <a href='secretariat@aisp.sg' rel='noopener' className=''>
                                        <Image
                                            imagePath={AppIcons.footmail}
                                            imgCustomClassName="open-bar"
                                            altText="Icon"
                                        ></Image>
                                    </a>
                                </li>

                                <li>
                                    <a href='https://t.me/AiSP_SG' rel='noopener' className=''>
                                        <Image
                                            imagePath={AppIcons.footteli}
                                            imgCustomClassName="open-bar"
                                            altText="Icon"
                                        ></Image>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer