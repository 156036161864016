export const onHandleNationalityDropdown = (dataField, formData) => {

    const residentStatusIndex = formData.formFields.findIndex(field => field.id === "residentStatusId");
    const isHideAndRemoveValidation = dataField.value !== 1 ? true : false;
    const newCssValue = isHideAndRemoveValidation ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    const newValidationValue = isHideAndRemoveValidation ? [] : [];
    const updatedFormData = { ...formData };
    if (residentStatusIndex !== -1) {
        updatedFormData.formFields[residentStatusIndex].style.containerCss = newCssValue;
        updatedFormData.formFields[residentStatusIndex].validation = newValidationValue;
    }
    return { ...updatedFormData };
}

export const onHandleGraduationDate = (dataField, formData) => {

    const graduationDateIndex = formData.formFields.findIndex(field => field.id === "graduationDate");
    const isHideAndRemoveValidation = dataField.value !== 1 ? true : false;
    const newCssValue = isHideAndRemoveValidation ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    const newValidationValue = isHideAndRemoveValidation ? [] : [];
    const updatedFormData = { ...formData };
    if (graduationDateIndex !== -1) {
        updatedFormData.formFields[graduationDateIndex].style.containerCss = newCssValue;
        updatedFormData.formFields[graduationDateIndex].validation = newValidationValue;
    }
    return { ...updatedFormData };
}

export const onHandleOrdinaryPath3 = (dataField, formData) => {
    const isOrdinaryMemberPath3 = dataField.value === 4 ? true : false;
    // Nominator 1
    const isSepratorIndex = formData.formFields.findIndex(field => field.id === "isSeprator");
    const forOrdinaryIndex = formData.formFields.findIndex(field => field.id === "forOrdinary");
    const nameofNominator1Index = formData.formFields.findIndex(field => field.id === "nameofNominator1");
    const membershipNumberForNominator1Index = formData.formFields.findIndex(field => field.id === "membershipNumberForNominator1");
    const excoPositionIdForNominator1Index = formData.formFields.findIndex(field => field.id === "excoPositionIdForNominator1");
    const excoTermIdForNominator1Index = formData.formFields.findIndex(field => field.id === "excoTermIdForNominator1");
    const uploadEndorsementIndex = formData.formFields.findIndex(field => field.id === "uploadEndorsement");
    // Nominator 2
    const isSeprator2Index = formData.formFields.findIndex(field => field.id === "isSeprator2");
    const nameofNominator2Index = formData.formFields.findIndex(field => field.id === "nameofNominator2");
    const membershipNumberForNominator2Index = formData.formFields.findIndex(field => field.id === "membershipNumberForNominator2");
    const excoPositionIdForNominator2Index = formData.formFields.findIndex(field => field.id === "excoPositionIdForNominator2");
    const excoTermIdForNominator2Index = formData.formFields.findIndex(field => field.id === "excoTermIdForNominator2");
    const uploadEndorsement2Index = formData.formFields.findIndex(field => field.id === "uploadEndorsement2");

    const newCssValue = isOrdinaryMemberPath3 ? "separation-line pl-0 pr-0" : "separation-line pl-0 pr-0 d-none";
    const newCssValue1 = isOrdinaryMemberPath3 ? "col-lg-12 mb-3 text-left" : "col-lg-12 mb-3 text-left d-none";
    const newCssValue2 = isOrdinaryMemberPath3 ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    const newCssValue3 = isOrdinaryMemberPath3 ? "col-md-12 mb-4" : "col-md-6 mb-3 d-none";
    const newValidationValue = isOrdinaryMemberPath3 ? [] : [];

    const updatedFormData = { ...formData };
    if (isSepratorIndex !== -1) {

        // Update the containerCss value for periodTo field
        updatedFormData.formFields[isSepratorIndex].style.containerCss = newCssValue;
    }
    if (forOrdinaryIndex !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[forOrdinaryIndex].style.containerCss = newCssValue1;
    }
    if (nameofNominator1Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo fie
        updatedFormData.formFields[nameofNominator1Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[nameofNominator1Index].validation = newValidationValue;
    }
    if (membershipNumberForNominator1Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo fie
        updatedFormData.formFields[membershipNumberForNominator1Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[membershipNumberForNominator1Index].validation = newValidationValue;
    }
    if (excoPositionIdForNominator1Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo fie
        updatedFormData.formFields[excoPositionIdForNominator1Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[excoPositionIdForNominator1Index].validation = newValidationValue;
    }
    if (excoTermIdForNominator1Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo fie
        updatedFormData.formFields[excoTermIdForNominator1Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[excoTermIdForNominator1Index].validation = newValidationValue;
    }
    if (uploadEndorsementIndex !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo fie
        updatedFormData.formFields[uploadEndorsementIndex].style.containerCss = newCssValue3;
        updatedFormData.formFields[uploadEndorsementIndex].validation = newValidationValue;
    }

    // Nominator 2
    if (isSeprator2Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[isSeprator2Index].style.containerCss = newCssValue;
    }
    if (nameofNominator2Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[nameofNominator2Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[nameofNominator2Index].validation = newValidationValue;
    }
    if (membershipNumberForNominator2Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[membershipNumberForNominator2Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[membershipNumberForNominator2Index].validation = newValidationValue;
    }
    if (excoPositionIdForNominator2Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[excoPositionIdForNominator2Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[excoPositionIdForNominator2Index].validation = newValidationValue;
    }
    if (excoTermIdForNominator2Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[excoTermIdForNominator2Index].style.containerCss = newCssValue2;
        updatedFormData.formFields[excoTermIdForNominator2Index].validation = newValidationValue;
    }
    if (uploadEndorsement2Index !== -1) {
        // const updatedFormData = { ...formData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[uploadEndorsement2Index].style.containerCss = newCssValue3;
        updatedFormData.formFields[uploadEndorsement2Index].validation = newValidationValue;
    }
    return { ...updatedFormData }
}


export const formatDateMMYYYY = (input) => {
    if (!input) {
        return "";
    }
    else if (!isNaN(new Date(input))) {
        return input;
    }
    else {

        // Split the input string into month and year
        const [month, year] = input.split('/').map(Number);

        // Create a new Date object (year, month - 1, day)
        const date = new Date(year, month - 1, 1);

        // Format the date
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
    }

};
