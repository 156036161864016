import { forwardRef, React, useImperativeHandle, useRef } from "react";
import FormCreator from "../../../../components/Forms/FormCreator";
import Buttons from "../../../../components/ui/button/Buttons";
import formatDate from "../../../../lib/formatDate";

const EngagementTrackingAndNotes = forwardRef(
  ({ formData, addEngagement, engagementList }, ref) => {
    const EmploymentInformationFormRef = useRef();

    const addSaveEngagement = () => {
      addEngagement();
    };

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        if (ref?.current) {
          const data = EmploymentInformationFormRef.current.getFormData();
          return data;
        }
        return null;
      },
    }));
    return (
      <>
        <div className="form-field-sec">
          <div className="row gx-5 molgrid">
            <div className="overflow-x-auto mb-2">
              {
                engagementList && engagementList.length > 0 &&
                <table className="w-full bg-white rounded-sm border border-[#81a3b8] custom-table">
                  {/* Table header */}
                  <thead className="table-bg text-xs font-semibold capitalize text-[#181818] border-[#81a3b8]">
                    <tr>
                      <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                        <div className="font-bold tble-th-txt text-left label">
                          History
                        </div>
                      </th>
                      <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                        <div className="font-bold tble-th-txt text-left"></div>
                      </th>
                      <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                        <div className="font-bold tble-th-txt text-left"></div>
                      </th>
                      {/* <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                      <div className="font-bold tble-th-txt text-left"></div>
                    </th> */}
                    </tr>
                  </thead>
                  {/* Table body */}

                  <tbody className="text-sm custom-tbody border border-[#181818]">
                    {engagementList?.map((engagement, key) => (
                      <tr className="custom-tbl-td-history" key={key}>
                        <td className="p-7  text-lg">
                          <div className="text-[#3a4358] text-[16px]">
                            <span>Engagement Type: </span>
                            <span>
                              {engagement.corporateEngagementTypeName}
                            </span>
                          </div>
                          {
                            engagement && engagement?.corporateEngagementTypeName === "User Changes" ?
                              <>
                                <div className="text-[#3a4358] mt-2">
                                  <span>Initial Data: </span>
                                  <span>{`Membership Type (${engagement.initialMembershipType})`}</span>
                                  <span>{`Subscription End Date (${formatDate(engagement.initialSubscriptionEndDate, "DD MMM YYYY")})`}</span>
                                </div>
                                <div className="text-[#3a4358] mt-2">
                                  <span>New Data: </span>
                                  <span>{`Membership Type (${engagement.updatedMembershipType})`}</span>
                                  <span>{`Subscription End Date (${formatDate(engagement.updatedSubscriptionEndDate, "DD MMM YYYY")})`}</span>
                                </div>
                              </>
                              :
                              <div className="text-[#3a4358] mt-2">
                                <span>Description: </span>
                                <span>
                                  {
                                    engagement?.corporateEngagementTypeName === "Adhoc Notes" ?
                                      engagement.engagementDescription
                                      :
                                      engagement.internalNotes
                                  }
                                </span>
                              </div>
                          }
                        </td>
                        <td className="p-7  text-lg">
                          <div className="text-[#3a4358]">
                            <span>Recorded By: </span>
                            <span>
                              {
                                engagement?.corporateEngagementTypeName !== "Adhoc Notes" ?
                                  (engagement?.updatedByAdmin ? engagement.updatedByAdmin : engagement?.updateByUser)
                                  :
                                  engagement.recordedBy
                              }
                            </span>
                          </div>
                          <div className="text-[#3a4358] mt-2">
                            <span>Engagement Date:</span>
                            <span> {formatDate(engagement.engagementDate, "DD MMMM YYYY")}</span>
                          </div>
                        </td>
                        <td className="p-7  text-lg">
                          {
                            engagement && engagement?.corporateEngagementTypeName === "Adhoc Notes" ?
                              <div className="font-bold text-[#AA3361]">
                                <Buttons
                                  buttonText="View/Edit"
                                  buttonTypeClassName="theme-btn"
                                  onClick={addSaveEngagement}
                                />
                              </div>
                              :
                              null
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </div>
            <FormCreator
              ref={EmploymentInformationFormRef}
              {...formData}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <div className="mb-3">
            <Buttons
              buttonText="Add/Save"
              buttonTypeClassName="theme-btn"
              onClick={addSaveEngagement}
            />
          </div>
        </div>
      </>
    );
  }
);
export default EngagementTrackingAndNotes;
