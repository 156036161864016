import React, { useState } from "react";
const Dropdown = React.lazy(() => import('../../ui/dropdown/DropDrown'));
const Input = React.lazy(() => import('../../ui/inputs/input/Input'));
const Label = React.lazy(() => import('../../ui/label/Label'));
const ValidationText = React.lazy(() => import('../../ui/inputs/validation/ValidationText.js'))


const FormInputGroupSelect = ({
    name,
    value,
    error,
    options,
    onChange,
    dataField,
    labelName,
    placeholder,
    formSetting,
    isMultiSelect,
    onValidation,
    fieldSetting,
    fieldActions,
    ...inputProps
}) => {
    const [selectedFile, setSelectedFile] = useState(null)

    const capitalizeWords = (str) => {
        return str.toUpperCase();
    }


    const handleInputChange = (e) => {
        if (onChange) {
            if (e.target.type !== 'file') {
                setSelectedFile(null)
                const capitalizedValue = capitalizeWords(e.target.value);
                onChange(dataField[1], capitalizedValue);
            }
        }
    };
    const handleChange = (selectedOption) => {
        if (onChange) {
            if (isMultiSelect) {
                const selectedValues = selectedOption.map((option) => option.value);
                onChange(dataField[0], selectedValues)
            }
            else {
                onChange(dataField[0], selectedOption);
                if (fieldActions) {
                    fieldActions('DDL_CHANGED', dataField[0], selectedOption);
                }
            }
        }
    }

    const handleSelectOnBlur = () => {
        if (onValidation) {
            onValidation(dataField[0]);
        }
    };
    const handleInputOnBlur = () => {

        if (onValidation) {
            onValidation(dataField[1]);
        }
    };

    return (
        <>
            <div className="input-label-part combine-select-box">
                {labelName && labelName !== "" && <Label labelName={labelName} for={name} isRequired={inputProps.isRequired} />}
                {inputProps.hasMainTitle ?
                    <div className="section-title">
                        <h5>{inputProps.hasMainTitle}</h5>
                    </div>
                    : ""}
                <div className="input-group" style={{ flexWrap: "nowrap" }}>
                    <div className="input-group-prepend">
                        <Dropdown
                            placeholder={placeholder}
                            onChange={handleChange}
                            onBlur={handleSelectOnBlur}
                            options={options}
                            value={inputProps?.isEdit ? value[0] : value[0]}
                            isMultiSelect={isMultiSelect}
                            isDisabled={formSetting?.isViewOnly || inputProps.isDisable}
                            {...inputProps}
                        />
                    </div>
                    {
                        inputProps?.isEdit ? <Input
                            cssClass="border-radius-none-left input-field"
                            {...inputProps}
                            value={value[1]}
                            filename={selectedFile}
                            onChange={handleInputChange}
                            onBlur={handleInputOnBlur}
                            isDisable={formSetting?.isViewOnly || inputProps?.isDisable || false}
                        />
                            :
                            <Input
                                cssClass="border-radius-none-left input-field"
                                {...inputProps}
                                value={value[1]}
                                filename={selectedFile}
                                onChange={handleInputChange}
                                onBlur={handleInputOnBlur}
                                isDisable={formSetting?.isViewOnly || inputProps?.isDisable || false}
                            />
                    }
                </div>
            </div>
            <ValidationText error={error || ""} />
        </>
    );
};

export default FormInputGroupSelect;
