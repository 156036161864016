import { NonMembeEDMFormData, UploadMemberExcelFileData } from "../data/UploadMemberExcelFile.Data";

export const setAllDropDownField = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "source": filterDropDownList("TagName"),
    };

    const formFields = [
        ...UploadMemberExcelFileData.formFields,
        ...NonMembeEDMFormData.formFields
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
    return dropdownLists
};