import { FormFieldTypes } from "../../../../../data/formFieldType"
import { GridColumnType } from "../../../../../data/gridColumnType"

export const edcuationInfoTableConfig = {
    columns: [
        {
            name: "Education Institution (Name & Country)",
            fieldName: "educationInstitutionName",
            allowShort: false
        },
        {
            name: "Highest Educational Qualification",
            fieldName: "educationalQualificationName",
            allowShort: false
        },
        {
            name: "Year of Completion",
            fieldName: "yearName",
            allowShort: false
        },
        {
            name: "Certificates",
            fieldName: "educationCertificatesFileName",
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
                allowDownload:true,
            }

        },
    ]
}
export const EdcuationInformationData = {
    name: 'EdcuationInformationData',
    initialState: {
        educationInstitutionName: '',
        highestEducationalQualification: '',
        yearOfComplition: '',
        edcuationCertificate: '',
    },
    formFields: [
        // {
        //     id: "EdcuationInformationData",
        //     lable: "",
        //     Field_Name: "EdcuationInformationData",
        //     fieldType: FormFieldTypes.MAINFORMTITLE,
        //     dataField: "EdcuationInformationData",
        //     fieldSetting: {
        //         formTitle: "Edcuation Information",
        //     },
        //     style: {
        //         containerCss: "col-lg-12 mb-2 text-left",
        //     },
        // },
        {
            id: "edcuationalInfoTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "edcuationalInfoTable",
            fieldSetting: {
                header: edcuationInfoTableConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-left",
            },
        },
        {
            id: "educationInstitutionName",
            lable: "Education Institution (Name & Country)",
            Field_Name: "Education Institution (Name & Country)",
            fieldType: FormFieldTypes.INPUT,
            dataField: "educationInstitutionName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "yearOfComplition",
            lable: "Year of Completion",
            Field_Name: "Year of Completion",
            fieldType: FormFieldTypes.SELECT,
            dataField: "yearOfComplition",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        
        {
            id: "highestEducationalQualification",
            lable: "Highest Educational Qualification",
            Field_Name: "Highest Educational Qualification",
            fieldType: FormFieldTypes.SELECT,
            dataField: "highestEducationalQualification",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "edcuationCertificate",
            lable: 'Education Certificates (please upload in pdf/jpg format max 2MB)',
            Field_Name: 'Education Certificates (please upload in pdf/jpg format max 2MB)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'edcuationCertificate',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                // isDownloadable: false,
                acceptedFiles: '.pdf',
                // imageCrop: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}
export default EdcuationInformationData