import React, { useRef, useEffect, useState } from "react";
import {
  membershipPricingTableFormData,
} from "./MembershipPricingTableForm.data";
import FormCreator from "../../../components/Forms/FormCreator";
import Buttons from "../../../components/ui/button/Buttons";
import {
  useLazyGetAllMembershipPricingForAllMembersQuery,
  useAddEditAllMembershipPricingForAllMembersMutation,
} from "../../../app/services/PricingServices/MembershipPricingAPI";
import SwalAlert from "../../../services/swalService/SwalService";

const MembershipPricingTableForm = () => {

  const { success } = SwalAlert()

  const [AISPPricingTableForm, setAISPGmapPricingTable] = useState(
    membershipPricingTableFormData
  );
  // Define get pricing detail api
  const [getGmapPricingTable, {
    isSuccess: isGmapPricingTableSuccess,
    isFetching: isGmapPricingTableFetching,
    data: AISPPricingTable
  }] = useLazyGetAllMembershipPricingForAllMembersQuery()

  const [AddEditMembershipPricing, {
    isSuccess: isAddEditMembershipPricingSuccess,
    isFetching: isAddEditMembershipPricingFetching,
    data: AddEditMembershipPricingData
  }] = useAddEditAllMembershipPricingForAllMembersMutation()

  useEffect(() => {
    getGmapPricingTable();
  }, [])

  useEffect(() => {
    if (!isGmapPricingTableFetching && isGmapPricingTableSuccess && AISPPricingTable) {
      setAISPGmapPricingTable({ ...AISPPricingTableForm, initialState: AISPPricingTable })
    }
  }, [isGmapPricingTableFetching, isGmapPricingTableSuccess, AISPPricingTable]);

  useEffect(() => {
    if (!isAddEditMembershipPricingFetching && isAddEditMembershipPricingSuccess && AddEditMembershipPricingData) {
      success("Pricing updated", "Success")
      getGmapPricingTable();
    }
  }, [isAddEditMembershipPricingFetching, isAddEditMembershipPricingSuccess, AddEditMembershipPricingData]);


  const convertStringToNumber = (obj) => {
    const convertedObj = {};
    if (!obj)
      return
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string' && !isNaN(Number(value.replace(/\$|,/g, '').trim()))) {
        convertedObj[key] = Number(value.replace(/\$|,/g, '').trim());
      } else {
        convertedObj[key] = value !== undefined && value !== null ? value : 0;
      }
    }
    return convertedObj;
  };


  const handleSave = () => {
    const pricingFormData = GmapPricingTableRef.current.getFormData();
    if (!pricingFormData)
      return;
    const convertedData = convertStringToNumber(pricingFormData);
    AddEditMembershipPricing(convertedData);
  }

  const GmapPricingTableRef = useRef();
  return (
    <>
      <div className="gmap-pricing-table">
        <div className="common-project-info-main">
          <div className="custom-main-grid">
            <FormCreator
              ref={GmapPricingTableRef}
              {...AISPPricingTableForm}
            // onActionChange={formActionHandler}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="common-custom-pagination d-flex justify-content-between">
                <div className="">
                  <Buttons
                    buttonText="Back"
                    buttonTypeClassName="back-btn"
                  // onClick={handleSave}
                  />
                </div>
                <div className="">
                  <Buttons
                    buttonText="Save"
                    buttonTypeClassName="theme-btn"
                    onClick={handleSave}
                    isLoading={isAddEditMembershipPricingFetching}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MembershipPricingTableForm;
