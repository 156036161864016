import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import {encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";

const EmailTemplateManagementApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'EmailTemplateManagementApi',
    endpoints: (builder) => ({
     
        getEmailTemplateList: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/GetEmailTemplateList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditEmailTemplate: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/AddEditEmailTemplate',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
       
        getAllDropdownsForAISP1: builder.query({
            query: (details) => ({
                url: `/AISPAccount/GetAllDropdownsForAISP`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getEmailTemplateById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/GetEmailTemplateById?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteEmailTemplate: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/DeleteEmailTemplate',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetEmailTemplateListMutation,
    useAddEditEmailTemplateMutation,
    useDeleteEmailTemplateMutation,
    useLazyGetAllDropdownsForAISP1Query,
    useLazyGetEmailTemplateByIdQuery,
    

} = EmailTemplateManagementApi;

export default EmailTemplateManagementApi;