import React from 'react'
import { Link } from 'react-router-dom';
import "./NavigateButton.scss";
import { encryptAESID } from '../../../utils/Encryption';

export const NavigateButton = ({ name, path, navStyle, identifyNumber }) => {
    return (
        <>
            {identifyNumber > 0 ? <Link className={`nav-btn ${navStyle}`} to={`${path}/${encryptAESID(identifyNumber)}`} target="_blank">{name}</Link>
                : <Link className={`nav-btn ${navStyle}`} to={`${path}`} target="_blank">{name}</Link>}
        </>
    )
}
