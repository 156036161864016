import React from "react";
import "../inputs/input/Input.scss";

const Label = ({ labelName, subLabelName,linkName, isRequired }) => {
  return (
    <div className="mr-4 input-label-title">
      {labelName && (
        <label className="input-label">
          {labelName} {isRequired && <span className="validation-star">*</span>}
        </label>
      )}
      {subLabelName && (
        <>
          <br />
          <span>{subLabelName}</span><a href="#" className="ml-2">{linkName}</a>
        </>
      )}
    </div>
  );
};

export default Label;
