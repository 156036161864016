import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";

const IndividualTaskListApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'IndividualTaskListApi',
    endpoints: (builder) => ({
        getIndividualTaskList: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/GetAllIndividualMembershipTaskList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetAllDropdownsForIndividualMembers: builder.query({
            query: () => ({
                url: encryptQueryString('/Common/GetAllDropdownsForIndividualMembers'),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetAllIndividualInformationDetailsById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GetAllIndividualInformationDetailsById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetIndividualInformationDetailsById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GetIndividualInformationDetailsById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UpdateIndividualDataById: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/UpdateIndividualMemberTaskDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        RejectIndividualMemberApplication: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/RejectIndividualMemberApplication',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SentPaymentLinkIndividualMember: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/SentPaymentLinkIndividualMember',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SaveIndividualMemberClarificationDetails: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/SaveIndividualMemberClarificationDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetMembershipPricingForMembershipTypeId: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/GetMembershipPricingForMembershipTypeId',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditMembershipTaskAndSendForApproval: builder.mutation({
            query: (details) => ({
                url: '/MembershipTask/AddEditMembershipTaskAndSendForApproval',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SaveMembershipAuditLogForSendBackToPIC1: builder.mutation({
            query: (details) => ({
                url: '/MembershipTask/SaveMembershipAuditLogForSendBackToPIC1',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        approveIndividualUpgrade: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/ApproveIndividualMemberUpgrade',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getPricingForUpgradeIndividual: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/GetPricingForUpgradeIndividual',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        sendPaymentLinkForUpgradeIndividual: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/SentPaymentLinkForUpgradeIndividualMember',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetIndividualTaskListMutation,
    useLazyGetAllDropdownsForIndividualMembersQuery,
    useLazyGetAllIndividualInformationDetailsByIdQuery,
    useLazyGetIndividualInformationDetailsByIdQuery,
    useUpdateIndividualDataByIdMutation,
    useRejectIndividualMemberApplicationMutation,
    useSentPaymentLinkIndividualMemberMutation,
    useSaveIndividualMemberClarificationDetailsMutation,
    useGetMembershipPricingForMembershipTypeIdMutation,
    useAddEditMembershipTaskAndSendForApprovalMutation,
    useSaveMembershipAuditLogForSendBackToPIC1Mutation,
    useApproveIndividualUpgradeMutation,
    useGetPricingForUpgradeIndividualMutation,
    useSendPaymentLinkForUpgradeIndividualMutation
} = IndividualTaskListApi;

export default IndividualTaskListApi;