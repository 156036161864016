import { forwardRef, React, useImperativeHandle, useRef } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';

const EmploymentInformation = forwardRef(({ formData, handleOnChangeFileUpload, handleChangeOnFileDownload }, ref) => {
  const EmploymentInformationFormRef = useRef();

  const handleFormFieldChange = (dataField, value) => {
    if ((dataField === "resumeFileName") && value) {
      handleOnChangeFileUpload(dataField, value)
    }

  };

  const handleDownload = (dataField, value) => {
    handleChangeOnFileDownload(dataField, value)
  };

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = EmploymentInformationFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const formActionHandler = {
    DOWNLOAD: handleDownload,
  }

  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={EmploymentInformationFormRef}
          {...formData}
          onActionChange={formActionHandler}
          onFormFieldValueChange={handleFormFieldChange}
        ></FormCreator>
      </div>
    </div>

  )
}
)
export default EmploymentInformation;