import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";

const EmailCampaignApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'EmailCampaignApi',
    endpoints: (builder) => ({
        getRecipientsByMembershipTypeDetails: builder.mutation({
            query: () => ({
                url: '/FieldManagement/GetRecipientsByMembershipTypeDetails',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getFilterDropdowns: builder.query({
            query: () => ({
                url: '/FieldManagement/GetEmailCampaignFilterDropdown',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditEmailCampaign: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/AddEditEmailCampaign',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getEmailTemplateById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/GetEmailTemplateById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getSelectedEmailCountForCampaign: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/GetSelectedEmailCountForCampaign',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getEmailcampaignList: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/GetEmailCampaignList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getEmailCampaignById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/GetEmailCampaignById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetRecipientsDropdown: builder.query({
            query: () => ({
                url: `/FieldManagement/GetRecipientsDropdown`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        DuplicateEmailCampaignById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/DuplicateEmailCampaignById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        DeleteEmailCampaignById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/DeleteEmailCampaignDetailsById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetRecipientsByMembershipTypeDetailsMutation,
    useLazyGetFilterDropdownsQuery,
    useAddEditEmailCampaignMutation,
    useLazyGetEmailTemplateByIdQuery,
    useGetSelectedEmailCountForCampaignMutation,
    useGetEmailcampaignListMutation,
    useLazyGetEmailCampaignByIdQuery,
    useLazyGetRecipientsDropdownQuery,
    useLazyDuplicateEmailCampaignByIdQuery,
    useLazyDeleteEmailCampaignByIdQuery,
} = EmailCampaignApi;

export default EmailCampaignApi;