import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const membershipFinancialService = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'membershipFinancialService',
    endpoints: (builder) => ({
        // getEventPaymentReconcilationList: builder.query({
        //     query: () => ({
        //         url: '/Finance/GetEventPaymentReconcilationList',
        //         method: 'GET',
        //     }),
        //     transformResponse: transformSucessResponse,
        //     transformErrorResponse: transformErrorResponse,
        // }),
        getEventPaymentReconcilationList: builder.mutation({
            query: (details) => ({
                url: '/Finance/GetEventPaymentReconcilationList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SavePaymentReconcilationDetailsByAdmin: builder.mutation({
            query: (details) => ({
                url: '/Finance/SavePaymentReconcilationDetailsByAdmin',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AcceptTotalMembershipPaidAmountInReconciliation: builder.mutation({
            query: (details) => ({
                url: '/Finance/AcceptTotalMembershipPaidAmountInReconciliation',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetMembersFinancialInformationById: builder.mutation({
            query: (details) => ({
                url: '/Finance/GetMembersFinancialInformationById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getOutstandingInvoicesList: builder.mutation({
            query: (details) => ({
                url: '/MembershipPricing/GetOutstandingInvoicesList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addMembershipPaymentConfirmationMembersDetails: builder.mutation({
            query: (details) => ({
                url: '/MembershipPayment/AddMembershipPaymentConfirmationMembersDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAllExtractInvoicesList: builder.mutation({
            query: (details) => ({
                url: '/Finance/GetAllExtractInvoicesList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getTransactionRecordList: builder.mutation({
            query: (details) => ({
                url: '/Finance/GetTransactionRecordList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        downloadSheetForTransactionRecord: builder.mutation({
            query: (details) => ({
                url: '/Finance/DownloadSheetForTransactionRecord',
                method: 'POST',
                body: transformRequest(details),
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        zipInvoicesAsync: builder.mutation({
            query: (details) => ({
                url: '/Finance/ZipInvoices',
                method: 'POST',
                body: transformRequest(details),
                //responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
       
        financeGetInvoice: builder.query({
            query: (path) => ({
                url: encryptQueryString(`/Finance/FinanceGetInvoice?path=${path}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
               
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetEventPaymentReconcilationListMutation,
    useSavePaymentReconcilationDetailsByAdminMutation,
    useAcceptTotalMembershipPaidAmountInReconciliationMutation,
    useGetMembersFinancialInformationByIdMutation,
    useGetOutstandingInvoicesListMutation,
    useAddMembershipPaymentConfirmationMembersDetailsMutation,
    useGetAllExtractInvoicesListMutation,
    useGetTransactionRecordListMutation,
    useDownloadSheetForTransactionRecordMutation,
    useZipInvoicesAsyncMutation,
    useLazyFinanceGetInvoiceQuery,
} = membershipFinancialService;

export default membershipFinancialService;