import { forwardRef, React, useImperativeHandle, useRef } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';

const MembershipApplication = forwardRef(({ formData, handleFieldChange }, ref) => {
    const membershipApplicationFormRef = useRef();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const data = membershipApplicationFormRef.current.getFormData();
                return data;
            }
            return null;
        }
    }));
    const handleDataFieldChange = (fieldName, data) => {
        if (handleFieldChange)
            handleFieldChange(fieldName, data);
    }

    return (
        <>
            <div className="form-field-sec">
                <div className="row gx-5">
                    <FormCreator
                        ref={membershipApplicationFormRef}
                        {...formData}
                        onFormFieldValueChange={handleDataFieldChange}
                    ></FormCreator>
                </div>
            </div>

        </>
    )
})
export default MembershipApplication