import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import transactionReportFormData, { transactionReportTableConfig } from "./data/TransactionReportTable.Data";
import MolGrid from "../../components/Grid/MolGrid";
import formatDate from "../../lib/formatDate";
import { saveAs } from "file-saver";
// Import API sservice
import SwalAlert from "../../services/swalService/SwalService";
import {
  useGetTransactionRecordListMutation,
  useDownloadSheetForTransactionRecordMutation
}
  from "../../app/services/membershipFinancialService/MembershipFinancialServices";

const tempTrasactionReportList = [
  {
    invoiceNumber: "MEM00001420",
    paymentStatus: "Paid",
    paymentMode: "Bank Transfer",
    totalInvoiceAmount: "2000.00",
    paymentDate: "11/11/2011",
  }
]

const TransactionReportForm = forwardRef((props, ref) => {
  const [formData, SetFormData] = useState(transactionReportFormData);
  const formRef = useRef();
  const molGridRef = useRef();
  const { success, error, warning } = SwalAlert();
  const [
    membershipPaymentReconciliationList,
    setMembershipPaymentReconciliationList,
  ] = useState([]);
  const [paginationRequest, setPaginationRequest] = useState({
    PageNo: 1,
    PageSize: 10,
    OrderByColumn: "",
    OrderFlag: 0,
    StartDate: "",
    EndDate: "",
    TransactionReportFor: "Membership",
    IsForDownloadRecords: false,
    totalCount: 0
  });

  // Transaction records API call
  const [
    transactionRecordList,
    {
      isSuccess: isSuccessList,
      isLoading: isLoadingList,
      data: transactionRecordListResponse,
    },
  ] = useGetTransactionRecordListMutation();
  // Download excel API call
  const [
    downloadTransactionRecordExcel,
    {
      isSuccess: isDownloadSuccess,
      isLoading: isDownloadLoading,
      data: downloadExcelResponse,
    },
  ] = useDownloadSheetForTransactionRecordMutation();


  useEffect(() => {
    if (!isLoadingList && isSuccessList && transactionRecordListResponse) {
      let finalResponseList = transactionRecordListResponse.itemList.map(
        (obj) => {
          return {
            ...obj,
            invoiceDate: formatDate(obj?.invoiceDate, "DD/MM/YYYY"),
          };
        }
      );
      setPaginationRequest({
        ...paginationRequest,
        totalCount: transactionRecordListResponse.totalCount,
      });
      setMembershipPaymentReconciliationList(finalResponseList);
    }
  }, [isLoadingList, isSuccessList, transactionRecordListResponse]);

  useEffect(() => {
    if (!isDownloadLoading && isDownloadSuccess && downloadExcelResponse) {
      const now = new Date();
      const formattedDate = `${String(now.getDate()).padStart(2, '0')}${String(
        now.getMonth() + 1
      ).padStart(2, '0')}${now.getFullYear()}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}${String(
        now.getMinutes()
      ).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
  
      const fileName = `Transaction_Report_${formattedDate}_${formattedTime}.xlsx`;
  
      saveAs(downloadExcelResponse.fileData, fileName);
    }
  }, [isDownloadLoading, isDownloadSuccess, downloadExcelResponse]);
  

  // Extract invoices list method 
  const getTransactionRecordList = () => {
    const searchFormData = formRef.current.getFormData();
    if (searchFormData) {
      const request = {
        PageNo: 1,
        PageSize: 10,
        OrderByColumn: "",
        OrderFlag: 0,
        StartDate: searchFormData?.startDate || null,
        EndDate: searchFormData?.endDate || null,
        TransactionReportFor: "Membership",
        IsForDownloadRecords: false,
      };
      transactionRecordList(request);
    }
    else {
      error("Fill in all required fields");
    }
  };
  // Download transaction record excel method 
  const downloadTransactionRecord = () => {
    const searchFormData = formRef.current.getFormData();
    if (searchFormData) {
      const request = {
        PageNo: 1,
        PageSize: 9999999,
        OrderByColumn: "",
        OrderFlag: 0,
        StartDate: searchFormData.startDate,
        EndDate: searchFormData.endDate,
        TransactionReportFor: "Membership",
        IsForDownloadRecords: true,
      };
      downloadTransactionRecordExcel(request);
    }
    else {
      error("Fill in all required fields");
    }
  };
  const handlePageChange = (newPage) => {
    const searchFormData = formRef.current.getFormData();
    setPaginationRequest((previous) => {
      const tempPagination = {
        PageNo: newPage.pageNumber,
        PageSize: newPage.pageSize,
        OrderByColumn: "",
        OrderFlag: 0,
        StartDate: searchFormData.startDate,
        EndDate: searchFormData.endDate,
        TransactionReportFor: "Membership",
        IsForDownloadRecords: false,
      };
      transactionRecordList(tempPagination);
      return tempPagination;
    });
  };


  return (
    <React.Fragment>
      <div className="row gx-5 bg-light tasklist-form">
        <FormCreator ref={formRef} {...formData} />
        <div className="col-lg-2 col-md-4 mb-1 d-flex justify-content-start align-items-center mb-2">
          <div className="">
            <div className="custom-button payment">
              <Buttons
                buttonText="Show Transaction Report"
                buttonTypeClassName="theme-btn "
                onClick={getTransactionRecordList}
              />{
                membershipPaymentReconciliationList.length > 0 &&
                <Buttons
                  buttonText="Download Excel"
                  buttonTypeClassName="theme-btn "
                  onClick={downloadTransactionRecord}
                />
              }
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-span-12">
            <div className="card-section mt-4">
              <MolGrid
                ref={molGridRef}
                configuration={transactionReportTableConfig}
                dataSource={membershipPaymentReconciliationList}
                allowPagination={true}
                // isLoading={isLoadingList}
                onPageChange={handlePageChange}
                pagination={paginationRequest}
              // onActionChange={actionHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
export default TransactionReportForm;