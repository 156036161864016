import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "./SwalService.scss"

export const SwalServices = withReactContent(Swal);

const customClass = {
    container: '',
    popup: '',
    header: '',
    title: '',
    closeButton: '',
    icon: '',
    image: '',
    htmlContainer: '',
    input: '',
    inputLabel: '',
    validationMessage: '',
    actions: '',
    confirmButton: 'btn success-btn',
    denyButton: 'btn dark-btn',
    cancelButton: 'btn dark-btn',
    loader: '',
    footer: '',
    timerProgressBar: '',
}
const SwalAlert = () => {

    const success = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'success',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const error = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'error',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const warning = (title, text) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const info = (title, text) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'info',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    }

    const confirm = (title, text, saveBtnText, cancelBtnText, isCancelButton) => {

        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'question',
            // iconHtml: '<span className="warning-icons"><img src="/warningtick.png"/></span>',
            showCancelButton: isCancelButton === false ? isCancelButton : true,
            confirmButtonText: saveBtnText,
            cancelButtonText: cancelBtnText,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            customClass: customClass,

        }).then((result) => {
            if (result.value) {
                return true;
            } else if (result.dismiss === SwalServices.DismissReason.cancel) {
                return false;
            }
        });
    };

    const confirmDelete = (title, text, saveBtnText, cancelBtnText, isCancelButton) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'question',
            input: 'text',
            //inputPlaceholder: 'Type "Delete" to confirm...',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: isCancelButton !== false,
            confirmButtonText: saveBtnText,
            cancelButtonText: cancelBtnText,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            customClass: customClass,
            preConfirm: (value) => {
                if (value === 'delete') {
                    return value;
                } else {
                    SwalServices.showValidationMessage(
                        'You need to type "Delete" to confirm!'
                    );
                }
            },
            didOpen: () => {
                const input = SwalServices.getInput();
                const confirmButton = SwalServices.getConfirmButton();

                input.addEventListener('input', () => {
                    if (input.value === 'delete') {
                        confirmButton.removeAttribute('disabled');
                    } else {
                        confirmButton.setAttribute('disabled', true);
                    }
                });
                confirmButton.setAttribute('disabled', true);
            },
            customClass: customClass,

        }).then((result) => {
            if (result.value) {
                return result.value;
            } else if (result.dismiss === SwalServices.DismissReason.cancel) {
                return false;
            }
        });
    };

    const confirmTextWithApi = async (title, text, saveBtnText, cancelBtnText, isCancelButton, callApi, request, keyName, responseCondition, condition) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'question',
            input: 'password', // Changed to 'password' to mask input text
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: isCancelButton !== false,
            confirmButtonText: saveBtnText,
            cancelButtonText: cancelBtnText,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            customClass: customClass,
            preConfirm: (value) => {
                if (value === "" || value === null || value === undefined) {
                    SwalServices.showValidationMessage(
                        'Please enter your admin password!'
                    );

                } else {
                    const call = async (req) => {
                        req[keyName] = value;
                        try {
                            let response = await callApi(req);
                            if (response.data[responseCondition] === condition) {
                                return response; // This will allow the popup to close
                            } else {
                                SwalServices.showValidationMessage(
                                    'The provided password is incorrect!'
                                );
                                return false; // Prevent popup from closing
                            }
                        } catch (error) {
                            SwalServices.showValidationMessage(
                                `API call failed: ${error}`
                            );
                            return false; // Prevent popup from closing                                               
                        }
                    };
                    return call(request);
                }
            },
            customClass: customClass,

        }).then((result) => {
            if (result.value) {
                return result.value;
            } else if (result.dismiss === SwalServices.DismissReason.cancel) {
                return false;
            }
        });
    };

    // Export the SwalService functions
    return { confirm, success, warning, info, error, confirmDelete, confirmTextWithApi }
};
export default SwalAlert;