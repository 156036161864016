import React, { Suspense, forwardRef, lazy, useEffect, useRef, useState, useContext } from "react"
import FormCreator from "../../components/Forms/FormCreator";
import { auditLogData } from "./data/AuditLog.Data";
import SwalAlert from "../../services/swalService/SwalService";
import Buttons from "../../components/ui/button/Buttons";
import { useSaveMembershipAuditLogForSendBackToPIC1Mutation } from "../../app/services/Individual/IndividualTaskListApi";
import { IndividualMemberDataContext } from "../../pages/individualMemberTaskList/IndividualMember";
import { getData } from "../../utils/LocalStorage/LocalStorageManager";

const AuditLogForm = forwardRef((props, ref) => {
    const auditLogNotesRef = useRef();
    const { confirm, error, success } = SwalAlert();
    const [auditFormData, SetAuditFormData] = useState(auditLogData);
    const [isPIC1Flag, setIsPIC1Flag] = useState(true);
    const [auditInternalNotes, setAuditInternalNotes] = useState("");
    const [sentBackToPIC1, { isSuccess: isSuccessSentBackToPIC1, isLoading: isLoadingSentBackToPIC1, data: sentSentBackToPIC1Response }] = useSaveMembershipAuditLogForSendBackToPIC1Mutation();
    const { IndividualInfo, setContextData } = useContext(IndividualMemberDataContext);
    // Heandle sent back to PIC1 clarification
    const handleSentBackToPIC1 = () => {
        let formResponse = auditLogNotesRef.current.getFormData();
        let request = {};
        request.internalNotes = formResponse.internalNotes;
        request.taskId = formResponse.taskId;
        request.decision = "Clarification";
        request.updateByUser = getData("pic1Name");
        request.createdAt = new Date();
        request.applicantName = formResponse.applicantName;
        request.pIC1UserName = formResponse.PIC1Name;
        sentBackToPIC1(request);
    }

    // Method for handle form fields changes
    // const handleFormFieldChange = (dataField, value) => {
    //     let formResponse = auditLogNotesRef.current.getFormData();
    //     setAuditInternalNotes(formResponse.internalNotes);
    // }


    // Get approve request response
    useEffect(() => {
        let checkIsPIC1 = getData("isPIC1")
        let isPICFlag = checkIsPIC1 === true ? true : false;
        setIsPIC1Flag(isPICFlag);

    }, []);
    // Get approve request response
    useEffect(() => {
        if (!isLoadingSentBackToPIC1 && isSuccessSentBackToPIC1 && sentSentBackToPIC1Response) {
            success("Clarification request is sucessfully sent to the PIC1", "Successfull");
            window.location.reload();
        }
    }, [isLoadingSentBackToPIC1, isSuccessSentBackToPIC1, sentSentBackToPIC1Response]);
    const handleStateChange = (updatedData, dataField) => {
        if (dataField === "internalNotes") {
            setContextData(prevData => ({ ...prevData, internalNotes: updatedData.internalNotes }));
        }
    }

    return (
        <React.Fragment>
            <div className='row gx-5  bg-light tasklist-form'>
                <FormCreator
                    ref={auditLogNotesRef} {...auditFormData}
                    // onActionChange={formActionHandler}
                    onFormDataChange={handleStateChange}
                />
                <div className='row mt-4'>
                    <div className='col-lg-12 d-flex justify-content-end'>
                        {
                            isPIC1Flag ?
                                null
                                :
                                <Buttons
                                    buttonText="Send back to PIC1"
                                    buttonTypeClassName="theme-btn"
                                    onClick={handleSentBackToPIC1}
                                    isLoading={isLoadingSentBackToPIC1}
                                />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})
export default AuditLogForm;