
import { createSlice } from '@reduxjs/toolkit';
import { getAuthProps, setAuthProps, signOut, setAuthPropsOnOTP } from '../../lib/authenticationLibrary';
import { saveData, getData } from '../../utils/LocalStorage/LocalStorageManager';

const authData = getAuthProps();
const permissionData = getData("SecurityPermission") ? getData("SecurityPermission") : [];

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: (authData != null),
        user: (authData?.name), //Set user data from cookie.
        token: (authData?.token), //Set token data from cookie.
        authData,
        isLogedin: false,
        isOtpValid: false,
    },
    reducers: {
        authentication: (state, action) => {
            state.isLogedin = true;
            setAuthProps(action.payload);
        },
        otpAuth: (state, action) => {
            state.isOtpValid = action.payload.valid;
            setAuthPropsOnOTP(action.payload.valid)
        },
        logout: (state) => {
            state.isLogedin = false;
            signOut();
        },
    },
});

export const { authentication, otpAuth, logout } = authSlice.actions;

export default authSlice.reducer;
