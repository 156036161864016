import React from 'react'
import './CreateEmailTemplate.scss'
import CreateEmailTemplateForm from '../../features/createEmailTemplate/CreateEmailTemplateForm'

const CreateEmailTemplate = () => {
    return (
        <div><CreateEmailTemplateForm /></div>
    )
}

export default CreateEmailTemplate