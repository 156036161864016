import { NavigateButton } from "../../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";


//gmapApplicationTasklistTableConfig 






export const PaymentTasklistTableConfig = {
    columns: [
        // {
        //     name: "Registration Date",
        //     fieldName: "registrationDate",
        //     allowShort: false,
        //     colType: GridColumnType.DATE,
        //     colSettings: {
        //         format: 'DD/MM/YYYY',
        //     }
        // },
        {
            name: "Registration Date",
            fieldName: "registrationDate",
            allowShort: false,
        },
        {
            name: "Invoice No",
            fieldName: "reconciliationInvoiceNo",
            allowShort: false,
        },
        {
            name: "Company Name",
            fieldName: "companyName",
            allowShort: false,
        },
        {
            name: "UEN/Biz Reg.No.",
            fieldName: "companyUEN",
            allowShort: false,
        },
        {
            name: "Contact Person",
            fieldName: "contactPerson",
            allowShort: false,
        },
        {
            name: "Payment Via",
            fieldName: "paymentVia",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "paymentProofStatus",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {
                    name: "VIEW",
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],

        },

    ],
};

export const PaymentApprovalTableConfig = {
    columns: [
        {
            name: "Date",
            fieldName: "amountCollectedDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Bank Information",
            fieldName: "bankName",
            allowShort: false,
        },
        {
            name: "Amount Collected",
            fieldName: "amountCollected",
            allowShort: false,
        },
        {
            name: "Comments",
            fieldName: "comments",
            allowShort: false,
        }
    ],
};


const paymentReconciliationTasklistFormData = {
    name: "paymentTaskData",
    initialState: {
        searchInvoice: "",
        stutus: "",
        date: "",
        paymentCategory: ""
    },
    formFields: [
        {
            id: "searchInvoice",
            lable: "Search Invoice",
            Field_Name: "Search Invoice",
            fieldType: FormFieldTypes.INPUT,
            dataField: "searchInvoice",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3",
            },
        },
        {
            id: "status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "status",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [
                    { value: 3, label: "Approved" },
                    { value: 2, label: "Completed" },
                    { value: 4, label: "Rejected" },
                    { value: 1, label: "Pending Approval" },
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3"
            }
        },
        {
            id: "date",
            lable: "Date",
            Field_Name: "Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "date",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
               // options: [],
                //useMaxDate: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3"
            }
        },
        // {
        //     id: "paymentCategory",
        //     lable: "Payment Category (You can select more than one option)",
        //     Field_Name: "Payment Category (You can select more than one option)",
        //     fieldType: FormFieldTypes.SELECT,
        //     dataField: "paymentCategory",
        //     fieldSetting: {
        //         placeholder: "",
        //         allowSpace: true,
        //         options: []
        //     },
        //     // validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "col-md-4 mb-3"
        //     }
        // },
    ],
};
export default paymentReconciliationTasklistFormData;

const financeBankNameList = [
    {
        value: 1,
        label: "Oversea-Chinese Banking Corporation"
    },
    {
        value: 2,
        label: "United Overseas Bank Limited"
    },
    {
        value: 4,
        label: "Cash"
    },
    {
        value: 5,
        label: "Membership Paynow"
    },
    {
        value: 6,
        label: "Bank Transfer"
    },
]

export const paymentFormData = {
    name: "paymentFormData",
    initialState: {
        bankInformation: null,
        amountCollected: "",
        amountDate: "",
    },
    formFields: [
        {
            id: "bankInformation",
            lable: "Bank Information",
            Field_Name: "Bank Information",
            fieldType: FormFieldTypes.SELECT,
            dataField: "bankInformation",
            fieldSetting: {
                placeholder: " ",
                options: financeBankNameList,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "amountCollected",
            lable: "Amount Collected",
            Field_Name: "Amount Collected",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "amountCollected",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "amountDate",
            lable: "Amount Collected Date",
            Field_Name: "Amount Collected Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "amountDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                useMaxDate: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
    ],
};




