import React, { useEffect, useState } from 'react'
import Buttons from '../../../components/ui/button/Buttons'
import { useLazyGetEmailCampaignByIdQuery } from '../../../app/services/EmailCampaign/EmailCampaignApi';
import DataLoader from '../../../components/ui/dataLoader/DataLoader';
import { useHtmlToPdfDownloadMutation } from '../../../app/services/Common/CommonApi';
import SwalAlert from '../../../services/swalService/SwalService';
import { saveAs } from 'file-saver';
export const PreviewCampaignPopUp = ({ campaignId }) => {
    const [campaignMailData, setCampaignMailData] = useState(null);

    const { error } = SwalAlert()

    const [getCampaigById,
        { isSuccess: getCampaigByIdIsSuccess,
            isLoading: getCampaigByIdIsLoading,
            data: getCampaigByIdData }
    ] = useLazyGetEmailCampaignByIdQuery();

    const [HtmlToPdfDownload,
        { isSuccess: HtmlToPdfDownloadIsSuccess,
            isLoading: HtmlToPdfDownloadIsLoading,
            data: HtmlToPdfDownloaddData }
    ] = useHtmlToPdfDownloadMutation();

    useEffect(() => {
        getCampaigById(campaignId.toString())
    }, [campaignId])

    useEffect(() => {
        if (getCampaigByIdIsSuccess && !getCampaigByIdIsLoading && getCampaigByIdData) {
            setCampaignMailData(getCampaigByIdData.message)
        }
    }, [getCampaigByIdIsSuccess, getCampaigByIdIsLoading, getCampaigByIdData,]);


    const downloadPdf = async () => {
        try {
            let request = { data: getCampaigByIdData.message }
            const response = await HtmlToPdfDownload(request)

            saveAs(response?.data?.fileData, "CampaignPrview");
        }
        catch {
            error("Fail To Download, Please try again later")
        }
    }

    return (
        <div>
            <Buttons
                buttonTypeClassName="theme-btn"
                buttonText="Download Pdf"
                isDisable={HtmlToPdfDownloadIsLoading}
                isLoading={HtmlToPdfDownloadIsLoading}
                onClick={downloadPdf}
            />

            <div>
                {campaignMailData ? <div dangerouslySetInnerHTML={{ __html: campaignMailData }} /> : <DataLoader />}
            </div>
        </div>
    )
}
