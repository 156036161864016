import React, { useRef, useState } from 'react'
import Buttons from '../../components/ui/button/Buttons';
import CorporateMemberTable from './component/CorporateMemberTable';
import CorporateMemberSearchForm from './component/CorporateMemberSearchForm';
import SearchBar from '../component/SearchBar';

const initState = {
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    totalCount: 0,
    StatusId: null,
    MembershipTypeId: null
}
const CorporateTaskList = () => {
    const corporateTaskListFromRef = useRef();
    const [paginationRequest, setPaginationRequest] = useState(initState);

    const handleSearchApplicationTask = () => {
        // Ensure applicationTasklistRef.current is not null before using it
        if (!corporateTaskListFromRef.current) {
            // Handle the case where applicationTasklistRef.current is null
            return;
        }
        const searchFormData = corporateTaskListFromRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            searchText: searchFormData.uenNumber,
            dateFrom: searchFormData.dateFrom,
            statusId: searchFormData?.statusId?.value || searchFormData?.statusId,
            membershipTypeId: searchFormData?.membershipTypeId.value || searchFormData?.membershipTypeId,
            dateTo: searchFormData.dateTo,
        };
        setPaginationRequest(updatedPaginationRequest);

    };

    const handleClearClick = () => {
        setPaginationRequest(initState);
    }

    return (
        <div className="row align-items-end">
            <div className='col-11'>
                <div className='row gx-5'>
                    <CorporateMemberSearchForm ref={corporateTaskListFromRef} />
                </div>
            </div>
            <div className="col-lg-1 d-flex justify-content-end mb-3">
                <div className="two-button-fixes">
                    <div className="">
                        <Buttons
                            buttonText="Search"
                            buttonTypeClassName="theme-btn"
                            onClick={handleSearchApplicationTask}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mt-5">
                <div className="common-view-Individual-table">
                    <div className="table-responsive">
                        <CorporateMemberTable paginationReq={paginationRequest} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateTaskList