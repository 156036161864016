import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse,transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString,transformRequest } from "../../../utils/API/requestMiddleware";

const EDMApi=createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'EDMApi',
    endpoints: (builder) => ({
        AddEditIndividualNonMemberDetailsBatch: builder.mutation({
            query: (details) => ({
                url: '/WebMembership/AddEditIndividualNonMemberDetailsBatch',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetAllNonIndividualMemberList: builder.mutation({
            query: (details) => ({
                url: '/WebMembership/GetAllNonIndividualMemberList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteNonMember: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/IndividualMember/DeleteNonIndividualMemberDetailById?id=${details.id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetNonIndividualMemberById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GetNonIndividualMemberDetailById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UpdateNonIndividualMemberDetail: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/UpdateNonIndividualMemberDetailById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useAddEditIndividualNonMemberDetailsBatchMutation,
    useGetAllNonIndividualMemberListMutation,
    useLazyDeleteNonMemberQuery,
    useLazyGetNonIndividualMemberByIdQuery,
    useUpdateNonIndividualMemberDetailMutation

}=EDMApi;

export default EDMApi;