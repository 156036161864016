import React, { useEffect, useRef, useState } from 'react'
import FormCreator from '../../../components/Forms/FormCreator'
import Buttons from '../../../components/ui/button/Buttons'
import { OutstandingInvoiceDetailModelData } from '../data/OutstandingInvoiceDetailModel.Data';
import SwalAlert from '../../../services/swalService/SwalService';
import { useAddMembershipPaymentConfirmationMembersDetailsMutation } from '../../../app/services/membershipFinancialService/MembershipFinancialServices';
import { useStoreBase64ImageMutation, useUploadPDFMutation } from '../../../app/services/Common/CommonApi';
import { useNavigate } from 'react-router-dom';

const OutstandingInvoiceDetailPopup = ({ rowData, getMemberList, handleOpenDetailModal, openAddModal }) => {

    const outstandingInvoiceDetailModelRef = useRef();
    const [outstandingInvoiceDetailModelData, setOutstandingInvoiceDetailModelData] = useState(OutstandingInvoiceDetailModelData);
    const { success, error, warning } = SwalAlert();
    const [paymentProofName, setPaymentProofName] = useState("");
    const navigate = useNavigate();

    const [addMembershipPaymentConfimation,
        {
            data: membershipPaymentConfirmationResponse,
            isSuccess: isMembershipPaymentConfirmationSuccess,
            isLoading: membershipPaymentConfirmationLoading,
        },
    ] = useAddMembershipPaymentConfirmationMembersDetailsMutation();

    useEffect(() => {
        if (!membershipPaymentConfirmationLoading, isMembershipPaymentConfirmationSuccess, membershipPaymentConfirmationResponse) {
            
            success("Thank you! Your payment proof is uploaded. Please check your email.", "Successful").then(() => {
                handleOpenDetailModal();
                openAddModal();
                getMemberList();
            });
        }
    }, [membershipPaymentConfirmationLoading, membershipPaymentConfirmationResponse, isMembershipPaymentConfirmationSuccess])

    const [uploadPDF,
        { isSuccess: isUploadSuccess,
            isLoading: isUploadLoading,
            data: fileUploadData }
    ] = useUploadPDFMutation();

    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: paymentProofImageUpload }
    ] = useStoreBase64ImageMutation();

    useEffect(() => {
        if (!isUploadLoading && isUploadSuccess && fileUploadData) {
            let updatedFormData = { ...outstandingInvoiceDetailModelData }
            updatedFormData.initialState.addPaymentProof = fileUploadData?.fileName;
            outstandingInvoiceDetailModelData.initialState.addPaymentProof = fileUploadData?.fileName;
            setOutstandingInvoiceDetailModelData(updatedFormData);
            setPaymentProofName(fileUploadData.fileName);
        }
    }, [isUploadSuccess, isUploadLoading, fileUploadData,]);

    const handleMembershipPaymentConfimation = () => {
        const outstandingInvoiceData = outstandingInvoiceDetailModelRef.current.getFormData();
        if (!outstandingInvoiceData) {
            return error("Please fill all mandatory fields.", "");
        }
        const request = {};
        request.individualMembersId = rowData?.individualMembersId || 0;
        request.corporateMembersId = rowData?.corporateMembersId || 0;
        request.academicMembersId = rowData?.academicMembersId || 0;
        request.bookingId = rowData?.membershipPaymentId;
        request.membershipPaymentTypeId = rowData?.membershipPaymentTypeId;
        request.filePath = paymentProofName;
        request.comments = outstandingInvoiceData?.paymentComment;
        request.paymentProofStatusId = 1
        request.paymentVia = rowData?.paymentVia;
        request.isCorporate = rowData?.corporateMembersId > 0 ? true : false;
        request.isAcademic = rowData?.academicMembersId > 0 ? true : false;
        addMembershipPaymentConfimation(request);
    };
    const handleImageUpload = async (data) => {
        const { base64Data, fileName } = data
        let storagePath = "";
        if (rowData.individualMembersId > 0)
            storagePath = "IndividualPaymentProofImage";
        else if (rowData.academicMembersId > 0)
            storagePath = "AcademicPaymentProofImage";
        else if (rowData.corporateMembersId > 0)
            storagePath = "CorporatePaymentProofImage";

        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: storagePath,
        }
        const response = await saveMainImage(saveFileRequest)
        if (response.data) {
            let updatedFormData = { ...outstandingInvoiceDetailModelData }
            updatedFormData.initialState.addPaymentProof = fileName;
            outstandingInvoiceDetailModelData.initialState.addPaymentProof = fileName;
            setOutstandingInvoiceDetailModelData({ ...outstandingInvoiceDetailModelData, updatedFormData });
            setPaymentProofName(response?.data.fileName);
            success("Image upload successfuly.")
        }
    }
    // Handle change on file upload method
    const handleOnChangeFileUpload = (data) => {
        let storagePath = "";
        if (rowData.individualMembersId > 0)
            storagePath = "IndividualPaymentProof";
        else if (rowData.academicMembersId > 0)
            storagePath = "AcademicPaymentProof";
        else if (rowData.corporateMembersId > 0)
            storagePath = "CorporatePaymentProof";

        let pathName = storagePath;
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", pathName);
        uploadPDF(formData);
    }
    // Method for handle form fields changes
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "addPaymentProof" && value) {
            if ("name" in value) {
                handleOnChangeFileUpload(value)
            } else {
                handleImageUpload(value)
            }
        }
    }
    const handleClear = () => {
        const updatedForm = { ...outstandingInvoiceDetailModelData };
        updatedForm.initialState = {
            ...updatedForm.initialState,
            addPaymentProof: null,
        }
        setOutstandingInvoiceDetailModelData(updatedForm)
    }

    const formActionHandler = {
        CLEAR: handleClear,
    }
    return (
        <div className="d-flex">
            <div className='row gx-5 align-items-center'>
                <div className='col-lg-2 col-md-3 col-sm-3 col-12 mb-3 text-left mt-2'>
                    <h5 className='common-subtitle'>File 1</h5>
                </div>
                <div className='col-lg-8 col-md-6 col-sm-6 col-12 mb-4 input-textfield'>
                    <input type='text'
                        value={outstandingInvoiceDetailModelData?.initialState.addPaymentProof ? outstandingInvoiceDetailModelData?.initialState.addPaymentProof : ''}
                        disabled={true} className='form-input-group' style={{ width: "100%", height: "40px", border: "1px solid #ccc", padding: "10px" }} />
                </div>
                <FormCreator
                    ref={outstandingInvoiceDetailModelRef}
                    {...outstandingInvoiceDetailModelData}
                    onActionChange={formActionHandler}
                    onFormFieldValueChange={handleFormFieldChange}
                />
                <div className='col-lg-12 d-flex justify-content-center'>
                    <Buttons
                        buttonText="Submit"
                        buttonTypeClassName="theme-btn"
                        onClick={handleMembershipPaymentConfimation}
                        isLoading={membershipPaymentConfirmationLoading}
                    />
                </div>
            </div>
        </div>
    )

}
export default OutstandingInvoiceDetailPopup