import React, { useEffect, useRef, useState } from "react";

import { GridColumnType } from "../../data/gridColumnType";
import { renderGridMoneyColumn } from "./Grid Columns/MoneyColumn";
import { renderGridDateColumn } from "./Grid Columns/DateColumn";
import { renderGridLinkColumn } from "./Grid Columns/LinkColumn";
import { renderGridCheckboxColumn } from "./Grid Columns/CheckBoxColumn";
import { renderGridLableColumn } from "./Grid Columns/LabelColumn";
import RenderGridAction from './Grid Columns/ActionColumn';
// Function for rendering the action column
const MolGridDataRows = (props) => {

  const [editableGrid, setEditableGrid] = useState()
  const [editSelectedRow, setEditSelectedRow] = useState(null)
  const [editingRow, setEditingRow] = useState(null)
  const itemRef = useRef(null)

  useEffect(() => {
    if (props.newItemAdded) {
      handelEditableGrid(props.dataSource[props.dataSource.length - 1], props.dataSource.length - 1)
    }
    return () => {
      if (props.newItemAdded) {
        props.handleNewItemCancel()
      }
    }
  }, [props.newItemAdded])

  const handelEditableGrid = (data, rowIndex) => {
    setEditableGrid(true)
    setEditingRow(true)
    setEditSelectedRow({ ...data, rowIndex: rowIndex });
    setTimeout(() => itemRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 0);
  }

  const canceeEditGrid = () => {
    setEditableGrid(null)
    setEditingRow(null)
    setEditSelectedRow(null);
    if (props.newItemAdded) {
      props.handleNewItemCancel()
    }
  }

  const renderGridCol = (rowData, col, rowIndex, editingRow, editSelectedRow) => {
    if (!col.colType) {
      return rowData[col.fieldName];
    }
    switch (col.colType) {
      case GridColumnType.DATE:
        return renderGridDateColumn(rowData, col, rowIndex)
      case GridColumnType.MONEY:
        return renderGridMoneyColumn(rowData, col, rowIndex)
      case GridColumnType.LABLE:
        return renderGridLableColumn(rowData, col, rowIndex)
      case GridColumnType.LINK:
        return renderGridLinkColumn(rowData, col, rowIndex);
      case GridColumnType.CHECKBOX:
        return renderGridCheckboxColumn(rowData, col, rowIndex);
      case GridColumnType.ACTION:
        //return RenderGridAction(rowData, col, rowIndex, props.onActionChange)
        return <RenderGridAction
          rowData={rowData}
          col={col}
          rowIndex={rowIndex}
          onActionHandler={props.onActionChange}
          setEditableGrid={handelEditableGrid}
          editingRow={editingRow}
          editSelectedRow={editSelectedRow}
          canceeEditGrid={canceeEditGrid}
        />;
      case GridColumnType.CUSTOM:
        return col.renderCustomCol ? col.renderCustomCol(rowData, rowIndex) : "";
      default:
        return rowData[col.fieldName];
    }
  };

  const handleInputChnage = (e, colType) => {
    let { value, name } = e.target;
    let temp = { ...editSelectedRow }
    if (colType === GridColumnType.NUMBER) {
      const regex = /[^0-9]/g;
      value = value.replace(regex, '');
    }
    temp[name] = value
    setEditSelectedRow({ ...temp });
  }

  const rowClickAction = (actionName, data, rowIndex) => {
    if (props.onActionChange) {
      if (props.onActionChange[actionName]) {
        props.onActionChange[actionName](data);
      }
    }
  };
  const renderInputField = editableGrid && editSelectedRow;

  return (
    <>
      {/* <p>{JSON.stringify(props.dataSource)}</p> */}
      {props.dataSource && props.dataSource.map((row, rowIndex) => (
        <tr key={`row_${rowIndex}`}>
          {/* <p>{JSON.stringify(row)}</p> */}
          {props.columns.map((col, colIndex) => (
            <td key={`col_${colIndex}`} className="whitespace-nowrap">
              {/* <p>{JSON.stringify(col)}</p> */}
              {renderInputField && (col.colType === GridColumnType.Text || col.colType === GridColumnType.NUMBER)
                && rowIndex === editSelectedRow.rowIndex
                ?
                <input
                  ref={itemRef}
                  type="text"
                  name={col.fieldName}
                  value={editSelectedRow[col.fieldName]}
                  onChange={(e) => handleInputChnage(e, col.colType)}
                  id={`row-${row.rowIndex} - col-${colIndex}`}
                  className="input-field"
                />
                :
                <>
                  {renderInputField && col.colType === GridColumnType.ACTION && rowIndex === editSelectedRow.rowIndex
                    ?
                    renderGridCol(row, col, rowIndex, editingRow, editSelectedRow)
                    :
                    <>{props.isAllowRowClick ?
                      <div className="cpd-submission-row" onClick={(e) => { e.preventDefault(); rowClickAction("ROW_CLICK", row, rowIndex); }}>
                        {renderGridCol(row, col, rowIndex)}
                      </div>
                      :
                      renderGridCol(row, col, rowIndex)}</>
                  }
                </>
              }
              {/* {renderGridCol(row, col, rowIndex)} */}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
export default MolGridDataRows;