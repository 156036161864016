import React, { useEffect, useRef, useState } from 'react';
import Label from '../../../components/ui/label/Label';
import Input from '../../../components/ui/inputs/input/Input';
import MolGrid from '../../../components/Grid/MolGrid';
import { PaymmentOutstandingInvoiceListConfig } from '../data/OutstandingInvoiceDetailModel.Data';
import { useGetOutstandingInvoicesListMutation } from '../../../app/services/membershipFinancialService/MembershipFinancialServices';

const OutstandinInvoiceListPopup = ({ handleOpenDetailModal }) => {

    const outstandingInvoiceListRef = useRef();
    const [outstandingInvoiceListRequest, setOutstandingInvoiceListRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        searchText: "",
        totalCount: 0,
    });
    const [paymmentOutstandingInvoiceList, setPaymmentOutstandingInvoiceList] =
        useState([]);

    const [getOutstandingInvoicesList,
        {
            data: getOutstandingInvoicesListnResponse,
            isSuccess: isOutstandingInvoicesListSuccess,
            isLoading: OutstandingInvoicesListLoading,
        },
    ] = useGetOutstandingInvoicesListMutation();

    useEffect(() => {
        if (!OutstandingInvoicesListLoading, isOutstandingInvoicesListSuccess, getOutstandingInvoicesListnResponse) {
            setPaymmentOutstandingInvoiceList(getOutstandingInvoicesListnResponse.itemList);
            setOutstandingInvoiceListRequest({ ...outstandingInvoiceListRequest, totalCount: getOutstandingInvoicesListnResponse.totalCount })
        }
    }, [OutstandingInvoicesListLoading, getOutstandingInvoicesListnResponse, isOutstandingInvoicesListSuccess])

    useEffect(() => {
        getOutstandingInvoicesList({ ...outstandingInvoiceListRequest });
    }, [])

    const handleOutstandingInvoiceSearch = (e) => {
        setOutstandingInvoiceListRequest((previous) => {
            const tempPagination = {
                ...previous,
                pageNo: 1,
                searchText: e?.target?.value,
            };
            getOutstandingInvoicesList(tempPagination);
            return tempPagination;
        });
    };

    const handlePageChangeOutstandingInvoiceList = (newPage) => {
        setOutstandingInvoiceListRequest((previous) => {
            const tempPagination = {
                ...previous,
                pageNo: newPage.pageNumber,
                pageSize: newPage.pageSize,
            };
            getOutstandingInvoicesList(tempPagination);
            return tempPagination;
        });
    };

    const handleView = (data) => {
        if (data?.invoiceNo) {
            handleOpenDetailModal(data);
        }
    };

    const actionHandler = {
        VIEW: handleView,
    };

    const { pageNo, pageSize, totalCount } = outstandingInvoiceListRequest;

    const startIndex = (pageNo - 1) * pageSize + 1;
    const endIndex = Math.min(pageNo * pageSize, totalCount);

    return (
        <>
            <div className="d-flex">
                <div className="col">
                    <div className="col-lg-3 mb-1">
                        <Label labelName="Search" />
                    </div>
                    <div className="col-lg-4 mb-4">
                        <Input id="searchText"
                            name="SearchText"
                            onChange={handleOutstandingInvoiceSearch}
                        />
                    </div>
                    <div className="text-end">{`Showing ${startIndex} to ${endIndex} out of ${totalCount}`}</div>
                </div>
            </div>
            <MolGrid
                ref={outstandingInvoiceListRef}
                configuration={PaymmentOutstandingInvoiceListConfig}
                dataSource={paymmentOutstandingInvoiceList}
                allowPagination={true}
                isLoading={OutstandingInvoicesListLoading}
                onPageChange={handlePageChangeOutstandingInvoiceList}
                pagination={outstandingInvoiceListRequest}
                onActionChange={actionHandler}
            />
        </>
    )
}

export default OutstandinInvoiceListPopup