import { FormFieldTypes } from "../../../data/formFieldType";

const SuperUserLoginModalData = {
    name: "SuperUserLoginModalData",
    initialState: {
        //uenNo: "",
        password: "",
    },
    formFields: [
        // {
        //     id: "uenNo",
        //     lable: "Organisation UEN",
        //     Field_Name: "Organisation UEN",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "uenNo",
        //     fieldSetting: {
        //         allowSpace: true,
        //         isdisable : true,
        //     },
        //     // validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3 d-none"
        //     }
        // },
        {
            id: "password",
            lable: "Password",
            Field_Name: "password",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "password",
            fieldSetting: {
                placeholder: "Password",
            },
            style: {
                containerCss: "col-md-12"
            },
            validation: [{ type: "require" }]
        },

    ],
};
export default SuperUserLoginModalData;