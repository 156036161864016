import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator.js';
import Buttons from '../../../../components/ui/button/Buttons.js';
import { useLazyGetPDFQuery, useStoreBase64ImageMutation, useUploadPDFMutation } from '../../../../app/services/Common/CommonApi.js';
import ToastService from '../../../../services/toastService/ToastService.js';
import SwalAlert from '../../../../services/swalService/SwalService.js';
import { saveAs } from 'file-saver';

const EdcuationInformation = forwardRef(({ formData, editEdcuationInformation, ActionHandler }, ref) => {
    const edcuationInformationFormRef = useRef();
    const [uploadImageName, setUploadImageName] = useState("");
    // Upload image api call
    const { error, success } = SwalAlert();
    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: paymentProofImageUpload }
    ] = useStoreBase64ImageMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();
    // Upload pdf api method
    const [uploadPDF,
        { isSuccess: isUploadSuccess,
            isLoading: isUploadLoading,
            data: fileUploadData }
    ] = useUploadPDFMutation();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const data = edcuationInformationFormRef.current.getFormData();
                return data;
            }
            return null;
        }
    }));
    const handleOnChangeFileUpload = (dataField, data) => {
        let pathName = "EdcuationCertificate";
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", pathName);
        uploadPDF(formData);
    }
    const handleImageUpload = async (data) => {

        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "IndividualSignUpImage",
        }
        setUploadImageName(fileName);
        const response = await saveMainImage(saveFileRequest)
    }
    const handleFormFieldChange = (dataField, value) => {

        if ((dataField === "edcuationCertificate") && value) {
            if (value.type === "application/pdf") {
                handleOnChangeFileUpload(dataField, value)
            }
            else {
                handleImageUpload(value)
            }
        }
    };
    const handleClear = (data, fieldName) => {
        ActionHandler['CLEAR'](data, "EdcuationInformation")
    }
    const handleTableDelete = (rowData) => {
        ActionHandler['DELETE'](rowData, "EdcuationInformation")
    }
    const handleTableEdit = (rowData) => {
        ActionHandler['EDIT'](rowData, "EdcuationInformation")
    }
    // const handleTableDownload = (rowData) => {
    //     ActionHandler['DOWNLOAD'](rowData, "EdcuationInformation")
    // }
    const handleDownload = async (rowData) => {
        try {
            let fileNameParts = rowData.educationCertificatesFileName.split('.');
            let fileExtension = fileNameParts.pop().toLowerCase();
            let fileNameWithoutExtension = fileNameParts.join('.');

            if (fileExtension === 'pdf' || fileExtension === 'jpg' || fileExtension === 'png') {
                let request = {
                    type: "",
                    fileName: rowData.educationCertificatesFileName,
                };

                if (fileExtension === 'pdf') {
                    request.type = "EdcuationCertificate";
                } else if (fileExtension === 'jpg') {
                    request.type = "EdcuationCertificateImage";
                } else if (fileExtension === 'png') {
                    request.type = "individualSignUpImage";
                }
                const response = await getPDF(request);
                if (response?.data?.fileData && response?.data?.fileName) {
                    saveAs(response?.data?.fileData, response?.data?.fileName);
                }
            }
        } catch (error) {
            console.error('Failed to download the PDF. Please try again later.', error);
        }

    };
    const handleAdd = () => {
        ActionHandler['ADD'](formData.initialState, "EdcuationInformation")
    }
    const handleUpdate = () => {
        ActionHandler['UPDATE'](formData.initialState, "EdcuationInformation")
    }
    useEffect(() => {
        if (!isUploadLoading && isUploadSuccess && fileUploadData) {

        }
    }, [isUploadSuccess, isUploadLoading, fileUploadData,]);
    useEffect(() => {
        if (!isLoadingImage && isSuccessImage && paymentProofImageUpload) {
            ToastService.success("Image upload successfuly.")
        }
    }, [isLoadingImage, isSuccessImage, paymentProofImageUpload,]);

    const formActionHandler = {
        CLEAR: () => handleClear()
    }

    return (
        <div className="form-field-sec">
            <div className="row gx-5">
                <FormCreator
                    ref={edcuationInformationFormRef}
                    {...formData}
                    onActionChange={formActionHandler}
                    handleTableEdit={handleTableEdit}
                    handleTableDelete={handleTableDelete}
                    handleTableDownload={handleDownload}
                    onFormFieldValueChange={handleFormFieldChange}
                ></FormCreator>
            </div>
            {editEdcuationInformation ? <Buttons
                buttonTypeClassName="theme-btn ml-5"
                onClick={handleUpdate}
                buttonText={"Update"}
            /> : <Buttons
                buttonTypeClassName="theme-btn ml-5"
                onClick={handleAdd}
                buttonText={"Add"}
            />
            }

        </div>
    )
})

export default EdcuationInformation