import React from "react";
import AddEdiCampaignForm from "./AddEdiCampaignForm";
const AddEditCampaign = () => {
  return (
    <div className="campaign-section">
      <div className="campaign-data">
        <AddEdiCampaignForm />
      </div>
    </div>
  );
};

export default AddEditCampaign;
