import React, { useState } from "react";
const Input = React.lazy(() => import('../../ui/inputs/input/Input'));
const Label = React.lazy(() => import('../../ui/label/Label'));
const ValidationText = React.lazy(() => import('../../ui/inputs/validation/ValidationText.js'));


const FormInputGroup = ({
    name,
    value,
    inputError,
    onChange,
    dataField,
    labelName,
    placeholder,
    formSetting,
    onValidation,
    fieldSetting,
    fieldActions,
    ...inputProps
}) => {
    const [selectedFile, setSelectedFile] = useState(null)

    const capitalizeWords = (str) => {
        return str.toUpperCase();
    }

    const handleInputChange = (e) => {
        if (onChange) {
            if (e.target.type !== 'file') {
                setSelectedFile(null)
                const capitalizedValue = capitalizeWords(inputProps?.isEdit ? e.target.value[1] : e.target.value);
                onChange(dataField[1], capitalizedValue);
            }
        }
    };


    const handleInputOnBlur = () => {

        if (onValidation) {
            onValidation(dataField[1]);
        }
    };

    return (
        <>
            <div className="input-label-part">
                {labelName && labelName !== "" && <Label labelName={labelName} for={name} isRequired={inputProps.isRequired} />}
                {inputProps.hasMainTitle ?
                    <div className="section-title">
                        <h5>{inputProps.hasMainTitle}</h5>
                    </div>
                    : ""}
                <div className="input-grou d-flex" style={{ flexWrap: "nowrap" }}>
                    <div className="input-group-first">
                        {
                            inputProps?.isEdit ?
                                <Input
                                    {...inputProps}
                                    filename={selectedFile}
                                    value={value[0]}
                                    onChange={handleInputChange}
                                    isDisable={formSetting?.isViewOnly || inputProps?.isDisable || true}
                                />
                                :
                                <Input
                                    {...inputProps}
                                    filename={selectedFile}
                                    value={value[0]}
                                    onChange={handleInputChange}
                                    isDisable={formSetting?.isViewOnly || inputProps?.isDisable || true}
                                />
                        }
                    </div>
                    <div className="second-input">
                        {
                            inputProps?.isEdit ?
                                <Input
                                    {...inputProps}
                                    filename={selectedFile}
                                    value={value[1]}
                                    onChange={handleInputChange}
                                    onBlur={handleInputOnBlur}
                                    isDisable={formSetting?.isViewOnly || inputProps?.isDisable || false}
                                />
                                :
                                <Input
                                    {...inputProps}
                                    filename={selectedFile}
                                    value={value[1]}
                                    onChange={handleInputChange}
                                    onBlur={handleInputOnBlur}
                                    isDisable={formSetting?.isViewOnly || inputProps?.isDisable || false}
                                />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
export default FormInputGroup;