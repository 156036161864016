import { FormFieldTypes } from "../../../../../data/formFieldType";

const CommunicationData = {
    name: "CommunicationData",
    initialState: {
        "bulletCheckbox": false,
        "newsLetterCheckBox": false,
        "EDMCheckbox": false,
        "tagId": null,
    },
    formFields: [
        {
            id: "Communication",
            lable: "",
            Field_Name: "You are currently subscribed to: (untick to unsubscribe)",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "Communication",
            fieldSetting: {
              formTitle: "You are currently subscribed to: (untick to unsubscribe)",
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-left",
            },
          },        
        {
            id: "bulletCheckbox",
            lable: "Bulletin",
            Field_Name: "Bulletin",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "bulletCheckbox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-1",
            },
        },
        {
            id: "newsLetterCheckBox",
            lable: "Newsletter",
            Field_Name: "Newsletter",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "newsLetterCheckBox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-1",
            },
        },
        {
            id: "EDMCheckbox",
            lable: "EDM",
            Field_Name: "EDM",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "EDMCheckbox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-1",
            },
        },
        {
            id: "tagId",
            lable: "Tag",
            Field_Name: "Tag",
            fieldType: FormFieldTypes.SELECT,
            dataField: "tagId",
            fieldSetting: {
                isMultiSelect: true,
                options: []
            },
           
            validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
    ],
};
export default CommunicationData;