import { FormFieldTypes } from "../../../../../data/formFieldType";

const CorporateMembershipSubscriptionData = {
    name: "CorporateMembershipSubscriptionData",
    initialState: {
        membershipType: "",
        membershipTenure: "",
        preferredStartDate: "",
        membershipStartDate: "",
        membershipEndDate: "",
        billingCategory:"",


    },
    formFields: [
        {
            id: "membershipType",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipType",
            fieldSetting: {
                // placeholder: "",
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipTenure",
            lable: "Membership Tenure",
            Field_Name: "Membership Tenure",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTenure",
            fieldSetting: {
                // placeholder: "",
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipStartDate",
            lable: "Membership Start Date",
            Field_Name: "Membership Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "membershipStartDate",
            fieldSetting: {
                isDisable: false,
                dateFormat: "dd/MM/yyyy",
                // placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipEndDate",
            lable: "Membership End Date",
            Field_Name: "Membership End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "membershipEndDate",
            fieldSetting: {
                isDisable: false,
                dateFormat: "dd/MM/yyyy",
                // placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "preferredStartDate",
            lable: "Preferred Start Date (MM/YYYY)",
            Field_Name: "Preferred Start Date (MM/YYYY)",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "preferredStartDate",
            fieldSetting: {
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,
                // placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "billingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "billingCategory",
            fieldSetting: {
              
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
    ],
};

export default CorporateMembershipSubscriptionData;



