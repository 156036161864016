import React, {
  Suspense,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import { PaymentTasklistTableConfig } from "./data/PaymentReconciliationTable.Data";
import paymentReconciliationTasklistFormData from "./data/PaymentReconciliationTable.Data";
import CenterModel from "../../components/ui/centerModel/CenterModel";
import MolGrid from "../../components/Grid/MolGrid";
import formatDate from "../../lib/formatDate";
import PaymentApproval from "./component/PaymentApproval";
// Import API sservice
import { useGetEventPaymentReconcilationListMutation } from "../../app/services/membershipFinancialService/MembershipFinancialServices";
import SwalAlert from "../../services/swalService/SwalService";
import OutstandingInvoiceDetailPopup from "./component/OutstandingInvoiceDetailPopup";
import OutstandinInvoiceListPopup from "./component/OutstandinInvoiceListPopup";

const PaymentReconciliationForm = forwardRef((props, ref) => {
  const [formData, SetFormData] = useState(
    paymentReconciliationTasklistFormData
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const formRef = useRef();
  const molGridRef = useRef();
  const { success, error, warning } = SwalAlert();
  const [selectedReconciliationRecord, setSelectedReconciliationRecord] =
    useState(null);
  const [
    membershipPaymentReconciliationList,
    setMembershipPaymentReconciliationList,
  ] = useState(null);
  const [selectedOutstandingInvoiceData, setSelectedOutstandingInvoiceData] =
    useState();

  const [paymentListArr, setPaymentListArr] = useState([]);
  const [remaingAmount, setRemainAmount] = useState();
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    filterDate: null,
    totalCount: 0,
    statusId: null,
  });

  const [
    paymentReconciliationList,
    {
      isSuccess: isSuccessList,
      isLoading: isLoadingList,
      data: paymentReconciliationListResponse,
    },
  ] = useGetEventPaymentReconcilationListMutation();

  useEffect(() => {
    getMemberList();
  }, []);

  useEffect(() => {
    if (!isLoadingList && isSuccessList && paymentReconciliationListResponse) {
      let finalResponseList = paymentReconciliationListResponse.itemList.map(
        (obj) => {
          return {
            ...obj,
            registrationDate: formatDate(obj?.registrationDate, "DD/MM/YYYY"),
          };
        }
      );
      setPaginationRequest({
        ...paginationRequest,
        totalCount: paymentReconciliationListResponse.totalCount,
      });
      setMembershipPaymentReconciliationList(finalResponseList);
    }
  }, [isLoadingList, isSuccessList, paymentReconciliationListResponse]);

  const handlePageChange = (newPage) => {
    setPaginationRequest((previous) => {
      const tempPagination = {
        ...previous,
        pageNo: newPage.pageNumber,
        pageSize: newPage.pageSize,
      };
      getMemberList(tempPagination);
      return tempPagination;
    });
  };

  // Generate request to get payment reconciliation list
  const getMemberList = (rData) => {
    const request = {
      ...paginationRequest,
      ...rData,
    };
    paymentReconciliationList(request);
  };

  // Toggle modal visibility for rejection
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
    getMemberList();
  };

  const handleOpenAddModal = () => {
    setIsModalOpenAdd((prev) => !prev);
  };

  const openAddModal = () => {
    setIsModalOpenAdd(!isModalOpenAdd);
  };

  const handleOpenDetailModal = (rowData) => {
    setIsModalOpenDetail((prev) => !prev);
    if (rowData) {
      setSelectedOutstandingInvoiceData(rowData);
    }
  };

  // const openDetailModal = () => {
  //     setIsModalOpenDetail(!isModalOpenDetail);
  // };

  const handleView = (data) => {
    if (data?.bookingId) {
      let bookingId = data?.bookingId;
      const reconciliationTotalAmount = data?.reconciliationTotalAmount;
      let paymentListArr = [];
      membershipPaymentReconciliationList.forEach((item) => {
        if (item.bookingId === bookingId) {
          paymentListArr = paymentListArr
            ?.concat(item.paymentList)
            ?.filter(
              (entry) =>
                entry.amountCollected !== null && entry.amountCollected !== 0
            );
        }
      });
      const totalCollectedAmount = paymentListArr.reduce((sum, payment) => {
        return sum + (payment.amountCollected || 0);
      }, 0);
      const remainingTotalAmount =
        reconciliationTotalAmount - totalCollectedAmount;
      setRemainAmount(remainingTotalAmount);
      setPaymentListArr(paymentListArr);
      setSelectedReconciliationRecord(data);
      setIsModalOpen(true);
    }
  };

  const handleSearchApplication = () => {
    const searchFormData = formRef.current.getFormData();
    let data = {
      reconcilationStatus:
        searchFormData.status?.label || searchFormData?.status,
      filterDate: searchFormData.date,
      SearchText:
        searchFormData.searchInvoice?.label || searchFormData?.searchInvoice,
      //pageNo: 1
    };
    setPaginationRequest({ ...paginationRequest, ...data });
    getMemberList(data);
  };
  const actionHandler = {
    VIEW: handleView,
  };
  return (
    <React.Fragment>
      <div className="row gx-5 bg-light tasklist-form">
        <FormCreator ref={formRef} {...formData} />
        <div className="col-lg-2 col-md-4 mb-1 d-flex justify-content-start align-items-center ml-3 mt-3">
          <div className="">
            <div className="custom-button payment">
              <Buttons
                buttonText="Search"
                buttonTypeClassName="theme-btn "
                onClick={handleSearchApplication}
              />
              <Buttons
                buttonText="Add Records"
                buttonTypeClassName="theme-btn "
                onClick={handleOpenAddModal}
              />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-span-12">
            <div className="card-section mt-4">
              <MolGrid
                ref={molGridRef}
                configuration={PaymentTasklistTableConfig}
                dataSource={membershipPaymentReconciliationList}
                allowPagination={true}
                isLoading={isLoadingList}
                onPageChange={handlePageChange}
                pagination={paginationRequest}
                onActionChange={actionHandler}
              />
            </div>
            {isModalOpen && (
              <CenterModel
                modelTitle={"Payment Approval"}
                show={"test"}
                handleClose={openModal}
                onUpload={openModal}
              >
                <Suspense fallback={<div>Loading Editor...</div>}>
                  <PaymentApproval
                    selectedReconciliationRecord={selectedReconciliationRecord}
                    paymentListArr={paymentListArr}
                    remaingAmount={remaingAmount}
                    closePopUp={openModal}
                  />
                </Suspense>
              </CenterModel>
            )}
          </div>
        </div>
        {isModalOpenAdd && (
          <CenterModel
            modelTitle={"Outstanding Invoices List"}
            show={"test"}
            handleClose={openAddModal}
          >
            <>
              <OutstandinInvoiceListPopup
                handleOpenDetailModal={handleOpenDetailModal}
              />
            </>
          </CenterModel>
        )}

        {isModalOpenDetail && (
          <CenterModel
            modelTitle={"Outstanding Invoice Details"}
            show={"test"}
            handleClose={handleOpenDetailModal}
            className="invoice-detail-model"
          >
            <OutstandingInvoiceDetailPopup
              rowData={selectedOutstandingInvoiceData}
              getMemberList={getMemberList}
              handleOpenDetailModal={handleOpenDetailModal}
              openAddModal={openAddModal}
            />
          </CenterModel>
        )}
      </div>
    </React.Fragment>
  );
});

export default PaymentReconciliationForm;
