import { NavigateButton } from "../../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";
import GenratePDF from "../../IndividualMemberTaskList/component/GenratePDF";


// gmapApplicationTasklistTableConfig 
export const academicMemberTasklistTableConfig = {
    columns: [

        {
            name: "Company Name",
            fieldName: "organisationName",
            allowShort: false,
        },
        {
            name: "Company UEN",
            fieldName: "uenNo",
            allowShort: false,
        },
        {
            name: "Membership Type ",
            fieldName: "membershipTypeName",
            allowShort: false,
        },
        {
            name: "Transaction Type",
            fieldName: "transactionTypeName",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "statusName",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "View", onClickAction: true },
                { name: "Delete", onClickAction: true },
            ],
        },
    ],
};



const academicMemberTasklistFormData = {
    name: "AcademicData",
    initialState: {
        name: "",
        membershipTypeId: "",
        statusId: "",
    },
    formFields: [
        {
            id: "name",
            lable: "Company Name/UEN",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "membershipTypeId",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
        {
            id: "statusId",
            lable: "Status",
            Field_Name: "status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "statusId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
    ],
};

export default academicMemberTasklistFormData;

export const academicFormData = {
    name: "academicFormData",
    initialState: {
        instituteName: "",
        uenNo: "",
        institutionAddressLine1: "",
        institutionAddressLine2: "",
        institutionAddressLine3: "",
        postalCode: "",
        aCRABizFile: "",
        billingCategory: "",
        updatedBy: "",
        // subscriptionStartDate: "",
        // subscriptionEndDate: "",
        // lastUpdatedDate: "",
        membershipTypeId: '',
        membershipTenureId: '',
        isStartMembershipOn: false,
        startMembershipDate: '',
        entrenceFee: '',
        annualFee: '',
        complimentary: '',
        comment: "",
        internalNote: ""

    },
    formFields: [
        {
            id: "institutionDetails",
            lable: "",
            Field_Name: "institutionDetails",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "institutionDetails",
            fieldSetting: {
                formTitle: "Institution Details",
            },
            style: {
                containerCss: "col-lg-6 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "generatePdf",
            dataField: 'generatePdf',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <GenratePDF type="Academic" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-6 mb-2 d-flex justify-content-end form-tab-header",
            }
        },
        {
            id: "instituteName",
            lable: "Name of Institution as in ACRA",
            Field_Name: "Name of Institution as in ACRA",
            fieldType: FormFieldTypes.INPUT,
            dataField: "instituteName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "uenNo",
            lable: "UEN No.",
            Field_Name: "UEN No.",
            fieldType: FormFieldTypes.INPUT,
            dataField: "uenNo",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "institutionAddressLine1",
            lable: "Institution Address Line 1",
            Field_Name: "Institution Address Line 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionAddressLine1",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "institutionAddressLine2",
            lable: "Institution Address Line 2",
            Field_Name: "Institution Address Line 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionAddressLine2",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },

        {
            id: "institutionAddressLine3",
            lable: "Institution Address Line 3",
            Field_Name: "Institution Address Line 3",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionAddressLine3",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "postalCode",
            lable: "Postal Code",
            Field_Name: "Postal Code",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "postalCode",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "aCRABizFile",
            lable: 'ACRA BizFile (please upload in pdf format max 2mb)',
            Field_Name: 'ACRA BizFile',
            subLabelName: '(please upload in pdf/jpg format max 2mb)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'aCRABizFile',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "navigatePath",
            dataField: 'navigatePath',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <NavigateButton name={"View Academic Information"} path={'/editAcademicMembers'} navStyle="theme-btn" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-end",
            }
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "membershipSub",
            lable: "",
            Field_Name: "membershipSub",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "membershipSub",
            fieldSetting: {
                formTitle: "Membership Subscription",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "billingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "billingCategory",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "updatedBy",
            lable: "Updated By",
            Field_Name: "Updated By",
            fieldType: FormFieldTypes.INPUT,
            dataField: "updatedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "subscriptionStartDate",
            lable: "Subscription Start Date",
            Field_Name: "Subscription Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionStartDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            id: "subscriptionEndDate",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,

            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            id: "lastUpdatedDate",
            lable: "Last Updated Date",
            Field_Name: "Last Updated Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "lastUpdatedDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "pricingTable",
            lable: "pricingTable",
            Field_Name: "pricingTable",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "pricingTable",
            fieldSetting: {
                formTitle: "Pricing Table",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },

        {
            id: "isStartMembershipOn",
            lable: "Start Membership On",
            Field_Name: "Start Membership On",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isStartMembershipOn",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-2 mt-4 text-nowrap",
            },
        },
        {
            id: "startMembershipDate",
            lable: "   ",
            Field_Name: "StartMembership Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "startMembershipDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,

            },
            style: {
                containerCss: "col-lg-3 mb-3",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-3",
            }
        },

        {
            id: "paymentTerms",
            lable: "Payment Terms",
            Field_Name: "Payment Terms",
            fieldType: FormFieldTypes.SELECT,
            dataField: "paymentTerms",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-8",
            }
        },
        {
            id: "membershipTenureId",
            lable: "Membership Tenure",
            Field_Name: "Membership Tenure",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTenureId",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-8",
            }
        },
        {
            id: "entrenceFee",
            lable: "Entrance Fee",
            Field_Name: "Entrance Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "entrenceFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: false
            },
            style: {
                containerCss: "col-md-2 mb-3",
            },
        },
        {
            id: "annualFee",
            lable: "Annual Fee",
            Field_Name: "Annual Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "annualFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: false
            },
            style: {
                containerCss: "col-md-2 mb-3",
            },
        },
        {
            id: "complimentary",
            lable: "Complimentary Individual Membership",
            Field_Name: "Complimentary Individual Membership",
            fieldType: FormFieldTypes.INPUT,
            dataField: "complimentary",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "invoiceGeneration",
            lable: "",
            Field_Name: "invoiceGeneration",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "invoiceGeneration",
            fieldSetting: {
                formTitle: "Invoice Generation",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "comment",
            lable: "Comments to member",
            Field_Name: "Comments to member",
            fieldType: FormFieldTypes.INPUT,
            dataField: "comment",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },
        {
            id: "internalNote",
            lable: "Internal Notes",
            Field_Name: "Internal Notes",
            fieldType: FormFieldTypes.INPUT,
            dataField: "internalNote",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },

    ],
};
