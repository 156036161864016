export const TextInputType = {
    TEXT: "text",
    FILE:"file",
    EMAIL: "email",
    PASSWORD: "password",
    NUMBER: "number",
    CHECKBOX: "checkbox",
    DATEPICKER:"datepicker",
    TIME: "time"
}

export const NumberValueType = {
    INT: "INT",
    DECIMAL: "DECIMAL",
}
