import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import SwalAlert from "../../services/swalService/SwalService";
import FormCreator from "../../components/Forms/FormCreator";
import DataLoader from "../../components/ui/dataLoader/DataLoader";
import MolGrid from "../../components/Grid/MolGrid";
import { DropdownSelectConfig } from "./dataFile/DropdownSelectConfig";
import DropdownManagementFormData from "./dataFile/DropdownManagementForm.Data";
import { useAddEditDropdownDataMutation, useDeleteDropdowndataMutation, useGetDropdownRecordListMutation, useLazyGetDropdownDatabyIdQuery } from "../../app/services/DropdoenManagement/DropdownManagementApi";

const DropdownManagementFeature = () => {
    const { success, error, confirm } = SwalAlert();
    const [selectedDropdownId, setSelectedDropdownId] = useState();
    const dropDownSelectRef = useRef(null);
    const dropdownManagementFeatureRef = useRef();
    const [dropdownSelected, setDropdownSelected] = useState(null);

    const [getDropdownRecordList, { isSuccess: isSuccessDropdown, isFetching: isFetchingDropdown, data: dropdownResponse }] = useGetDropdownRecordListMutation();
    const [getDropdownDatabyId, { isSuccess: isSuccessEmailById, isFetching: isFetchingEmailById, data: dropdownByIdResponse }] = useLazyGetDropdownDatabyIdQuery();
    const [deleteDropdowndata, { isSuccess: isSuccessDeleteDropdowndata, data: deleteDropdowndataResponse }] = useDeleteDropdowndataMutation();
    const [addEditDropdown, { isSuccess: isSuccessAddEditDropdown, isFetching: isFetchingAddEditDropdown, data: addEditDropdownResponse }] = useAddEditDropdownDataMutation();

    useEffect(() => {
        if (isSuccessAddEditDropdown && addEditDropdownResponse && !addEditDropdownResponse.hasError) {
            success("Dropdown updated successfully");
            getDropdownDatabyId(selectedDropdownId);
        } else if (addEditDropdownResponse?.hasError) {
            error(addEditDropdownResponse.errorMessage);
        }
    }, [isSuccessAddEditDropdown, addEditDropdownResponse]);

    useEffect(() => {
        if (isSuccessDeleteDropdowndata && deleteDropdowndataResponse && !deleteDropdowndataResponse.hasError) {
            success("Dropdown deleted successfully");
            getDropdownDatabyId(selectedDropdownId);
        } else if (deleteDropdowndataResponse?.hasError) {
            error(deleteDropdowndataResponse.errorMessage);
        }
    }, [isSuccessDeleteDropdowndata, deleteDropdowndataResponse]);

    useEffect(() => {
        getDropdownRecordList();
    }, [getDropdownRecordList]);

    useEffect(() => {
        if (isSuccessEmailById && dropdownByIdResponse) {
            setDropdownSelected(dropdownByIdResponse);
        }
    }, [isSuccessEmailById, dropdownByIdResponse]);

    useEffect(() => {
        if (isSuccessDropdown && dropdownResponse) {
            const field = DropdownManagementFormData.formFields.find((obj) => obj.id === "Dropdown");
            field.fieldSetting.options = dropdownResponse.map((obj) => ({ value: obj.id, label: obj.name }));
        }
    }, [isSuccessDropdown, dropdownResponse]);

    const onDropdownChange = useCallback(
        (data) => {
            const { value } = data.Dropdown;
            setSelectedDropdownId(value);
            getDropdownDatabyId(value);
        },
        [getDropdownDatabyId]
    );

    const handleSave = useCallback(
        (data) => {
            const request = {
                DropdownId: selectedDropdownId,
                TabelDataId: data.id ? data.id : 0,
                Name: data.name,
                ValueOrdering: parseInt(data.valueOrdering) || "",
                CreatedBy: 115,
                CreatedAppId: 1,
            };
            addEditDropdown(request);
        },
        [addEditDropdown, selectedDropdownId]
    );

    const handleDelete = useCallback(
        (data) => {
            confirm("Delete", "Are you sure you want to delete?", "Yes", "Cancel").then((value) => {
                if (value) {
                    const request = {
                        TabelDataId: data.id,
                        Id: selectedDropdownId,
                        deletedBy: 115,
                        appId: 1,
                    };
                    deleteDropdowndata(request);
                }
            });
        },
        [confirm, deleteDropdowndata, selectedDropdownId]
    );

    const handleAdd = useCallback(() => {
        const valueOrdering = dropdownSelected[dropdownSelected.length - 1]?.valueOrdering + 1 || 1;
        setDropdownSelected((prev) => [...prev, { id: 0, name: "", valueOrdering }]);
    }, [dropdownSelected]);

    const actionHandler = useMemo(
        () => ({
            SAVE: handleSave,
            DELETE: handleDelete,
        }),
        [handleSave, handleDelete]
    );

    return (
        <>
            {dropdownResponse ? (
                <div className="d-flex flex-wrap">
                    <FormCreator ref={dropdownManagementFeatureRef} {...DropdownManagementFormData} onFormDataChange={onDropdownChange} />
                </div>
            ) : (
                <DataLoader />
            )}
            <MolGrid
                ref={dropDownSelectRef}
                configuration={DropdownSelectConfig}
                dataSource={dropdownSelected}
                allowPagination={false}
                onActionChange={actionHandler}
                isLoading={isFetchingEmailById}
                allowAddGridItem={selectedDropdownId ? true : false}
                addButtonText="Add Dropdown"
                onAddItem={handleAdd}
            />
        </>
    );
};

export default DropdownManagementFeature;



export const CustomDataGrid = ({ rows }) => {
    const [data, setData] = useState(rows);

    const editRow = (id) => {
        setData((prevData) =>
            prevData.map((row) =>
                row.id === id
                    ? { ...row, isEditing: true }
                    : row
            )
        );
    };

    const cancelEdit = (id) => {
        setData((prevData) =>
            prevData.map((row) =>
                row.id === id
                    ? { ...row, isEditing: false }
                    : row
            )
        );
    };

    const saveRow = (id) => {
        setData((prevData) =>
            prevData.map((row) => {
                if (row.id === id) {
                    const updatedRow = {
                        ...row,
                        isEditing: false,
                    };
                    Object.keys(row).forEach((key) => {
                        updatedRow[key] = document.getElementById(`${key}-${id}`).value;
                    });
                    return updatedRow;
                }
                return row;
            })
        );
    };

    const deleteRow = (id) => {
        setData((prevData) => prevData.filter((row) => row.id !== id));
    };

    return (
        <table>
            <thead>
                <tr>
                    {Object.keys(rows[0]).map((key) => (
                        <th key={key}>{key}</th>
                    ))}
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <p>{JSON.stringify(data)}</p>
                {data.map((row) => (
                    <tr key={row.id}>
                        {Object.keys(row).map((key) => (
                            <td key={`${key}-${row.id}`}>
                                {row.isEditing && key !== 'id' ? (
                                    <><p>{key}</p>
                                        <input
                                            type="text"
                                            defaultValue={row[key]}
                                            id={`${key}-${row.id}`}
                                        />
                                    </>
                                ) : (
                                    row[key]
                                )}
                            </td>
                        ))}
                        <td>
                            {row.isEditing ? (
                                <>
                                    <button onClick={() => saveRow(row.id)}>Save</button>
                                    <button onClick={() => cancelEdit(row.id)}>Cancel</button>
                                </>
                            ) : (
                                <>
                                    <button onClick={() => editRow(row.id)}>Edit</button>
                                    <button onClick={() => deleteRow(row.id)}>Delete</button>
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

