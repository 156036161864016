// Menus & Header Images

// Others
import calender from "../assets/images/calender.png";
import logo from "../assets/images/logo.png";
import edit from "../assets/images/editIcon.png";
import deleted from "../assets/images/deleteIcon.png";
import copy from "../assets/images/copy.png";
import user from "../assets/images/user.png";
import search from "../assets/images/search.png";
import notification from "../assets/images/notification.png";
import arrow from "../assets/images/arrow.png";
import setting from "../assets/images/setting.png";
import logout from "../assets/images/logout.png";
import shortingArrowIcon from "../assets/images/arrow.png";
import download from "../assets/images/Download.png";
import Authanticationbg from "../assets/images/authanticationbg.png";
import save from "../assets/images/save.png"
import cancel from "../assets/images/cancel.png";

// footer icon
import facebook from "../assets/images/footfacebook.png";
import footinstagram from "../assets/images/footinstagram.png";
import footmail from "../assets/images/footmail.png";
import footlinkedin from "../assets/images/footlinkedin.png";
import footyoutube from "../assets/images/footyoutube.png";
import footteli from "../assets/images/footteli.png";
import SuccessIcon from "../assets/images/success-icon.png";
import tickIcon from "../assets/images/tickIcon.png";
import unTickIcon from "../assets/images/unTickIcon.png";
import pendingIcon from "../assets/images/pendingIcon.png";

export const AppIcons = {
  // Menus & Header Images

  // Others
  calenderIcon: calender,
  logoImage: logo,
  editIcon: edit,
  deleteIcon: deleted,
  copyIcon: copy,
  userIcon: user,
  searchIcon: search,
  notificationIcon: notification,
  arrowIcon: arrow,
  settingIcon: setting,
  logoutIcon: logout,
  downloadIcon: download,
  Authanticationbg: Authanticationbg,
  saveIcon: save,
  cancelIcon: cancel,

  // footer icon set
  facebook: facebook,
  footinstagram: footinstagram,
  footlinkedin: footlinkedin,
  footmail: footmail,
  footyoutube: footyoutube,
  footteli: footteli,
  shortingArrowIcon: shortingArrowIcon,
  SuccessIcon: SuccessIcon,
  TickIcon: tickIcon,
  UnTickIcon: unTickIcon,
  PendingIcon: pendingIcon,
};
