import { FormFieldTypes } from "../../../data/formFieldType";

export const EditAdminAccountData = {
    name: "Create Admin Account",
    initialState: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "",
        fileUpload: "",               
        isAdminQRScan: false,
    },
    formFields: [
        {
            id: "First Name",
            lable: "First Name",
            Field_Name: "firstName",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "firstName",
            validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "Last Name",
            lable: "Last Name",
            Field_Name: "lastName",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "lastName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "Email",
            lable: "Email",
            Field_Name: "email",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "email",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        // {
        //     id: "Password",
        //     lable: "Password",
        //     Field_Name: "password",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "password",
        //     fieldSetting: {
        //         placeholder: "",
        //         allowSpace: true,
        //     },
        //     style: {
        //         containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
        //     }
        // },
        {
            id: "Role",
            lable: "Role",
            Field_Name: "role",
            fieldType: FormFieldTypes.SELECT,
            dataField: "role",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "",
            lable: "",
            Field_Name: "profilePic",
            fieldType: FormFieldTypes.FILE,
            dataField: "fileUpload",
            // validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-0 mt-3"
            }
        },
        {
            id: "isAdminQRScan",
            lable: "QR code has been set up",
            Field_Name: "QR code has been set up",
            fieldType: FormFieldTypes.TOGGLEINPUT,
            dataField: "isAdminQRScan",
            fieldSetting: {
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
    ],
};



export const UploadFileFormData = {
    name: "Create Admin Account",
    initialState: {
        fileUpload: "",
    },
    formFields: [
        {
            id: "",
            lable: "",
            Field_Name: "fileUpload",
            fieldType: FormFieldTypes.FILE,
            dataField: "fileUpload",
            validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "text-center mx-auto mb-3"
            }
        },

    ],
};



