import { GridColumnType } from "../../../data/gridColumnType";

export const DropdownSelectConfig = {
    columns: [
        {
            name: "Dropdown Name",
            fieldName: "name",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Value Order",
            fieldName: "valueOrdering",
            allowShort: false,
            colType: GridColumnType.NUMBER,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            editableGrid: true,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }
        },
    ],
};