import { FormFieldTypes } from "../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";

export const EngagementTrackingAndNotesGridConfig = {
  columns: [
    {
      name: "Engagement Type",
      fieldName: "engagementType",
      allowShort: false,
    },
    {
      name: "Description",
      fieldName: "description",
      allowShort: false,
    },
    {
      name: "Follow Up",
      fieldName: "followUp",
      allowShort: false,
      // colSettings: {
      //     isOpenNewTab: true,
      //     url: "$website",
      // },
    },
    {
      name: "Engagement Date",
      fieldName: "date",
      colType: GridColumnType.DATE,
      allowShort: false,
      colSettings: {
        format: "DD/MMM/YYYY",
      },
    },
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: false,
        allowDelete: false,
      },
      customActions: [
        { name: "Edit", onClickAction: true },
        { name: "Preview", onClickAction: true },
        { name: "Duplicate", onClickAction: true },
      ],
    },
  ],
};

export const EngagementTrackingNotesData = {
  name: "EngagementTrackingNotesData",
  initialState: {
    engagementType: 2,
    engagementDate: "",
    description: "",
    recordedBy: 0,
  },
  formFields: [
    {
      id: "engagementType",
      lable: "Engagement Type",
      Field_Name: "Engagement Type",
      fieldType: FormFieldTypes.SELECT,
      dataField: "engagementType",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
        isDisable: true,
      },
      validation: [],
      style: {
        containerCss: "col-md-6 mb-3  ",
      },
    },
    {
      id: "engagementDate",
      lable: "Engagement Date",
      Field_Name: "Engagement Date",
      fieldType: FormFieldTypes.DATEPICKER,
      dataField: "engagementDate",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      
      validation: [],
      style: {
        containerCss: "col-md-6 mb-3  ",
      },
    },
    {
      id: "description",
      lable: "Description",
      Field_Name: "Description",
      fieldType: FormFieldTypes.TEXTAREA,
      dataField: "description",
      fieldSetting: {
        options: [],
        maxLength:250
      },
      validation: [],
      style: {
        containerCss: "col-md-6 mb-3  ",
      },
    },
    {
      id: "recordedBy",
      lable: "Recorded by",
      Field_Name: "Recorded by",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recordedBy",
      fieldSetting: {
        options: [],
        isDisable: true,
      },
      validation: [],
      style: {
        containerCss: "col-md-6 mb-3  ",
      },
    },
    // {
    //     id: "followUpBy",
    //     lable: "Follow Up by",
    //     Field_Name: "Follow Up by",
    //     fieldType: FormFieldTypes.SELECT,
    //     dataField: "followUpBy",
    //     fieldSetting: {
    //         options: []
    //     },
    //     validation: [],
    //     style: {
    //         containerCss: "col-md-6 mb-3  "
    //     }
    // },
  ],
  formSetting: {
    isViewOnly: false,
  },
};
