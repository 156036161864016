import { FormFieldTypes } from "../../../../../data/formFieldType";


const ApplicationBackgroundData = {
    name: "ApplicationBackgroundData",
    initialState: {
        isconvicted: "yes",
        convictedFilePath: "",
        isSuspended: "yes",
        suspendedFilePath: "",
        isBankrupt: "yes",
        bankruptFilePath: "",
        isBodyTerminated: "yes",
        bodyTerminatedFileName: ""
    },
    formFields: [
        {
            id: "isconvicted",
            label: "Have you ever been convicted in a court of law of any country?",
            Field_Name: "Have you ever been convicted in a court of law of any country?",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isconvicted",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "yes", value: "yes", isDisable: false, optionClass: "", },
                    { label: "no", value: "no", isDisable: false, optionClass: "ml-3", },
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },

        {
            id: "convictedFilePath",
            lable: 'If Yes, Upload Information (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'convictedFilePath',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                 isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
                //imageCrop: false,
            },
            // validation: [],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "isSuspended",
            label: "Have you ever been dismissed, discharged, or suspended from employment?",
            Field_Name: "Have you ever been dismissed, discharged, or suspended from employment?",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isSuspended",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "yes", value: "yes", isDisable: false, optionClass: "", },
                    { label: "no", value: "no", isDisable: false, optionClass: "ml-3", },
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "suspendedFilePath",
            lable: 'If Yes, Upload Information (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'suspendedFilePath',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                 isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
                //imageCrop: false,
            },
            // validation: [],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "isBankrupt",
            label: "Have you ever been a bankrupt?",
            Field_Name: "Have you ever been a bankrupt?",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isBankrupt",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "yes", value: "yes", isDisable: false, optionClass: "", },
                    { label: "no", value: "no", isDisable: false, optionClass: "ml-3", },
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "bankruptFilePath",
            lable: 'If Yes, Upload Information (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'bankruptFilePath',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                 isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
                //imageCrop: false,
            },
            // validation: [],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "isBodyTerminated",
            label: "Have you been refused entry to any professional body or have your membership or registration with such body terminated or suspended?",
            Field_Name: "Have you been refused entry to any professional body or have your membership or registration with such body terminated or suspended?",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isBodyTerminated",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "yes", value: "yes", isDisable: false, optionClass: "", },
                    { label: "no", value: "no", isDisable: false, optionClass: "ml-3", },
                    
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "bodyTerminatedFileName",
            lable: 'If Yes, Upload Information (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'bodyTerminatedFileName',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                 isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
               // imageCrop: false,
            },
            // validation: [],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
    ],
};
export default ApplicationBackgroundData;




