import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const membershipPricingAPI = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'MembershipPricingAPI',
    endpoints: (builder) => ({
        GetAllMembershipPricingForAllMembers: builder.query({
            query: () => ({
                url: '/MembershipPricing/GetAllMembershipPricingForAllMembers',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditAllMembershipPricingForAllMembers: builder.mutation({
            query: (details) => ({
                url: '/MembershipPricing/AddEditAllMembershipPricingForAllMembers',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useLazyGetAllMembershipPricingForAllMembersQuery,
    useAddEditAllMembershipPricingForAllMembersMutation,
} = membershipPricingAPI;

export default membershipPricingAPI;