import { useEffect, useRef, useState } from "react";
import { AppIcons } from "../../../data/appIcons";
import Image from "../../image/Image";
import Buttons from "../../ui/button/Buttons";
import { Link } from "react-router-dom";
import { useAsync } from "react-select/async";
import { useCol } from "react-bootstrap/esm/Col";

const RenderGridAction = ({ rowData, col, rowIndex, onActionHandler, setEditableGrid, editingRow, editSelectedRow, canceeEditGrid }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);


  if (!col.defaultAction) {
    col.defaultAction = {
      allowEdit: true,
      allowDelete: true,
      allowDownload:true,

    };
  }

  if (col.renderCustomAction) {
    col?.renderCustomAction(rowData, col);
  }

  const handleAction = (actionName, data, rowIndex) => {
    if (col.editableGrid && actionName === 'EDIT') {
      setEditableGrid(data, rowIndex);
    }
    else if (col.editableGrid && actionName === 'CANCEL') {
      canceeEditGrid();
    }
    else if (onActionHandler) {
      if (onActionHandler[actionName]) {
        onActionHandler[actionName](data, rowIndex);
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };



  return (
    <div className="d-flex action-button">
      {col.customActions && col.customActions.length > 0 && (
        <div
          className={`dropdown-action ${isDropdownOpen ? "openAction" : ""}`}
          ref={dropdownRef}
        >
          <Buttons
            buttonTypeClassName="btn-drop"
            textWithIcon={true}
            imagePath={AppIcons.arrowIcon}
            onClick={toggleDropdown}
          ></Buttons>
          <div className="dropdown-list">
            <ul>
              {
                col.customActions.map((action, index) => (
                  < li key={`customAction_${index}`}>
                    < Link onClick={
                      (e) => {
                        e.preventDefault(e);
                        closeDropdown();
                        action.onClickAction && handleAction("CUSTOM_ACTION_CLICK", rowData, action.name);
                      }}
                      to={action.path}>
                      {action.name}
                    </ Link >
                  </ li >
                ))}
            </ul>
          </div>
        </div>
      )}
      {col.customAction &&
        col.customAction.length > 0 &&
        col.customAction.map((action, index) => {
          const commonLinkProps = {
            key: `customAction_${index}`,
            onClick: (e) => {
              e.preventDefault();
              handleAction(action.name, rowData);
            },
            className: "mr-4",
            title: action.name,
            href: "",

          };
          // Add condition to check if rowData.contractInputFile is present
          if (action.name === "DOWNLOAD" && !rowData.contractInputFile) {
            return null;
          } else {
            // Render null if rowData.contractInputFile is not present
            return (
              <>
                {action.iconNam ? <Link {...commonLinkProps}>
                  <Image imagePath={action.iconName} altText={action.name} />
                </Link> :
                  <Link className="text-decoration-underline" {...commonLinkProps}>{action.name}</Link>
                }
              </>
            );
          }
        })}

      {editingRow && col.editableGrid && col.defaultAction?.allowEdit ?
        <>
          <Link
            onClick={(e) => {
              e.preventDefault();
              setEditableGrid(null)
              handleAction("SAVE", editSelectedRow, rowIndex);
            }}
            className="mr-4"
            title="SAVE"
          >
            <Image imagePath={AppIcons.saveIcon} altText="Save Icon" />
          </Link>
          <Link
            onClick={(e) => {
              e.preventDefault();
              handleAction("CANCEL", rowData, rowIndex);
            }}
            className="mr-4"
            title="CANCEL"
          >
            <Image imagePath={AppIcons.cancelIcon} altText="Cancel Icon" />
          </Link>
        </>
        :
        <>
          {
            col.defaultAction?.allowEdit && (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleAction("EDIT", rowData, rowIndex);
                }}
                className="mr-4"
                title="EDIT"
              >
                <Image imagePath={AppIcons.editIcon} altText="Edit Icon" />
              </Link>
            )
          }

          {col.defaultAction?.allowDelete && (
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleAction("DELETE", rowData);
              }}
              className="mr-4"
              title="DELETE"
            >
              <Image imagePath={AppIcons.deleteIcon} altText="Delete Icon" />
            </Link>
          )}
           {col.defaultAction?.allowDownload && (
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleAction("DOWNLOAD", rowData);
              }}
              className="mr-4"
              title="DOWNLOAD"
            >
              <Image imagePath={AppIcons.downloadIcon} altText="Download Icon" />
            </Link>
          )}
        </>
      }

    </div>
  );
};
export default RenderGridAction