import React, { useEffect, useRef, useState } from 'react'
import Buttons from '../../components/ui/button/Buttons';
import ReportSearchForm from './component/ReportSearchForm';
import { useGenerateAllActiveIndividualRecordsMutation, useGetAllExportForIndividualDataRecordsMutation, useLazyDownloadSheetByExportForIndividualDataIdQuery } from '../../app/services/Reports/ReportsApi';
import MolGrid from '../../components/Grid/MolGrid';
import { ReportListTableConfig } from './data/ReportListTable';
import SwalAlert from '../../services/swalService/SwalService';
import { saveAs } from "file-saver";

const initState = {
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    orderByColumn: "",
    orderFlag: 0,
    totalCount: 0,
}
const ReportList = () => {
    const reportSearchFormRef = useRef();
    const molGridRef = useRef();
    const [paginationRequest, setPaginationRequest] = useState(initState);
    const [dataSource, setDataSource] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { success, error, warning } = SwalAlert();
    const [reportList, { isSuccess: isSuccess, isLoading: isLoading, data: reportRes },] = useGenerateAllActiveIndividualRecordsMutation();
    const [reportAllList, { isSuccess: isListSuccess, isLoading: isListLoading, data: allReportRes },] = useGetAllExportForIndividualDataRecordsMutation();
    const [getExcel, { isSuccess: isSuccessGetExcel, isLoading: isLoadingGetExcel, data: getExcelData }] = useLazyDownloadSheetByExportForIndividualDataIdQuery();


    useEffect(() => {
        setDataSource([]);
        reportAllList(paginationRequest)
    }, [])

    useEffect(() => {
        if (!isLoadingGetExcel && isSuccessGetExcel && getExcelData) {
            const base64ToArrayBuffer = (base64) => {
                const binaryString = window.atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                return bytes.buffer;
            };

            const arrayBuffer = base64ToArrayBuffer(getExcelData?.fileBytes);
            const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            if (getExcelData?.filePath) {
                saveAs(blob, getExcelData?.filePath);
            } else {
                error("File does not exist.", "");
            }
        }
    }, [isLoadingGetExcel, isSuccessGetExcel, getExcelData]);

    useEffect(() => {
        if (!isListLoading && isListSuccess && allReportRes) {
            setDataSource(allReportRes?.itemList)
            setPaginationRequest({ ...paginationRequest, totalCount: allReportRes?.totalCount })
        }
    }, [isListLoading, isListSuccess, allReportRes]);

    useEffect(() => {
        if (!isLoading && isSuccess && reportRes) {
            if (reportRes.length === 0) {
                return warning("Oops!", "No record found for this period")
            }
            success("Report Generate Successfully.", "")
            reportAllList(paginationRequest)
        }
    }, [isLoading, isSuccess, reportRes]);


    const handleSearchApplicationTask = () => {
        // Ensure applicationTasklistRef.current is not null before using it
        if (!reportSearchFormRef.current) {
            // Handle the case where applicationTasklistRef.current is null
            return;
        }
        const searchFormData = reportSearchFormRef.current.getFormData();

        const request = {
            startdate: searchFormData?.startdate || null,
            enddate: searchFormData?.enddate || null,
        };
        reportList(request);

    };

    const handlePageChange = ({ pageNumber, pageSize }) => {
        reportAllList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }


    const handleDownLoadActionClick = (data) => {
        getExcel(data?.individualReportId);
    };

    const actionHandler = {
        DOWNLOAD: handleDownLoadActionClick,
    };

    return (
        <div className="row align-items-end">
            <div className='col-11'>
                <div className='row gx-5'>
                    <ReportSearchForm ref={reportSearchFormRef} />
                </div>
            </div>
            <div className="col-lg-1 d-flex justify-content-end mb-3">
                <div className="two-button-fixes">
                    <div className="">
                        <Buttons
                            buttonText="Generate"
                            buttonTypeClassName="theme-btn"
                            onClick={handleSearchApplicationTask}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mt-5">
                <div className="common-view-Individual-table">
                    <div className="table-responsive">
                        <MolGrid
                            ref={molGridRef}
                            configuration={ReportListTableConfig}
                            dataSource={dataSource}
                            allowPagination={true}
                            isLoading={isLoading}
                            pagination={paginationRequest}
                            onPageChange={handlePageChange}
                            onActionChange={actionHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportList