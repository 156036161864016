import { FormFieldTypes } from "../../../../../data/formFieldType";

const ProfessionalCertificationDataGeneral = {
    name: "ProfessionalCertificationData",
    initialState: {
        yearsofProfessionalPracticeIT: "",
        yearsOfProfessionalPracticeIS: "",
        professionCodeId: "",
    },
    formFields: [
        {
            id: "yearsofProfessionalPracticeIT",
            lable: "Professional Practice in Information Technology (IT) since",
            Field_Name: "Professional Practice in Information Technology (IT) since",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "yearsofProfessionalPracticeIT",
            fieldSetting: {
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,
                placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
       
        {
            id: "yearsOfProfessionalPracticeIS",
            lable: "Professional Practice in Information Security (IS) since",
            Field_Name: "Professional Practice in Information Security (IS) since",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "yearsOfProfessionalPracticeIS",
            fieldSetting: {
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,
                placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "professionCodeId",
            lable: "Profession Code",
            Field_Name: "Profession Code",
            fieldType: FormFieldTypes.SELECT,
            dataField: "professionCodeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
    ],
};

export default ProfessionalCertificationDataGeneral;



