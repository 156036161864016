import { FormFieldTypes } from "../../../data/formFieldType";

export const CreateAdminAccountFormData = {
    name: "Create Admin Account",
    initialState: {
        name: "",
        lastName:"",
        email:"",
        password:"",
        role:"",
        fileUpload:"",
    },
    formFields: [
        {
            id: "First Name",
            lable: "First Name",
            Field_Name: "name",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "name",
            validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "Last Name",
            lable: "Last Name",
            Field_Name: "lastName",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "lastName",
            validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "Email",
            lable: "Email",
            Field_Name: "email",
            fieldType: FormFieldTypes.LOWERCASEINPUT,
            dataField: "email",
            validation: [{ type: "require" },{ type: "email" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "Password",
            lable: "Password",
            Field_Name: "password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "password",
            validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "Role",
            lable: "Role",
            Field_Name: "role",
            fieldType: FormFieldTypes.SELECT,
            dataField: "role",
            validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "Role",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
       
        {
            id: "",
            lable: "",
            Field_Name: "fileUpload",
            fieldType: FormFieldTypes.FILE,
            dataField: "fileUpload",
            // validation: [{ type: "require" }],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-0 mt-3"
            }
        },
    ],
};

