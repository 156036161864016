/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./MolGrid.scss";
import PropTypes from "prop-types";
import MolGridHeader from "./MolGrid-Header";
import MolGridDataRows from "./Molgrid-data-row";
import MolPagination from "./Pagination/MolPagination";
import DataLoader from "../ui/dataLoader/DataLoader";
import NoRecordFound from "../ui/noRecordFound/NoRecordFound";
import Buttons from "../ui/button/Buttons";

const MolGrid = forwardRef((props, ref) => {
  // Initialize state for currentPage, pageSize, and totalPageCount
  const [currentPage, setCurrentPage] = useState(
    props.pagination?.pageNo || 1
  );
  const [pageSize, setPageSize] = useState(props.pagination?.pageSize || 10);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [sorting, setSorting] = useState([]);
  const tempObjData = {
    id: null,
    name: null,
    valueOrdering: null
  }

  //edit inside grid states
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [newItemAdded, setNewItemAdded] = useState(false)


  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  // Calculate the total page count whenever totalCount or pageSize changes
  useEffect(() => {
    if (props.pagination.totalCount > 0) {
      calculateTotalPage(pageSize);
    }
  }, [props.pagination.totalCount, pageSize]);

  // Calculate total page count and adjust currentPage if necessary
  const calculateTotalPage = (newPageSize) => {
    let newTotalPageCount = Math.ceil(
      props.pagination.totalCount / newPageSize
    );
    if (newTotalPageCount === 0) {
      newTotalPageCount = 1;
    }
    if (currentPage > newTotalPageCount) {
      setCurrentPage(newTotalPageCount);
    }
    setTotalPageCount(newTotalPageCount);
  };

  // Get the current page object
  const getCurrentPageObject = () => {
    return {
      pageNumber: currentPage,
      pageSize: pageSize,
      // Add other pagination-related properties here
    };
  };

  // Get the current page object
  const getDefulatPageObject = () => {
    setCurrentPage(1);
    return {
      pageNumber: 1,
      pageSize: pageSize,
      // Add other pagination-related properties here
    };
  };

  const getSortingString = (data) => {
    return data.map(item => {
      const sortingOrder = item.isAsc ? '' : ' desc';
      return `${item.fieldName}${sortingOrder}`;
    }).join(',');
  };

  const generateSortingString = () => {
    return getSortingString(sorting);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (props.onPageChange) {
      props.onPageChange({
        pageNumber: newPage,
        pageSize: pageSize,
        // Add other pagination-related properties here
      });
    }
  };

  const handleSortingUpdate = (updatedSortingData) => {
    setSorting(updatedSortingData);
    if (props.onSorting) {
      props.onSorting(getSortingString(updatedSortingData));
    }
  };

  // Handle page size change
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    calculateTotalPage(newSize);
    setCurrentPage(1);
    if (props.onPageChange) {
      props.onPageChange({
        pageNumber: 1,
        pageSize: newSize,
        // Add other pagination-related properties here
      });
    }
  };

  // Use useImperativeHandle to make the getCurrentPageObject function accessible via the ref
  useImperativeHandle(ref, () => ({
    getCurrentPageObject,
    getDefulatPageObject,
    generateSortingString
  }));

  const handleNewItemAdd = () => {
    if (props.allowAddGridItem) {
      let tempObj = props.dataSource?.length > 0 ? { ...props.dataSource[props.dataSource.length - 1] } : tempObjData;
      for (let key in tempObj) {
        tempObj[key] = null
      }
      if (!dataSource.some(obj => JSON.stringify(obj) === JSON.stringify(tempObj))) {
        let tempDataSource = [...dataSource]
        tempDataSource.push(tempObj)
        setDataSource(tempDataSource);
        setNewItemAdded(true);
      }
    }
  }

  const handleNewItemCancel = () => {
    let tempDataSource = [...dataSource]
    tempDataSource.pop()
    setDataSource(tempDataSource);
    setNewItemAdded(false)
  }

  const renderDataRow = (prop) => {

    const isRenderRow =
      !!(prop.dataSource );
    return (
      <>
        {prop.isLoading ? (
          <tr>
            <td colSpan={100}>
              <DataLoader />
            </td>
          </tr>
        ) : isRenderRow ? (
          <>{dataSource &&
            <MolGridDataRows
              dataSource={dataSource}
              columns={prop.configuration.columns}
              onActionChange={prop.onActionChange}
              newItemAdded={newItemAdded}
              handleNewItemCancel={handleNewItemCancel}
              isAllowRowClick={props.isAllowRowClick}
            />}
          </>
        ) : (
          <tr>
            <td colSpan={100}>
              <NoRecordFound />
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <>
      <div className="molgrid">
        <div className="table-responsive">
          {/* <p>{JSON.stringify(props)}</p> */}
          {
            props.allowAddGridItem &&
            <Buttons
              buttonText={props.abbButtonText ? props.abbButtonText : "+ Add Item"}
              buttonTypeClassName="outline-btn mb-2 "
              onClick={() => handleNewItemAdd()}
            />
          }

          <table className="border-table-simple">
            <thead>
              <MolGridHeader columns={props.configuration.columns}
                onSortingUpdate={handleSortingUpdate}
                selectedSorting={sorting}
              />
            </thead>
            <tbody>{renderDataRow(props)}</tbody>
          </table>
        </div>
        <div className="">
          {props.allowPagination && (
            <MolPagination
              pageSize={pageSize}
              currentPage={currentPage}
              totalPages={totalPageCount}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
        </div>
      </div>
    </>

  );
});

MolGrid.propTypes = {
  configuration: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  allowPagination: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    pageSize: PropTypes.number,
  }),
};

MolGrid.defaultProps = {
  allowPagination: true,
  pagination: {
    currentPage: 1,
    totalCount: 1,
    pageSize: 10,
  },
};

export default MolGrid;
