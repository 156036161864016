export const editEmailCampaignInitials = (data, apiData) => {
    let formData = { ...data }
    const memebrship = [
        { id: "avip", membershipTypeId: 1 },
        { id: "path1", membershipTypeId: 2 },
        { id: "path2", membershipTypeId: 3 },
        { id: "path3", membershipTypeId: 4 },
        { id: "associateMember", membershipTypeId: 5 },
        { id: "cppStartUp", membershipTypeId: 6 },
        { id: "cppsme", membershipTypeId: 7 },
        { id: "cpp", membershipTypeId: 8 },
        { id: "cpp100", membershipTypeId: 9 },
        { id: "academic", membershipTypeId: 10 },
        { id: "affiliate", membershipTypeId: 11 },
        { id: "fellow", membershipTypeId: 12 },
    ]

    let filters = apiData.recipients?.split(',')?.map(Number)
    let selectedRecipientsFilter = {}
    memebrship?.forEach(({ id, membershipTypeId }) => {
        selectedRecipientsFilter[id] = filters?.includes(membershipTypeId);
    })

    const safeSplit = (value, from = ',') => {
        return (typeof value === 'string' && value.trim() !== '')
            ? value.split(from).map(item => Number(item.trim()))
            : [];
    };

    const safeJoin = (value) => (value && Array.isArray(value) ? value.join(', ') : '');

    formData.initialState.message = apiData.message;
    formData.initialState.subject = apiData.subject;
    formData.initialState.attachment = apiData.attachment;
    formData.initialState.send = apiData.sendNow === false ? "SendLater" : "SendNow";
    formData.initialState.sendDate = apiData.sendDate;
    formData.initialState.sendTime = apiData.sendTime;
    formData.initialState.campaignName = apiData.campaignName;
    formData.initialState.recipientsByMembershipType = (Object.keys(selectedRecipientsFilter).length === 11 && Object.values(selectedRecipientsFilter).every(value => value !== null && value !== undefined && value !== false)) ? true : false;
    formData.initialState.aispOrdinaryMember = selectedRecipientsFilter?.path1 && selectedRecipientsFilter?.path2 && selectedRecipientsFilter?.path3;
    formData.initialState.path1 = selectedRecipientsFilter?.path1;
    formData.initialState.path2 = selectedRecipientsFilter?.path2;
    formData.initialState.path3 = selectedRecipientsFilter?.path3;
    formData.initialState.associateMember = selectedRecipientsFilter?.associateMember;
    formData.initialState.affiliate = selectedRecipientsFilter?.affiliate;
    formData.initialState.avip = selectedRecipientsFilter?.avip;
    formData.initialState.fellow = selectedRecipientsFilter?.fellow;
    formData.initialState.cppStartUp = selectedRecipientsFilter?.cppStartUp;
    formData.initialState.cppsme = selectedRecipientsFilter?.cppsme;
    formData.initialState.cpp = selectedRecipientsFilter?.cpp;
    formData.initialState.cpp100 = selectedRecipientsFilter?.cpp100;
    formData.initialState.academic = selectedRecipientsFilter?.academic;
    formData.initialState.recipientsByGender = formData.formFields.find(field => field.id === "recipientsByGenderSelect").fieldSetting.options.length === safeSplit(apiData.recipientsByGender).length;
    formData.initialState.recipientsByJobTitle = formData.formFields.find(field => field.id === "recipientsByJobTitleSelect").fieldSetting.options.length === safeSplit(apiData.recipientsByJobTitle).length;
    formData.initialState.recipientsByGenderSelect = safeSplit(apiData?.recipientsByGender);
    formData.initialState.recipientsByJobTitleSelect = safeSplit(apiData?.recipientsByJobTitle);
    formData.initialState.recipientsByNationality = formData.formFields.find(field => field.id === "recipientsByNationalitySelect").fieldSetting.options.length === safeSplit(apiData.recipientsByNationality).length;
    formData.initialState.recipientsByIndustry = formData.formFields.find(field => field.id === "recipientsByIndustrySelect").fieldSetting.options.length === safeSplit(apiData.recipientsByIndustry).length;
    formData.initialState.recipientsByNationalitySelect = safeSplit(apiData?.recipientsByNationality);
    formData.initialState.recipientsByIndustrySelect = safeSplit(apiData?.recipientsByIndustry);
    formData.initialState.recipientsByTagging = formData.formFields.find(field => field.id === "recipientsByTaggingSelect").fieldSetting.options.length === safeSplit(apiData.recipientsByTagging).length;
    formData.initialState.recipientsByTaggingSelect = safeSplit(apiData?.recipientsByTagging);
    formData.initialState.recipientsByStatus = formData.formFields.find(field => field.id === "recipientsByStatusSelect").fieldSetting.options.length === safeSplit(apiData.recipientsByStatus).length;
    formData.initialState.recipientsByStatusSelect = safeSplit(apiData?.recipientsByStatus);
    formData.initialState.recipientsByRace = formData.formFields.find(field => field.id === "recipientsByRaceSelect").fieldSetting.options.length === safeSplit(apiData.recipientsByRace).length;
    formData.initialState.recipientsByRaceSelect = safeSplit(apiData?.recipientsByRace);
    formData.initialState.recipientsByResidenceStatus = formData.formFields.find(field => field.id === "recipientsByResidenceStatusSelect").fieldSetting.options.length === safeSplit(apiData.recipientsByResidenceStatus).length;
    formData.initialState.recipientsByCampaignTypeSelect = Number(apiData.recipientsByCampaignType);

    formData.initialState.includeEmail = apiData.includeEmail;
    formData.initialState.excludeEmail = apiData.excludeEmail;

    return formData;
}

export const setRecipientsFilter = (apiData, dataForm) => {

    let formData = { ...dataForm };
    // Array of IDs to update
    const idsToUpdate = [
        { id: "avip", membershipTypeId: 1 },
        { id: "path1", membershipTypeId: 2 },
        { id: "path2", membershipTypeId: 3 },
        { id: "path3", membershipTypeId: 4 },
        { id: "associateMember", membershipTypeId: 5 },
        { id: "cppStartUp", membershipTypeId: 6 },
        { id: "cppsme", membershipTypeId: 7 },
        { id: "cpp", membershipTypeId: 8 },
        { id: "cpp100", membershipTypeId: 9 },
        { id: "academic", membershipTypeId: 10 },
        { id: "affiliate", membershipTypeId: 11 },
        { id: "fellow", membershipTypeId: 12 },
        { id: "aispOrdinaryMember", membershipTypeId: null }
    ];
    // Filter form fields to be updated
    let fields = formData.formFields.filter(obj =>
        idsToUpdate.map(idObj => idObj.id).includes(obj.id)
    );
    // Update the labels for each form field
    fields.forEach(field => {
        let idToUpdate = idsToUpdate.find(idObj => idObj.id === field.id);
        // Handle the aispOrdinaryMember separately
        if (field.id === "aispOrdinaryMember") {
            let path1Data = apiData.find(obj => obj.membershipTypeId === 2);
            let path2Data = apiData.find(obj => obj.membershipTypeId === 3);
            let path3Data = apiData.find(obj => obj.membershipTypeId === 4);
            let totalMemberCount = (path1Data?.memberCount || 0) + (path2Data?.memberCount || 0) + (path3Data?.memberCount || 0);
            field.lable = `AISP Ordinary Member (${totalMemberCount})`;
        } else {
            let data = apiData.find(obj => obj.membershipTypeId === idToUpdate.membershipTypeId);
            if (data) {
                field.lable = `${data.membershipTypeName} (${data.memberCount})`;
            }
        }
    });
    // Map the updated fields back to the formFields
    formData.formFields = formData.formFields.map(obj => {
        let updatedField = fields.find(field => field.id === obj.id);
        return updatedField ? updatedField : obj;
    });

    return formData;

}

export const removeValidationOnDraft = (status, formData) => {
    const subjectStatusIndex = formData.formFields.findIndex(field => field.id === "subject");
    const messageStatusIndex = formData.formFields.findIndex(field => field.id === "message");
    const campaignTypeStatusIndex = formData.formFields.findIndex(field => field.id === "recipientsByCampaignTypeSelect");
    const newValidationValue = status === "Draft" ? [] : [{ type: "require" }];
    const updatedFormData = { ...formData };
    if (subjectStatusIndex !== -1) {
        updatedFormData.formFields[subjectStatusIndex].validation = newValidationValue;
    }
    if (messageStatusIndex !== -1) {
        updatedFormData.formFields[messageStatusIndex].validation = newValidationValue;
    }
    if (campaignTypeStatusIndex !== -1) {
        updatedFormData.formFields[campaignTypeStatusIndex].validation = newValidationValue;
    }
    return { ...updatedFormData };
}