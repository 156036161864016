import { NavigateButton } from "../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../data/formFieldType";


const forgetPasswordFormData = {
    name: "Login From",
    initialState: {
        emailAddress: "",
        password: "",
        rememberMe: "",
        navigatePath: "",
    },
    formFields: [
        {
            id: "emailAddress",
            lable: "Enter the email to retrieve your account",
            Field_Name: "email address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "emailAddress",
            fieldSetting: {
                placeholder: "Email Address",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
    ],
};
export default forgetPasswordFormData;

export const authanticationFormData = {
    name: "Authantication From",
    initialState: {
        otp: "",
    },
    formFields: [
        {
            id: "otp",
            lable: "",
            Field_Name: "OTP",
            fieldType: FormFieldTypes.INPUT,
            dataField: "otp",
            fieldSetting: {
                placeholder: "Enter Your OTP",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
    ],
};