import React, { useRef, useState } from 'react'
import Buttons from '../../components/ui/button/Buttons';
import AcademicMemberTable from './component/AcademicMemberTable';
import AcademicMemberSearchForm from './component/AcademicMemberSearchForm';
import SearchBar from '../component/SearchBar';

const initState = {
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    totalCount: 0,
    StatusId: null,
    searchText: null,
    orderByColumn: "",
    orderFlag: 0,
    dateFrom: null,
    statusId: null,
    MemberShipTypeId: null,
    dateTo: null,
}
const AcademicTaskList = () => {
    const academicFromRef = useRef();
    const [paginationRequest, setPaginationRequest] = useState(initState);

    const handleSearchApplicationTask = () => {
        // Ensure academicFromRef.current is not null before using it
        if (!academicFromRef.current) {
            // Handle the case where academicFromRef.current is null
            return;
        }
        const searchFormData = academicFromRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            searchText: searchFormData.name,
            dateFrom: searchFormData.dateFrom,
            statusId: searchFormData?.statusId?.value || searchFormData?.statusId,
            MemberShipTypeId: searchFormData?.membershipTypeId?.value || searchFormData?.membershipTypeId,
            dateTo: searchFormData.dateTo,
        };
        setPaginationRequest(updatedPaginationRequest);
    };

    const handleClearClick = () => {
        setPaginationRequest(initState);
    }

    return (
        <>
            <div className="row align-items-end">
                <div className='col-lg-11'>
                    <div className='row gx-5'>
                        <AcademicMemberSearchForm ref={academicFromRef} />
                    </div>
                </div>
                <div className="col-lg-1 d-flex justify-content-end mb-3">
                    <div className="two-button-fixes">
                        <div className="d-none">
                            <Buttons
                                buttonText="Clear"
                                buttonTypeClassName="btn btn-secondary"
                                onClick={handleClearClick}
                            />
                        </div>
                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="theme-btn"
                                onClick={handleSearchApplicationTask}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-5">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <AcademicMemberTable paginationReq={paginationRequest} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AcademicTaskList