import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";

const ReportApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'ReportApi',
    endpoints: (builder) => ({
        // GenerateAllActiveIndividualRecords: builder.query({
        //     query: () => ({
        //         url: '/BatchJob/GenerateAllActiveIndividualRecords',
        //         method: 'GET',
        //     }),
        //     transformResponse: transformSucessResponse,
        //     transformErrorResponse: transformErrorResponse,
        // }),
        GenerateAllActiveIndividualRecords: builder.mutation({
            query: (details) => ({
                url: '/BatchJob/GenerateAllActiveIndividualRecords',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetAllExportForIndividualDataRecords: builder.mutation({
            query: (details) => ({
                url: '/BatchJob/GetAllExportForIndividualDataRecords',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        DownloadSheetByExportForIndividualDataId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/BatchJob/DownloadSheetByExportForIndividualDataId?id=${id}`),
                method: 'GET',
                //responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    // useLazyGenerateAllActiveIndividualRecordsQuery,
    useGenerateAllActiveIndividualRecordsMutation,
    useGetAllExportForIndividualDataRecordsMutation,
    useLazyDownloadSheetByExportForIndividualDataIdQuery
} = ReportApi;

export default ReportApi;