import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";
import CreateEmailTemplateForm from "../../createEmailTemplate/CreateEmailTemplateForm";


export const emailTemplateManagementTableConfig = {
    columns: [
        {
            name: "Email Name",
            fieldName: "emailName",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Email Subject",
            fieldName: "subject",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Email Project",
            fieldName: "projectName",
            allowShort: false,
            colType: GridColumnType.Text,

        },
        {
            name: "Email Where Used",
            fieldName: "whereUsed",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }
        },
    ],
};





const EmailTemplateManagementFormData = {
    name: "Search Filter Form",
    initialState: {
        ProjectTypeId: null,
    },
    formFields: [
        {
            id: "ProjectTypeId",
            lable: "Project",
            Field_Name: "ProjectTypeId",
            fieldType: FormFieldTypes.SELECT,
            dataField: "ProjectTypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-5 mb-4"
            }
        },
    ],
};

export default EmailTemplateManagementFormData;



