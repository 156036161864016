import React, { useEffect, useState } from "react";
import "./pagination.scss";
import Buttons from "../../ui/button/Buttons";

const MolPagination = ({
  pageSize,
  currentPage,
  totalPages,
  onPageChange,
  onPageSizeChange,
}) => {

  const [inputPage, setInputPage] = useState(currentPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      // setInputPage(prev => (parseInt(prev) - 1).toString())
    }
  };

  const handlePageInputChange = (event) => {
    const input = event.target.value;
    setInputPage(input);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     handleGoToPage();
  //   }
  // };

  const onChangeHandler = (event) => {
    const re = /^[0-9\b]+$/;
    if (inputPage !== "" || re.test(inputPage)) {
      if (event.key === "Enter") {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
          onPageChange(pageNumber);
        }
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      // setInputPage(prev => (parseInt(prev) + 1).toString())
    }
  };

  // const handlePageSizeChange = (event) => {
  //   const newSize = parseInt(event.target.value, 10);
  //   onPageSizeChange(newSize);
  // };

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];

  //   // Calculate the start and end page numbers to display
  //   let startPage = Math.max(currentPage - 2, 1);
  //   let endPage = Math.min(startPage + 4, totalPages);

  //   // Adjust the startPage if there are fewer than 5 pages to display
  //   if (endPage - startPage < 4) {
  //     startPage = Math.max(endPage - 4, 1);
  //   }

  //   for (let i = startPage; i <= endPage; i++) {
  //     pageNumbers.push(
  //       <button
  //         key={i}
  //         className={i === currentPage ? "active-button" : ""}
  //         onClick={() => {
  //           onPageChange(i)
  //           setInputPage(i)
  //         }
  //         }
  //       >
  //         {i}
  //       </button>
  //     );
  //   }

  //   return pageNumbers;
  // };

  // const generatePageSizeOptions = () => {
  //   const options = [];

  //   // Add static page sizes
  //   const staticPageSizes = [10, 20, 50, 100];

  //   // Ensure the dynamicPageSize is not in the staticPageSizes array
  //   const dynamicPageSize = pageSize; // Replace with the dynamic page size from props

  //   // Check if dynamicPageSize is already in staticPageSizes
  //   const isDynamicPageSizeInStatic = staticPageSizes.includes(dynamicPageSize);

  //   // Combine static and dynamic page sizes (excluding dynamicPageSize if it's in staticPageSizes)
  //   const allPageSizes = isDynamicPageSizeInStatic
  //     ? [...staticPageSizes]
  //     : [...staticPageSizes, dynamicPageSize];

  //   // Sort allPageSizes in ascending order
  //   allPageSizes.sort((a, b) => a - b);

  //   for (const size of allPageSizes) {
  //     options.push(
  //       <option key={"sel_" + size} value={size}>
  //         {size}
  //       </option>
  //     );
  //   }
  //   return options;
  // };

  useEffect(() => {
    setInputPage(currentPage)
  }, [currentPage])

  return (
    <>
      <div className="table-pagination">
        <Buttons
          buttonText="Previous"
          buttonTypeClassName="outline-btn mx-4"
          onClick={handlePrevious}
        />
        <p>
          {/* Page {currentPage} of {totalPages} */}
          Page
          <input
            className="new-input-page ml-2" //old class -> new-input-page
            value={inputPage}
            onChange={handlePageInputChange}
            onBlur={handleGoToPage}
            //onKeyPress={handleKeyPress}            
            onKeyPress={(e) => onChangeHandler(e)}
            min="1"
            max={totalPages}
          />
          <span className="mx-1">of</span>
          <span className="mx-1">{totalPages}</span>
        </p>
        {/* {renderPageNumbers()} */}

        <Buttons
          buttonText="Next"
          buttonTypeClassName="outline-btn mx-4"
          onClick={handleNext}
        />
      </div>
    </>
  );
};
export default MolPagination;