import { FormFieldTypes } from "../../../../../data/formFieldType";

const OrganisationDetailsData = {
    name: "OrganisationDetailsData",
    initialState: {
        nameOfOrganisationAsInAcra: "",
        brnUenNo: "",
        organisationAddressLine1: "",
        organisationAddressLine2: "",
        organisationAddressLine3: "",
        postalCode: "",
        corporateIndustryCodeId: "",
        acraBizFile: "",

    },
    formFields: [
        {
            id: "nameOfOrganisationAsInAcra",
            lable: "Name of Organisation as in ACRA",
            Field_Name: "Name of Organisation as in ACRA",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameOfOrganisationAsInAcra",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "brnUenNo",
            lable: "BRN/UEN No.",
            Field_Name: "BRN/UEN No.",
            fieldType: FormFieldTypes.INPUT,
            dataField: "brnUenNo",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "organisationAddressLine1",
            lable: "Organisation Address Line 1",
            Field_Name: "Organisation Address Line 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "organisationAddressLine1",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "organisationAddressLine2",
            lable: "Organisation Address Line 2",
            Field_Name: "Organisation Address Line 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "organisationAddressLine2",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "organisationAddressLine3",
            lable: "Organisation Address Line 3",
            Field_Name: "Organisation Address Line 3",
            fieldType: FormFieldTypes.INPUT,
            dataField: "organisationAddressLine3",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "postalCode",
            lable: "Postal Code",
            Field_Name: "Postal Code",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "postalCode",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "corporateIndustryCodeId",
            lable: "Industry Code",
            Field_Name: "Industry Code",
            fieldType: FormFieldTypes.SELECT,
            dataField: "corporateIndustryCodeId",
            fieldSetting: {
                // placeholder: "",
                // allowSpace: true,
                 isDisable: true,
                 options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
    ],
};
export default OrganisationDetailsData;