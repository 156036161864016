import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../../utils/API/responseMiddleware";


const AcademicTask_ListApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'AcademicTask_ListApi',
    endpoints: (builder) => ({
        getAcadmicTaskList: builder.mutation({
            query: (details) => ({
                url: '/AcademicMember/GetAllAcademicMembershipTaskList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAcademicGeneratePdfById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/AcademicMember/GetAcademicGeneratePdfById?id=${id}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getCorporateGeneratePdfById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CorporateMember/GetCorporateGeneratePdfById?id=${id}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        generatePDFForIndividualInformation: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GeneratePDFForIndividualInformation?id=${id}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetAcadmicTaskListMutation,
    useLazyGetAcademicGeneratePdfByIdQuery,
    useLazyGetCorporateGeneratePdfByIdQuery,
    useLazyGeneratePDFForIndividualInformationQuery

} = AcademicTask_ListApi;

export default AcademicTask_ListApi;