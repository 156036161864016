import React, { Suspense, forwardRef, lazy, useEffect, useImperativeHandle, useRef, useState } from 'react'
import FormCreator from '../../components/Forms/FormCreator'
import { academicFormData } from './data/AcademicMemberTable.Data';
import {
    useAddEditMembershipPricingByAdminMutation,
    useLazyGetAllAcedemicInformationDetailsByIdQuery,
    useLazyGetAllDropdownsForAcademicMembersQuery,
    useRejectAcademicMemberApplicationMutation,
    useSentPaymentLinkAcademicMemberMutation,
    useUpdateAcademicMemberDetailsMutation
} from '../../app/services/Academic/AcademicTaskListApi';
import { setAllDropDownField } from './utils/setAllDropDownField';
import Buttons from '../../components/ui/button/Buttons';
import { decryptAESID } from '../../utils/Encryption';
import { useParams } from 'react-router-dom';
import {
    setFieldParameter,
    setPricingDetailsField,
    forUserCreatedByAdmin
} from './utils/setEditFieldsUtils';
import SwalAlert from '../../services/swalService/SwalService';
import CenterModel from '../../components/ui/centerModel/CenterModel';
import {
    useLazyGetPDFQuery,
    useUploadPDFMutation
} from '../../app/services/Common/CommonApi';
import ModuleLoader from '../../components/ui/moduleLoader/ModuleLoader';
import { saveAs } from 'file-saver';
import {
    useGetMembershipPricingForMembershipTypeIdMutation,
    useSaveIndividualMemberClarificationDetailsMutation
} from '../../app/services/Individual/IndividualTaskListApi';
import CKEditorComponent from '../../components/ui/inputs/ckEditor/CkEditor';
// const TinyEditor = lazy(() => import('../../components/ui/inputs/TinyEditor/TinyEditor'));

const AcademicForm = forwardRef((props, ref) => {
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false).toString() : 0;
    const { confirm, error, success } = SwalAlert();
    const formRef = useRef();
    const [formData, SetFormData] = useState(academicFormData);
    const [isModalOpenForCF, setIsModalOpenForCF] = useState(false);
    const [rejectComments, setRejectComments] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [CFComments, setCFComments] = useState("");
    const [isSendPaymentLink, setIsSendPaymentLink] = useState(false);
    const [isUpdateTaskDetailsLink, setIsUpdateTaskDetailsLink] = useState("");
    const [membershipPricingDetails, setMembershipPricingDetails] = useState({
        membershipTypeId: 0,
        membershipTenureId: 0
    });
    const [acraFileName, setACRAFileName] = useState(null);
    const pricingInitialStateRef = useRef({});

    const [getAllDropDowns, { data: dropdownResponse, isSuccess: isSuccess, isLoading: isLoading, }] = useLazyGetAllDropdownsForAcademicMembersQuery();
    const [getMemberDetailsByID, { data: memberDetails, isSuccess: isSuccessGetByID, isLoading: isLoadingGetById, }] = useLazyGetAllAcedemicInformationDetailsByIdQuery();
    const [updateMemberData, { data: updatedMemberDataResponse, isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, }] = useUpdateAcademicMemberDetailsMutation();
    const [rejectApplication, { data: rejectApplicationResponse, isSuccess: IsRejected, isLoading: IsRejectedLoading, }] = useRejectAcademicMemberApplicationMutation();
    const [uploadPDF, { data: fileUploadData, isSuccess: isUploadSuccess, isLoading: isUploadLoading, }] = useUploadPDFMutation();
    const [sendPaymentLink, { data: paymentResponse, isSuccess: isPaymentSuccess, isLoading: IsPaymentLoading, }] = useSentPaymentLinkAcademicMemberMutation();
    const [getPricing, { data: pricingResponse, isSuccess: isSuccessPrice, isLoading: isLoadingPrice, }] = useGetMembershipPricingForMembershipTypeIdMutation();
    const [sendClearfication, { data: CFResponse, isSuccess: isCFSuccess, isLoading: IsCFLoading, }] = useSaveIndividualMemberClarificationDetailsMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();
    const [addEditMembersPricing, { data: MembershipPricingResponse, isSuccess: isPricingSuccess, isLoading: IsPricingLoading, }] = useAddEditMembershipPricingByAdminMutation();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const formResponse = formRef.current.getFormData();
                return formResponse;
            }
            return null;
        }
    }));

    useEffect(() => {
        if (!isLoading && isSuccess && dropdownResponse) {
            setAllDropDownField(dropdownResponse)
        }
    }, [isLoading, isSuccess, dropdownResponse])

    useEffect(() => {
        if (!IsCFLoading && isCFSuccess && CFResponse) {
            success("", "Clarification Sent Successfully")
            setIsModalOpenForCF(false)
            setCFComments('');
        }
    }, [IsCFLoading, isCFSuccess, CFResponse])

    useEffect(() => {
        if (!isLoadingGetById && isSuccessGetByID && memberDetails) {
            let updateForm = forUserCreatedByAdmin(memberDetails.createdName, formData);
            SetFormData(updateForm);
            setFieldParameter(memberDetails)
            setACRAFileName(memberDetails?.acraBizFileName)
            setMembershipPricingDetails({ ...membershipPricingDetails, membershipTypeId: memberDetails?.membershipTypeId, membershipTenureId: memberDetails?.membershipTenureId })
            //setPricingField(memberDetails.membershipTypeId, memberDetails.membershipTenureId);
        }
    }, [isLoadingGetById, isSuccessGetByID, memberDetails])

    useEffect(() => {
        if (!isLoadingUpdate && isSuccessUpdate && updatedMemberDataResponse && isUpdateTaskDetailsLink === "isUpdated") {
            if (isUpdateTaskDetailsLink === "isUpdated" && isSendPaymentLink) {
                setIsUpdateTaskDetailsLink("");
            }
            else {
                success("", "Application Updated Successfully ");
            }
        }
    }, [isLoadingUpdate, isSuccessUpdate, updatedMemberDataResponse])

    useEffect(() => {
        if (!IsRejectedLoading && IsRejected && rejectApplicationResponse) {
            success("", "Application Reject Successfully ")
            setIsModalOpen(false)
        }
    }, [IsRejectedLoading, IsRejected, rejectApplicationResponse])

    useEffect(() => {
        if (!IsPaymentLoading && isPaymentSuccess && paymentResponse) {
            success("", "Payment Link Sent On Registered Email Successfully ")
            setIsModalOpen(false);
            setIsSendPaymentLink(false);
        }
    }, [IsPaymentLoading, isPaymentSuccess, paymentResponse])

    useEffect(() => {
        if (membershipPricingDetails.membershipTypeId > 0 || membershipPricingDetails.membershipTenureId > 0) {
            setPricingField(membershipPricingDetails.membershipTypeId, membershipPricingDetails.membershipTenureId)
        }
    }, [membershipPricingDetails.membershipTypeId, membershipPricingDetails.membershipTenureId])

    useEffect(() => {
        if (!isLoadingPrice && isSuccessPrice && pricingResponse) {
            const response = setPricingDetailsField(pricingResponse, memberDetails.membershipTenureId);
            response.initialState.membershipTenureId = membershipPricingDetails.membershipTenureId
            response.initialState.membershipTypeId = membershipPricingDetails.membershipTypeId
            SetFormData(previousState => ({
                ...previousState,
                initialState: response.initialState
            }));
            pricingInitialStateRef.current = {
                ...response.initialState
            };
        }
    }, [isLoadingPrice, isSuccessPrice, pricingResponse]);

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const handleReject = () => {
        if (rejectComments === "" || rejectComments === undefined || rejectComments === null) {
            return error("The comments field for rejecting the application is mandatory and must be filled out.", "error")
        }
        const request = {
            AcademicMembersId: editID,
            IsRejected: true,
            SendRejectionReason: rejectComments,
        }
        confirm("Reject Application", "Are you sure you want to Reject Application?", "Yes", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    rejectApplication(request);
                }
            });
    }

    const setPricingField = (memberType, memberTenure) => {
        let request = {
            MembershipTypeId: memberType,
            MembershipTenureId: memberTenure,
            memberId: editID || 0,
            isCorporate: false,
            isAcademic: true
        }
        getPricing(request);
    }
    const handleSentPaymentLink = () => {
        let formResponse = formRef.current.getFormData();
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        setIsSendPaymentLink(true);
        handleUpdate(formResponse)
        let request = {
            AcademicMembersId: Number(editID),
            IsSentPaymentLink: true,
            PaymentTermId: 1,
            IsStartMemberShip: formResponse?.isStartMembershipOn || null,
            StartMemberShipOn: formResponse?.startMembershipDate || null,
            MembershipTypeId: formResponse.membershipTypeId,
            MembershipTenureId: formResponse.membershipTenureId,
            EntranceFee: formResponse?.entrenceFee,
            AnnualFee: formResponse?.annualFee,
        }
        sendPaymentLink(request);
    }

    const handleUpdate = (formData) => {
        let formResponse = formRef.current.getFormData() || formData;
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        setIsUpdateTaskDetailsLink("isUpdated");
        const request = {
            AcademicMembersId: editID || 0,
            InstitutionName: formResponse?.instituteName,
            UENNo: formResponse?.uenNo,
            AddressLine1: formResponse?.institutionAddressLine1,
            AddressLine2: formResponse?.institutionAddressLine2,
            AddressLine3: formResponse?.institutionAddressLine3,
            PostalCode: formResponse?.postalCode,
            ACRABizFileName: fileUploadData?.fileName || formResponse?.aCRABizFile,
            MembershipTypeId: formResponse?.membershipTypeId?.value || formResponse?.membershipTypeId,
            IsStartMemberShip: formResponse?.isStartMembershipOn || false,
            StartMemberShipOn: formResponse?.startMembershipDate || null,
            PaymentTermsId: formResponse?.paymentTerms?.value || formResponse?.paymentTerms,
            MembershipTenureId: formResponse?.membershipTenureId?.value || formResponse?.membershipTenureId,
            ComplimentaryIndividualMembership: formResponse?.complimentary || null,
            Commentstomember: formResponse?.comment || null,
            InternalNotes: formResponse?.internalNote || null,
            EntranceFee: formResponse?.entrenceFee,
            AnnualFee: formResponse?.annualFee,

        }
        updateMemberData(request);
    }
    // Handle change method for upload ACRA Biz file
    const handleFormFieldChange = (fieldName, fieldValue) => {
        debugger;
        switch (fieldName) {
            case "acraFile":
                if (fieldValue) {
                    return handleOnChangeFileUpload(fieldName, fieldValue);
                }
                break;
            // case "annualFee":
            //     return handleMemberShipPricing(fieldValue);
            // case "entrenceFee":
            //     handleMemberShipPricing(fieldValue);
            //     setMembershipPricingDetails(prevDetails => ({
            //         ...prevDetails,
            //         entrenceFee: fieldValue,
            //     }));
            //     break;
            default:
                // Optionally handle other fields or do nothing
                break;
        }
    };

    // const handleMemberShipPricing = (annualFee) => {
    //     let formResponse = formRef.current.getFormData();
    //     if (!formResponse) {
    //         return error("Please fill all mandatory fields.", "")
    //     }
    //     const request = {
    //         MemberId: Number(editID),
    //         EntranceFee: membershipPricingDetails?.entrenceFee,
    //         AnnualFee: annualFee,
    //         MembershipTypeId: formResponse?.membershipTypeId,
    //         MembershipTenureId: formResponse?.membershipTenureId,
    //         IsCorporate: false,
    //         IsAcademic: true
    //     }
    //     debugger;
    //     addEditMembersPricing(request);
    // }

    // Handle change on file upload method
    const handleOnChangeFileUpload = (dataField, data) => {
        let pathName = dataField === "aCRABizFile" && "ACRA_FILE";
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", pathName);
        uploadPDF(formData);
    }

    useEffect(() => {
        getAllDropDowns()
        if (editID) {
            getMemberDetailsByID(editID);
        }
    }, [])
    const handleClear = () => {
        const updatedForm = { ...formData };
        updatedForm.initialState.aCRABizFile = null;
        SetFormData(updatedForm);
    }

    const handleDownload = async () => {
        let request = {
            type: "ACRA_FILE",
            fileName: fileUploadData?.fileName || acraFileName,
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    const handleClearfication = () => {
        if (CFComments === "" || CFComments === undefined || CFComments === null) {
            return error("The comments field for clarification the application is mandatory and must be filled out.", "error")
        }
        let request = {
            IndividualMembersId: editID,
            ClarificationDetails: CFComments,
            IsCorporate: false,
            IsAcademic: true
        }
        confirm("Clarification", "Are you sure you want to send the clarification?", "Yes", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    sendClearfication(request)
                }
            });
    }

    const handleDropdownChange = (data, dataField) => {
        const fields = ["membershipTenureId", "membershipTypeId"];
        if (fields.includes(dataField)) {
            setMembershipPricingDetails({
                ...membershipPricingDetails,
                [dataField]: data?.value || 0
            });
        }
    };
    const openCFModal = () => {
        setIsModalOpenForCF(!isModalOpenForCF);
    }

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        DDL_CHANGED: handleDropdownChange
    }

    return (
        <React.Fragment>
            <div className='row gx-5  bg-light tasklist-form'>
                {(isSuccess && isSuccessGetByID)
                    ? <FormCreator
                        ref={formRef} {...formData}
                        onActionChange={formActionHandler}
                        initialState={pricingInitialStateRef.current}
                        onFormFieldValueChange={handleFormFieldChange}
                    />
                    : <ModuleLoader />
                }
            </div>
            <div className='row mt-4'>
                <div className='col-lg-12 d-flex justify-content-between'>
                    <Buttons
                        buttonText="Reject Application"
                        buttonTypeClassName="red-btn"
                        onClick={openModal}
                    />
                    <Buttons
                        buttonText="Send Clarification"
                        buttonTypeClassName="outline-btn"
                        onClick={openCFModal}
                    />
                    <Buttons
                        buttonText="Update Application"
                        buttonTypeClassName="outline-btn"
                        onClick={handleUpdate}
                    />
                    <Buttons
                        buttonText="Send Payment Link"
                        isLoading={IsPaymentLoading}
                        buttonTypeClassName="theme-btn"
                        onClick={handleSentPaymentLink}
                    />
                </div>
            </div>
            {isModalOpen &&
                <CenterModel
                    modelTitle={'Reject Application'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        {/* <TinyEditor
                            onTinyMceEditorChange={(e) => setRejectComments(e)}
                        /> */}
                        <CKEditorComponent
                            onCKEditorChange={(e) => setRejectComments(e)}
                        />
                        <Buttons
                            buttonText="Send Email"
                            isLoading={IsRejectedLoading}
                            buttonTypeClassName="outline-btn mt-4"
                            onClick={handleReject}
                        />
                    </Suspense>
                </CenterModel>
            }
            {isModalOpenForCF &&
                <CenterModel
                    modelTitle={'Send Clarification'}
                    show={"test"}
                    handleClose={openCFModal}
                    onUpload={openCFModal}
                >
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        {/* <TinyEditor
                            onTinyMceEditorChange={(e) => setCFComments(e)}
                        /> */}
                        <CKEditorComponent
                            onCKEditorChange={(e) => setCFComments(e)}
                        />
                        <Buttons
                            buttonText="Send Email"
                            isLoading={IsCFLoading}
                            buttonTypeClassName="outline-btn mt-4"
                            onClick={handleClearfication}
                        />
                    </Suspense>
                </CenterModel>
            }
        </React.Fragment >
    )
})

export default AcademicForm