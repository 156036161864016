import { FormFieldTypes } from "../../../data/formFieldType"
import { GridColumnType } from "../../../data/gridColumnType";

export const auditLogTableConfig = {
    columns: [
        {
            name: "Date",
            fieldName: "createdAt",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Name",
            fieldName: "updateByUser",
            allowShort: false,
        },
        {
            name: "Decision",
            fieldName: "decision",
            allowShort: false,
        },
        {
            name: "Internal Notes",
            fieldName: "internalNotes",
            allowShort: false,
        }
    ],
};
export const auditLogData = {
    name: 'Professional',
    initialState: {
        taskId: "",
        applicantName: "",
        auditLogInfoTable: [],
        internalNotes:"",
        PIC1Name: "",
        professionalInformationFile: '',
    },
    formFields: [
        {
            id: "auditLogInfoTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "auditLogInfoTable",
            fieldSetting: {
                header: auditLogTableConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-left ",
            },
        },
        {
            id: "internalNotes",
            lable: "Internal Notes",
            Field_Name: "Internal Notes",
            fieldType: FormFieldTypes.INPUT,
            dataField: "internalNotes",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-12 mb-3 ",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}