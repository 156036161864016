import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { corporateMemberTasklistTableConfig } from '../data/CorporateMemberTable.Data';
import MolGrid from '../../../components/Grid/MolGrid';
import { useGetCorporateTaskListMutation } from '../../../app/services/Corporate/CorporateTaskListApi';
import CenterModel from '../../../components/ui/centerModel/CenterModel';
import { decryptAES } from '../../../services/CryptoService';
import { encryptAESID } from '../../../utils/Encryption';
import { useLazyDeleteMemberQuery } from '../../../app/services/Common/CommonApi';
import ToastService from '../../../services/toastService/ToastService';
import SwalAlert from '../../../services/swalService/SwalService';

const CorporateMemberTable = ({ paginationReq }) => {
    const navigate = useNavigate();
    const molGridRef = useRef();
    const { confirmDelete } = SwalAlert();
    const [dataSource, setDataSource] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        SearchText: "",
        gmapNumber: "",
        submissionStartDate: "",
        submissionEndDate: "",
        totalCount: 0,
        StatusId: null
    });

    const [memberTaskList,
        { isSuccess: isSuccess,
            isLoading: isLoading,
            data: memberTaskResponse },
    ] = useGetCorporateTaskListMutation();

    const [deleteRecord, {
        data: deleteResponse,
        isSuccess: isDeleteSuccess,
        isLoading: isDeleteLoading,
    }] = useLazyDeleteMemberQuery()

    useEffect(() => {
        setDataSource([]);
        memberTaskList(paginationReq)
        setPaginationRequest(paginationReq);
    }, [paginationReq])

    useEffect(() => {
        if (!isLoading && isSuccess && memberTaskResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: memberTaskResponse.totalCount })
            setDataSource(memberTaskResponse.itemList)
        }
    }, [isLoading, isSuccess, memberTaskResponse]);

    useEffect(() => {
        if (!isDeleteLoading && isDeleteSuccess && deleteResponse) {
            ToastService.success("Delete Item successfully");
            memberTaskList(paginationReq)
        }
    }, [isDeleteLoading, isDeleteSuccess, deleteResponse])


    const handlePageChange = ({ pageNumber, pageSize }) => {
        memberTaskList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    // Function to handle Edit click
    const handleViewClick = (memberID) => {
        navigate(`/corporateMember/${encryptAESID(memberID)}`);
    }

    const handleCustomActionClick = (rowData, buttonName) => {
        if (buttonName === "Delete") {
            handleTableDelete(rowData?.corporateMembersId)
        } else {
            handleViewClick(rowData?.corporateMembersId)
        }
    };

    const handleTableDelete = (memberID) => {
        const request = {
            id: memberID,
            isCorporateMember: true,
            isAcademicMember: false,
        }
        confirmDelete("Are you sure?", "Type 'delete' to confirm", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    deleteRecord(request);
                }
            });
    }

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const actionHandler = {
        "CUSTOM_ACTION_CLICK": handleCustomActionClick
    }

    return (
        <>
            <h1 className="account-info-title">
                Total :{paginationRequest.totalCount}
            </h1>
            <MolGrid
                ref={molGridRef}
                configuration={corporateMemberTasklistTableConfig}
                dataSource={dataSource}
                allowPagination={true}
                isLoading={isLoading}
                pagination={paginationRequest}
                onPageChange={handlePageChange}
                onActionChange={actionHandler}
            />
            {isModalOpen &&
                <CenterModel
                    modelTitle={'Test'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <h1>Hello</h1>
                </CenterModel>
            }
        </>
    )
}
export default CorporateMemberTable