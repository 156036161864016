import React from 'react'
import EmailTemplateManagementForm from '../../features/emailTemplateManagement/EmailTemplateManagementForm'
import './EmailTemplateManagement.scss'

const EmailTemplateManagement = () => {
    return (
        <div>
            <EmailTemplateManagementForm />
        </div>
    )
}

export default EmailTemplateManagement