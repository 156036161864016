import corporateMemberTasklistFormData, { corporateFormData } from "../data/CorporateMemberTable.Data";

export const setAllDropDownField = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "statusId": filterDropDownList("TaskListStatus"),
        "membershipTypeId": filterDropDownList("CorporateMembershipType"),
        "industryCode": filterDropDownList("IndustryCode"),
        "bilingCategory": filterDropDownList("Billing Category"),
        "updatedBy": filterDropDownList(""),
        "pricingMembershipType": filterDropDownList("CorporateMembershipType"),
        "paymentTerms": filterDropDownList("Payment Terms"),
        "membershipTenureId": filterDropDownList("MembershipTenure"),
    };

    const formFields = [
        ...corporateMemberTasklistFormData.formFields,
        ...corporateFormData.formFields
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
