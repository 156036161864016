import React, { useRef, useEffect, useState } from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import resetPasswordFormData from "./ResetPasswordForm.data";
import { useNavigate, useParams } from "react-router-dom";
import Image from "../../components/image/Image";
import { AppIcons } from "../../data/appIcons";
import { useDispatch } from "react-redux";
import ToastService from "../../services/toastService/ToastService";
import { authentication } from "../../app/slice/authSlice";
import { saveData } from "../../utils/LocalStorage/LocalStorageManager";
import { encryptAESID, decryptAESID, aesDecrypt } from "../../utils/Encryption";

import { useResetPasswordForAdminMutation } from "../../app/services/AdminServices/authApi";

const ResetPasswordForm = (props) => {
  const resetPasswordFromRef = useRef();
  const { memberid } = useParams();
  console.log("Print Paramas values", { memberid })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminId = aesDecrypt(memberid);
  console.log( { adminId })

  // Declare update admin password API
  const [updateAdminPassword,
    { isLoading: updateLoading,
      isSuccess: updateSuccess,
      data: updatePasswordResponse }] = useResetPasswordForAdminMutation();

  const handleFormDataChange = (formData) => { };

  const handleUpdateAdminPassword = () => {
    // Retrieve form data
    const formData = resetPasswordFromRef.current?.getFormData();

    // Check if form data exists
    if (!formData) return;

    // Create the request object
    const request = {
      AdminID: Number(adminId),
      Password: formData?.newPassword,
      PasswordSalt: null,
      HashedPassword: null,
    };

    // Proceed with the login if form data exists
    updateAdminPassword(request);
  };


  useEffect(() => {
    if (!updateLoading && updateSuccess && updatePasswordResponse) {
      ToastService.success("Admin password update successfully !");
      navigate(`/login`);
    }
  }, [updateLoading, updateSuccess, updatePasswordResponse]);

  return (
    <div className="login-form-section">
      <div className="logo-section">
        <Image
          imagePath={AppIcons.logoImage}
          imgCustomClassName="open-bar"
          altText="Icon"
        ></Image>
      </div>
      <h2>New Password</h2>
      <div className="row">
        <FormCreator
          ref={resetPasswordFromRef}
          {...resetPasswordFormData}
          onFormDataUpdate={handleFormDataChange}
        ></FormCreator>
      </div>
      <div className="button-part">
        <Buttons
          buttonTypeClassName="theme-btn"
          buttonText="continue"
          onClick={handleUpdateAdminPassword}
          isLoading={updateLoading}
        />
      </div>
    </div>
  );
};
export default ResetPasswordForm;