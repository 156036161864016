import { NavigateButton } from "../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../data/formFieldType";


const resetPasswordFormData = {
    name: "Login From",
    initialState: {
        newPassword: "",
        confirmNewPassword: "",
    },
    formFields: [
        {
            id: "newPassword",
            lable: "Enter New Password",
            Field_Name: "new password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "newPassword",
            fieldSetting: {
                placeholder: "New Password",
                allowSpace: true,
            },
            validation: [{ type: "require" }, { type: "password" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
        {
            id: "confirmNewPassword",
            lable: "Confirm New Password",
            Field_Name: "confirm new password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "confirmNewPassword",
            fieldSetting: {
                placeholder: "Confirm New Password",
                allowSpace: true,
            },
            validation: [{ type: "require" }, { type: "password" }, { type: "compare", compareEle:"newPassword" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
    ],
};
export default resetPasswordFormData;