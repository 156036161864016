
import React, { useEffect, useRef, useState } from "react";
import ViewCorporateMembersFormData, { GridConfig } from "./data/ViewCorporateMembersForm.data";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import MolGrid from "../../components/Grid/MolGrid";
import formatDate from "../../lib/formatDate";
import { useGetCorporateMemberListMutation } from "../../app/services/Corporate/ViewCorporateMemberApi";
import { useNavigate } from "react-router-dom";
import { encryptAESID } from "../../utils/Encryption";
import { useLazyDeleteViewMemberQuery } from "../../app/services/Common/CommonApi";
import SwalAlert from "../../services/swalService/SwalService";
import ToastService from "../../services/toastService/ToastService";
import { AispWebUrl } from "../../utils/AppSetting";
import { getAuthProps } from "../../lib/authenticationLibrary";


const ViewCorporateMembersForm = (props) => {
  const navigate = useNavigate()
  const ViewCorporateMembersFormRef = useRef();
  const molGridRef = useRef();
  const { confirmDelete } = SwalAlert();
  const [searchFormData, setSearchForm] = useState(ViewCorporateMembersFormData);
  const [corporateMemberList, setCorporateMemberList] = useState(null);
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    searchText: "",
    searchSpecificDate: null,
    totalCount: 0,
    statusId: null,
    membershipTypeId: null
  });

  useEffect(() => {
    getMemberList()
  }, [])

  const [memberList,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: memberListResponse },
  ] = useGetCorporateMemberListMutation();

  const [deleteRecord, {
    data: deleteResponse,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  }] = useLazyDeleteViewMemberQuery()

  useEffect(() => {
    if (!isLoading && isSuccess && memberListResponse) {
      let tempList = memberListResponse.itemList.map((obj) => {
        return { ...obj, subscriptionEndDate: formatDate(obj?.subscriptionEndDate, "DD/MM/YYYY") }
      })
      setPaginationRequest({ ...paginationRequest, totalCount: memberListResponse.totalCount })

      setCorporateMemberList(tempList)
    }
  }, [isLoading, isSuccess, memberListResponse]);

  useEffect(() => {
    if (!isDeleteLoading && isDeleteSuccess && deleteResponse) {
      ToastService.success("Delete Item successfully");
      memberList(paginationRequest)
    }
  }, [isDeleteLoading, isDeleteSuccess, deleteResponse])

  const getMemberList = (rData) => {

    const request = {
      ...paginationRequest, ...rData
    }
    memberList(request)
  }
  const handlePageChange = (newPage) => {
    setPaginationRequest(previous => {

      const tempPagination = { ...previous, pageNo: newPage.pageNumber, pageSize: newPage.pageSize }
      getMemberList(tempPagination)
      return tempPagination
    })
  }
  const handleSearch = () => {
    if (ViewCorporateMembersFormRef) {
      let formData = ViewCorporateMembersFormRef.current.getFormData()

      let data = {
        searchText: formData.name,
        statusId: formData.statusId?.value ? formData.statusId?.value : formData.statusId,
        searchSpecificDate: formData.subscriptionEndDate,
        membershipTypeId: formData.membershipTypeId?.value ? formData.membershipTypeId?.value : formData.membershipTypeId,
        pageNo: 1
      }
      setPaginationRequest({ ...paginationRequest, ...data })
      getMemberList(data);
    }
  }

  const handleCustomActionClick = (rowData, buttonName) => {

    if (buttonName === "View") {
      navigate(`/editCorporateMembers/${encryptAESID(rowData.corporateMembersId)}`)
    }
    else {
      handleTableDelete(rowData?.corporateMembersId)
    }
  }

  const handleTableDelete = (memberID) => {
    const request = {
      id: memberID,
      isCorporateMember: true,
      isAcademicMember: false,
    }
    confirmDelete("Are you sure?", "Type 'delete' to confirm", "Delete", "Cancel")
      .then((confirmed) => {
        if (confirmed) {
          deleteRecord(request);
        }
      });

  }

  const actionHandler = {
    "CUSTOM_ACTION_CLICK": handleCustomActionClick
  }

  const handleCreateNewSignup = () => {
    let loginData = getAuthProps();
    window.open(`${AispWebUrl}/corporate/${encryptAESID(loginData?.adminUserId)}`);
  }

  return (
    <div className="form-field-sec">
      <div className="row align-items-end">
        <div className="col-10">
          <div className="row gx-5">
            <FormCreator
              ref={ViewCorporateMembersFormRef}
              {...searchFormData}
            ></FormCreator>
          </div>
        </div>

        <div className="col-2 d-flex justify-content-end">
          <div className="two-button-fixes">
            <div className="d-none">
              <Buttons
                buttonText="Clear"
                buttonTypeClassName="theme-btn"
              />
            </div>
            <div className="mb-3">
              <Buttons
                buttonText="Search"
                buttonTypeClassName="theme-btn"
                onClick={handleSearch}
              />
            </div>
            <div className="mb-6">
              <Buttons
                buttonText="Create New"
                buttonTypeClassName="theme-btn"
                onClick={handleCreateNewSignup}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-span-12">
          <div className="card-section mt-4">
            <h1 className="account-info-title">
              Total :{paginationRequest.totalCount}
            </h1>
            <MolGrid
              ref={molGridRef}
              configuration={GridConfig}
              dataSource={corporateMemberList}
              allowPagination={true}
              isLoading={isLoading}
              pagination={paginationRequest}
              onPageChange={handlePageChange}
              onActionChange={actionHandler}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default ViewCorporateMembersForm;
