import React, { useState, useEffect, useRef } from "react";
import SuperUserLoginModalData from "../data/SuperUserLoginModal.data";
import Buttons from "../../../components/ui/button/Buttons"
import FormCreator from "../../../components/Forms/FormCreator";
import { useLoginSuperUserModalMutation } from "../../../app/services/Corporate/ViewCorporateMemberApi";
import { AispWebUrl } from "../../../utils/AppSetting";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthProps } from "../../../lib/authenticationLibrary";
import { encryptAESID } from "../../../utils/Encryption";

const SuperUserLoginModal = ({ uenNumber, openAddModal, isCorporate, loginData }) => {
    const superLoginRef = useRef();
    const navigate = useNavigate();
    const { id } = useParams();
    const [superUserData, setSuperUserData] = useState(SuperUserLoginModalData);



    const [loginSuperUserModal,
        {
            data: superUserModalResponse,
            isSuccess: superUserModalSuccess,
            isLoading: superUserModalLoading,
        },
    ] = useLoginSuperUserModalMutation();

    // useEffect(() => {
    //     debugger
    //     let loginData = getAuthProps();

    //     if (superUserModalSuccess && superUserModalResponse) {
    //         const appType = isCorporate ? "/cppDashboard/cpp" : "/appDashboard/app";
    //         // window.open(`${AispWebUrl}/${appType}/${id}`);
    //         window.open(`${AispWebUrl}/${appType}/${id}?adminUserId=${encryptAESID(loginData.adminUserId)}`);
    //     }
    // }, [superUserModalLoading, superUserModalResponse, superUserModalSuccess]);


    useEffect(() => {
        let loginData = getAuthProps();

        if (superUserModalSuccess && superUserModalResponse) {
            const { isAuthenticated } = superUserModalResponse;
            if (isAuthenticated) {
                const appType = isCorporate ? "/cppDashboard/cpp" : "/appDashboard/app";
                window.open(`${AispWebUrl}/${appType}/${id}?adminUserId=${encryptAESID(loginData.adminUserId)}`);
            }
            else{
                alert(superUserModalResponse.errorMessage);
                return;
            }
        }
    }, [superUserModalLoading, superUserModalResponse, superUserModalSuccess]);



    const handleOpenSuperUserModal = () => {
        const SuperUserLoginModalData = superLoginRef.current.getFormData();
        const request = {
            CompanyUEN: uenNumber,
            Password: SuperUserLoginModalData?.password,
            IsCorporate: isCorporate ? true : false,
        };

        loginSuperUserModal(request);

    };
    return (
        <div className="">
            <div className="form-field-sec">
                <div className="row gx-5">
                    <FormCreator
                        ref={superLoginRef}
                        {...superUserData}
                    >
                    </FormCreator>
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <div className="mt-4">
                        <Buttons
                            buttonText="Login"
                            buttonTypeClassName="theme-btn "
                            onClick={handleOpenSuperUserModal}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SuperUserLoginModal


