import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { signOut } from './lib/authenticationLibrary';


const AuthMiddleware = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const authToken = Cookies.get('AdminAuthUser');
        if (!authToken) {
            signOut()
        }
    }, [navigate]);
    return children;
};
export default AuthMiddleware;