import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';


const ApplicationBackground = forwardRef(({ formData, handleOnChangeFileUpload, handleChangeOnFileDownload }, ref) => {
  const ApplicationBackgroundFormRef = useRef();

  const handleFormFieldChange = (dataField, value) => {
    handleOnChangeFileUpload(dataField, value)
  };

  const handleDownload = (dataField, value) => {
    handleChangeOnFileDownload(dataField, value)
  };

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = ApplicationBackgroundFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const formActionHandler = {
    DOWNLOAD: handleDownload,
  }

  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={ApplicationBackgroundFormRef}
          onActionChange={formActionHandler}
          {...formData}
          onFormFieldValueChange={handleFormFieldChange}
        ></FormCreator>
      </div>
    </div>
  )
}
)
export default ApplicationBackground