import React, { Suspense, useEffect, useRef } from 'react'
import Image from '../../components/image/Image'
import { AppIcons } from '../../data/appIcons'
import FormCreator from '../../components/Forms/FormCreator'
import { authanticationFormData } from './LoginForm.data'
import Buttons from '../../components/ui/button/Buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetQRCodeMutation, useValidateOTPMutation } from '../../app/services/AdminServices/authApi'
// import { getCookie } from '../../utils/Cookies/CookieHandler'
import SwalAlert from '../../services/swalService/SwalService'
import { useDispatch, useSelector } from 'react-redux'
import { otpAuth } from '../../app/slice/authSlice'
import { saveData, getData } from '../../utils/LocalStorage/LocalStorageManager'
import { decryptAESID } from '../../utils/Encryption'

const AuthenticationForm = () => {
  const authanticationFromRef = useRef();
  const { memberid } = useParams();
  const authID = memberid ? decryptAESID(memberid, false).toString() : 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let loginData = getData("authData")
  // const loginData = getCookie("AdminAuthUser");
  const { error, success } = SwalAlert();
  const { isLogedin, isOtpValid, isQRScan } = useSelector((state) => state.auth)
  const [GetQRCode, { data: QRCode, isSuccess: isSuccess, isLoading: isLoading }] = useGetQRCodeMutation();
  const [OTPValidate, { data: OTPValidateResponse, isSuccess: isOTPSuccess, isLoading: isOTPLoading }] = useValidateOTPMutation();

  useEffect(() => {
    if (authID) {
      const request = {
        email: loginData?.userName || null
      }
      GetQRCode(request);
    }
  }, [authID]);

  useEffect(() => {
    if (!isOTPLoading && isOTPSuccess && OTPValidateResponse) {
      if (OTPValidateResponse.valid) {
        dispatch(otpAuth(OTPValidateResponse));
        saveData('isValidOTP', OTPValidateResponse);
      } else {
        error("Invalid OTP.")
      }
    }
  }, [isOTPLoading, isOTPSuccess, OTPValidateResponse]);

  // Effect to handle navigation based on the authentication
  useEffect(() => {
    if (isLogedin && isOtpValid) {
      navigate(`/dashboard`);
    }
  }, [isLogedin, isOtpValid]);

  const handleOTPValidation = () => {
    let formResponse = authanticationFromRef.current.getFormData();
    if (!formResponse) {
      return error("Please Enter OTP field.", "")
    }
    const request = {
      OTP: formResponse?.otp || null,
      email: loginData?.userName || null,
      isAcademic: false,
      isCorporate: false,
      isAdmin: true
    }
    OTPValidate(request);
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="auth-container">
        <div className="row d-flex justify-content-center mt-5 width">
          <div className="col-lg-4">
            <div className="authantication-form-main">
              <div className="authantication-form-section">
                <div className="logo-section">
                  <Image
                    alt="Logo Image"
                    imgCustomClassName="main-logo-sec"
                    imagePath={AppIcons.Authanticationbg}
                  />
                </div>
                <h2>
                  {loginData?.isQRScan ? "Welcome back! Please enter your OTP to continue." : "Unlock the next chapter with a scan"}
                </h2>
                <div className="qr-code-sec">
                  <Suspense
                    fallback={
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    }
                  >
                    {!loginData?.isQRScan && (<Image
                      alt="Logo Image"
                      imgCustomClassName="main-logo-sec"
                      imagePath={`data:image/png;base64,${QRCode}`}
                    />
                    )}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-2 mb-5 width">
          <div className="col-lg-4">
            <div className="row">
              <FormCreator
                ref={authanticationFromRef}
                {...authanticationFormData}
              ></FormCreator>
              <div className="col-lg-12 d-flex justify-content-center">
                <Buttons
                  buttonTypeClassName="theme-btn"
                  buttonText="Validate"
                  onClick={handleOTPValidation}
                  isLoading={isOTPLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthenticationForm
