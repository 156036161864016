import React, { useRef, useEffect, useState } from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import loginFormData from "./LoginForm.data";
import { useNavigate } from "react-router-dom";
import Image from "../../components/image/Image";
import { AppIcons } from "../../data/appIcons";
import SwalAlert from "../../services/swalService/SwalService";
import { useDispatch, useSelector } from "react-redux";
import ToastService from "../../services/toastService/ToastService";
import { authentication } from "../../app/slice/authSlice";
import { useLoginMutation } from "../../app/services/AdminServices/authApi";
import { saveData } from "../../utils/LocalStorage/LocalStorageManager";
import { encryptAESID } from "../../utils/Encryption";

const LoginForm = (props) => {
  const loginFromRef = useRef();
  const { isLogedin } = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Declare admin login API
  const [login,
    { isLoading: loginLoading,
      isSuccess: loginSuccess,
      data: authData }] = useLoginMutation();

  const handleLogin = () => {
    // Retrieve form data
    const authData = loginFromRef.current?.getFormData();

    // Check if form data exists
    if (!authData) return;

    // Get the current date and time in the required format
    const currentDateTime = new Date().toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });

    // Create the request object
    const request = {
      userName: authData.emailAddress,
      password: authData.password,
      isVerified: true,
      currentDateTimeFromUserSystem: currentDateTime
    };
    // Proceed with the login if form data exists

    login(request);
  };

  const handleFormDataChange = (formData) => { };

  useEffect(() => {
    if (!loginLoading && loginSuccess && authData) {
      if (authData.isAuthenticated === false) {
        ToastService.error(authData.message);
      } else if (authData.isAuthenticated === true) {
        saveData("isPIC1", authData.isPIC1);
        saveData("pic1Name", authData.name);
        saveData("authData", authData);
        ToastService.success("You are login successfully", "Success");
        dispatch(authentication(authData));
      }
    }
  }, [loginLoading, loginSuccess, authData]);


  // Effect to handle navigation based on the authentication
  useEffect(() => {
    if (isLogedin) {
      navigate(`/AuthLogin/${encryptAESID(authData?.adminUserId)}`);
    }
  }, [isLogedin])

  return (
    <div className="login-form-section">
      <div className="logo-section">
        <Image
          imagePath={AppIcons.logoImage}
          imgCustomClassName="open-bar"
          altText="Icon"
        ></Image>
      </div>
      <h2>Login</h2>
      <div className="row">
        <FormCreator
          ref={loginFromRef}
          {...loginFormData}
          onFormDataUpdate={handleFormDataChange}
        ></FormCreator>
      </div>
      <div className="button-part">
        <Buttons
          buttonTypeClassName="theme-btn"
          buttonText="Sign in"
          onClick={handleLogin}
          isLoading={loginLoading}
        />
      </div>
    </div>
  );
};

export default LoginForm;
