import React, { useEffect } from 'react'
import Buttons from '../../../components/ui/button/Buttons'
import { saveAs } from 'file-saver'
import { useParams } from 'react-router-dom'
import { decryptAESID } from '../../../utils/Encryption'

import { useLazyGetAcademicGeneratePdfByIdQuery, useLazyGetCorporateGeneratePdfByIdQuery, useLazyGeneratePDFForIndividualInformationQuery } from '../../../app/services/MembershipService/Academic/AcademicTask_ListApi'

const GenratePDF = (props) => {
  const memberID = useParams();
  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }
  ] = useLazyGetAcademicGeneratePdfByIdQuery();

  const [getPDFForCorporate,
    { isSuccess: isSuccessGetPDFForCorporate,
      isLoading: isLoadingGetPDFForCorporate,
      data: getPDFDataForCorporate }
  ] = useLazyGetCorporateGeneratePdfByIdQuery();

  const [getPDFForIndividual,
    { isSuccess: isSuccessGetPDFForIndividual,
      isLoading: isLoadingGetPDFForIndividual,
      data: getPDFDataForIndividual }
  ] = useLazyGeneratePDFForIndividualInformationQuery();



  // download pdf
  const handleDownloadPDF = async () => {
    let memberIdDecrypted = decryptAESID(memberID?.id);
    let requestType = props.type;
    let response;

    if (requestType === "Academic") {
      response = await getPDF(memberIdDecrypted);
    } else if (requestType === "Corporate") {
      response = await getPDFForCorporate(memberIdDecrypted.toString());
    } else if (requestType === "Individual") {
      response = await getPDFForIndividual(memberIdDecrypted.toString());
    }

    if (response && response.data) {

      const { fileData, fileName } = response.data;
      const blob = new Blob([fileData], { type: 'application/pdf' });

      let tempfileName = 'Application.pdf'; // Default name
      if (requestType === "Academic") {
        tempfileName = 'Academic Application Details.pdf';
      } else if (requestType === "Corporate") {
        tempfileName = 'Corporate Application Details.pdf';
      } else if (requestType === "Individual") {
        tempfileName = 'Individual Application Details.pdf';
      }
      saveAs(blob, tempfileName);
    }
  }

  useEffect(() => {
    if (!isLoadingGetPDF && isSuccessGetPDF && getPDFData) {
    }
  }, [isLoadingGetPDF, isSuccessGetPDF, getPDFData])

  useEffect(() => {
    if (!isLoadingGetPDFForCorporate && isSuccessGetPDFForCorporate && getPDFDataForCorporate) {
    }
  }, [isLoadingGetPDFForCorporate, isSuccessGetPDFForCorporate, getPDFDataForCorporate])

  return (
    <React.Fragment>
      <Buttons
        buttonText="Generate PDF"
        buttonTypeClassName="btn-link bg-light"
        onClick={handleDownloadPDF}
      />

    </React.Fragment>
  )
}

export default GenratePDF