import React, { useEffect, useRef, useState } from 'react'
import EmailTemplateManagementFormData from './data/EmailTemplateManagementTable.Data.js';
import FormCreator from '../../components/Forms/FormCreator.js';
import Buttons from '../../components/ui/button/Buttons.js';
import SearchBar from '../component/SearchBar.js';
import { useNavigate } from 'react-router-dom';
import MolGrid from '../../components/Grid/MolGrid.js';

import { emailTemplateManagementTableConfig } from './data/EmailTemplateManagementTable.Data.js';
import { useLazyGetAllDropdownsForAISP1Query } from '../../app/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi.js';
import SwalAlert from '../../services/swalService/SwalService.js';
import { useDeleteEmailTemplateMutation, useLazyGetEmailTemplateByIdQuery, useGetEmailTemplateListMutation } from '../../app/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi.js';

import Label from '../../components/ui/label/Label.js';
import Input from '../../components/ui/inputs/input/Input.js';
import DataLoader from '../../components/ui/dataLoader/DataLoader';
import CreateEmailTemplateForm from '../createEmailTemplate/CreateEmailTemplateForm.js';


const EmailTemplateManagementForm = () => {
    const [emialList, setEmailList] = useState()
    const EmailTemplateManagementFormRef = useRef();
    const { confirm, success, error } = SwalAlert()


    const [searchRequest, setSearchRequest] = useState({
        ProjectTypeId: null,
        SearchText: null,
    });
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        SearchText: null,
        ProjectTypeId: null,
        totalCount: 0,
    });

    // click to open create email template 
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/CreateEmailTemplate');

    };

    // table design adjustment 
    const molGridRef = useRef();


    const [getAllDropdownsForAISP, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetAllDropdownsForAISP1Query()

    const [getEmailTemplateList, {
        isSuccess: isSuccessEmailList,
        isFetching: isFetchingEmailList,
        data: emailListResponse
    }] = useGetEmailTemplateListMutation()

    useEffect(() => {
        getAllDropdownsForAISP();
    }, [])

    const [deleteEmailTemplate, {
        isSuccess: isSuccessdeleteEmailTemplate,
        data: deleteEmailTemplateResponse
    }] = useDeleteEmailTemplateMutation()

    useEffect(() => {
        if (isSuccessdeleteEmailTemplate && deleteEmailTemplateResponse) {
            if (deleteEmailTemplateResponse.hasError === false) {
                success("Email Deleted Successfully");
                getEmailList();
            }
            else {
                error(deleteEmailTemplateResponse.errorMessage)
            }
        }
    }, [isSuccessdeleteEmailTemplate, deleteEmailTemplateResponse]);


    useEffect(() => {
        getEmailList()
    }, [])

    useEffect(() => {
        if (!isFetchingEmailList && isSuccessEmailList && emailListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: emailListResponse.totalCount })
            setEmailList(emailListResponse.itemList);

        }
    }, [isFetchingEmailList, isSuccessEmailList, emailListResponse]);
    const handlePageChange = ({ pageNumber, pageSize }) => {
        getEmailList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            let field = EmailTemplateManagementFormData.formFields.find(obj => obj.id === "ProjectTypeId")
            field.fieldSetting.options = dropdownResponse.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);

    const getEmailList = (request) => {

        const req = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            SearchText: null,
            ProjectTypeId: null,
            ...request
        }
        getEmailTemplateList(req)
    }


    const handleDelete = (data) => {
        confirm('Delete', "Are you sure you want to delete", 'Yes', 'Cancel')
            .then(value => {
                if (value) {
                    const request = {
                        id: data.emailTemplateId,
                        deletedBy: 115,
                        appId: 1
                    }
                    deleteEmailTemplate(request)
                }
            })
    }

    const handleEdit = (data) => {

        navigate(`/EmailTemplateManagement/${data.emailTemplateId}`);
    }
    const handleInputChange = (e) => {
        let temp = { ...searchRequest }
        temp["SearchText"] = e.target.value
        setSearchRequest(temp)
        getEmailList(temp);
    }

    const onDropDownChange = (data) => {
        const { value } = data.ProjectTypeId
        let temp = { ...searchRequest }
        temp["ProjectTypeId"] = value
        setSearchRequest(temp)
        getEmailList(temp);
    }
    const actionHandler = {
        DELETE: handleDelete,
        EDIT: handleEdit
    };
    return (
        <div className='email-template-management'>
            <div className="dropdown-management-main">
                <div className="row align-items-center card-bg">
                    <FormCreator
                        ref={EmailTemplateManagementFormRef}
                        {...EmailTemplateManagementFormData}
                        onFormDataChange={onDropDownChange}
                    ></FormCreator>
                </div>
                <div className="row align-items-center card-bg mt-5">
                    <div className="custom-table-sec">
                        <div className='d-flex justify-content-between'>
                            <div className='button-part'>
                                <Buttons
                                    buttonTypeClassName="outline-btn"
                                    buttonText="+ Add New "
                                    // isLoading={loginLoading}
                                    onClick={handleClick}
                                />
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 mb-3 my-3 px-2'>
                                    <Label labelName="Search:" />
                                </div>
                                <div className='col-lg-9 mb-3 my-2'>
                                    <Input
                                        id="searchText"
                                        name='SearchText'
                                        value={searchRequest.SearchText}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='email-template table-responsive'>
                            {emialList ?
                                <MolGrid ref={molGridRef}
                                    configuration={emailTemplateManagementTableConfig}
                                    dataSource={emialList}
                                    allowPagination={true}
                                    onActionChange={actionHandler}
                                    isLoading={isFetchingEmailList}
                                    pagination={paginationRequest}
                                    onPageChange={handlePageChange}
                                />
                                : <DataLoader />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EmailTemplateManagementForm