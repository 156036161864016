import { FormFieldTypes } from "../../../../../data/formFieldType";

const PersonalInformationData = {
    name: "PersonalInformationData",
    initialState: {
        salutationsID: null,
        firstNameasInPassport: "",
        lastNameasInPassport: "",
        contactNumber: "",
        howWouldYouLikeUsToAddressYou: "",
        isGenderMale: "male",
        nationality: "",
        countryOfResidence: "",
        residentStatusId: "",
        raceId: "",
        businessEmail: "",
        alternateEmail: "",
        linkedInProfile: "https://www.linkedin.com/in/",
        userName: "",
        graduationDate: "",
    },
    formFields: [
        {
            id: "salutationsID",
            lable: "First Name as in NRIC/Passport",
            Field_Name: ["Salutations", "& First Name"],
            fieldType: FormFieldTypes.INPUTGROUPSELECT,
            dataField: ["salutationsID", "firstNameasInPassport"],
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
                options: [],
                // isDisable: true,
                // isEdit: true
            },
            //validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "lastNameasInPassport",
            lable: "Last Name as in NRIC/Passport",
            Field_Name: "Last Name as in NRIC/Passport",
            fieldType: FormFieldTypes.INPUT,
            dataField: "lastNameasInPassport",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "contactNumber",
            lable: "Contact Number",
            Field_Name: "Contact Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "contactNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },

        {
            id: "howWouldYouLikeUsToAddressYou",
            lable: "How would you like us to address you?",
            Field_Name: "How would you like us to address you?",
            fieldType: FormFieldTypes.INPUT,
            dataField: "howWouldYouLikeUsToAddressYou",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "isGenderMale",
            label: "Gender",
            Field_Name: "Gender",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isGenderMale",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Male", value: "male", isDisable: false, optionClass: "", },
                    { label: "Female", value: "female", isDisable: false, optionClass: "ml-3", },
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },
        {
            id: "nationality",
            lable: "Nationality",
            Field_Name: "Nationality",
            fieldType: FormFieldTypes.SELECT,
            dataField: "nationality",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "countryOfResidence",
            lable: "Country of Residence ",
            Field_Name: "Country of Residence ",
            fieldType: FormFieldTypes.SELECT,
            dataField: "countryOfResidence",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },

        {
            id: "residentStatusId",
            lable: "Resident Status",
            Field_Name: "Resident Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "residentStatusId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "raceId",
            lable: "Race",
            Field_Name: "Race",
            fieldType: FormFieldTypes.SELECT,
            dataField: "raceId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "businessEmail",
            lable: "Primary Email Address",
            Field_Name: "Primary Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "businessEmail",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
            },
            // validation: [{ type: "require" }, { type: "email" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "alternateEmail",
            lable: "Alternate Email Address",
            Field_Name: "Alternate Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "alternateEmail",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        // {
        //     id: "linkedInProfile",
        //     lable: "LinkedIn Profile",
        //     Field_Name: "LinkedIn Profile",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "linkedInProfile",
        //     fieldSetting: {
        //         placeholder: "",
        //         allowSpace: false,
        //     },
        //     validation: [],
        //     style: {
        //         containerCss: "col-md-6 mb-3",
        //     },
        // },
        {
            id: "linkedInProfile",
            lable: "LinkedIn Profile",
            Field_Name: ["Linkedin Profile", "& User Name"],
            fieldType: FormFieldTypes.INPUTGROUP,
            dataField: ["linkedInProfile", "userName"],
            fieldSetting: {
                // placeholder: "Select",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "graduationDate",
            lable: "Graduation Date",
            Field_Name: "Graduation Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "graduationDate",
            fieldSetting: {
                dateFormat: "MM/yyyy",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
    ],
};
export default PersonalInformationData;