import { individualMemberTasklistTableConfig, inidividualFormData } from "../data/IndividualMemberTable.Data";
import { auditLogData } from "../data/AuditLog.Data";

export const setFieldParameter = (apiResponse) => {
    inidividualFormData.initialState.salutationsID = apiResponse?.salutationId;
    inidividualFormData.initialState.fullName = apiResponse?.firstName;
    inidividualFormData.initialState.lastName = apiResponse?.lastName;
    inidividualFormData.initialState.contact = apiResponse?.contactNo;
    inidividualFormData.initialState.howWould = apiResponse?.likeUsToAddressYou;
    inidividualFormData.initialState.isGenderMale = apiResponse?.isMale ? 'male' : 'female';
    inidividualFormData.initialState.nationalityID = apiResponse?.nationalityTypeId;
    inidividualFormData.initialState.countryOfResID = apiResponse?.countryOfResidenceId;
    inidividualFormData.initialState.residentStatus = apiResponse?.residentStatusId;
    inidividualFormData.initialState.race = apiResponse?.raceId;
    inidividualFormData.initialState.bwEmailAddress = apiResponse?.businessEmail;
    inidividualFormData.initialState.awEmailAddress = apiResponse?.personalEmail;
    inidividualFormData.initialState.linkedinProfile = apiResponse?.linkedinProfile;
    inidividualFormData.initialState.billingCategory = apiResponse?.individualMemberBillingCategoryId;
    inidividualFormData.initialState.updatedBy = apiResponse?.firstName;
    inidividualFormData.initialState.subscriptionStartDate = apiResponse?.subscriptionStartDate;
    inidividualFormData.initialState.subscriptionEndDate = apiResponse?.subscriptionEndDate;
    inidividualFormData.initialState.subscriptionLastDate = apiResponse?.updatedDate;
    inidividualFormData.initialState.membershipTypeId = apiResponse?.membershipTypeId;
    inidividualFormData.initialState.membershipTenureId = apiResponse?.membershipTenureId;
    inidividualFormData.initialState.isStartMembershipOn = apiResponse?.isStartMemberShip ? true : false;
    inidividualFormData.initialState.startMembershipDate = apiResponse?.startMembershipOn;
    inidividualFormData.initialState.paymentTerms = apiResponse?.paymentTermsId || 1;
    inidividualFormData.initialState.comment = apiResponse?.commentstomember;
    //upgrade membership initials
    inidividualFormData.initialState.currentMembershipTier = apiResponse?.currentMembershipType;
    inidividualFormData.initialState.newMembershipTier = apiResponse?.membershipTypeId;
    inidividualFormData.initialState.subscriptionEndDateForUpgrade = apiResponse?.subscriptionEndDate;



    const navigatePathIndex = inidividualFormData.formFields.findIndex(field => field.id === "navigatePath");
    if (navigatePathIndex !== -1) {
        const updatedFormData = { ...inidividualFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[navigatePathIndex].fieldSetting.identifyNumber = apiResponse.individualMemberId;
    }

};


export const setPricingDetailsField = (apiResponse) => {
    inidividualFormData.initialState.entrenceFee = apiResponse?.applicationFee || 0;
    inidividualFormData.initialState.annualFee = apiResponse?.annualFee || 0;
    inidividualFormData.initialState.currentTierApplicationFee = apiResponse?.currentTierApplicationFee || 0; // Get from membership payment
    inidividualFormData.initialState.currentTierAnnualFee = apiResponse?.currentTierAnnualFee || 0; // Get from membership payment
    inidividualFormData.initialState.newTierApplicationFee = apiResponse?.newTierApplicationFee || 0; // Get from prising table
    inidividualFormData.initialState.newTierAnnualFee = apiResponse?.newTierAnnualFee || 0; // Get from prising table
    inidividualFormData.initialState.annualFeePaing = apiResponse?.annualFee || 0; // get from Neew sp
    inidividualFormData.initialState.applicationFeePaing = apiResponse?.applicationFee || 0; // get from Neew sp
    return inidividualFormData
}

export const setAuditLogField = (apiResponse) => {
    let getPIC1Name = apiResponse?.auditLogList ? apiResponse.auditLogList[0].updateByUser : "";
    auditLogData.initialState.taskId = apiResponse?.taskId || 0;
    auditLogData.initialState.PIC1Name = getPIC1Name || "";
    auditLogData.initialState.applicantName = `${apiResponse?.firstName} ${apiResponse?.lastName} ` || "";
    auditLogData.initialState.internalNotes = "";
    return auditLogData
}

export const resetInternalNotes = (apiResponse) => {
    auditLogData.initialState.internalNotes = "";
    return auditLogData
}

export const onHandleNationalityDropdown = (dataField, formData, isUpgrade) => {
    //find index of object 
    const residentStatusIndex = formData.formFields.findIndex(field => field.id === "residentStatus");

    const upgradeMembershipDetailsIndex = formData.formFields.findIndex(field => field.id === "upgradeMembershipDetails");
    const currentMembershipTierIndex = formData.formFields.findIndex(field => field.id === "currentMembershipTier");
    const currentTierApplicationFeeIndex = formData.formFields.findIndex(field => field.id === "currentTierApplicationFee");
    const currentTierAnnualFeeIndex = formData.formFields.findIndex(field => field.id === "currentTierAnnualFee");

    const newMembershipTierIndex = formData.formFields.findIndex(field => field.id === "newMembershipTier");
    const newTierApplicationFeeIndex = formData.formFields.findIndex(field => field.id === "newTierApplicationFee");
    const newTierAnnualFeeIndex = formData.formFields.findIndex(field => field.id === "newTierAnnualFee");

    const subscriptionEndDateForUpgradeIndex = formData.formFields.findIndex(field => field.id === "subscriptionEndDateForUpgrade");
    const applicationFeePaingIndex = formData.formFields.findIndex(field => field.id === "applicationFeePaing");
    const annualFeePaingIndex = formData.formFields.findIndex(field => field.id === "annualFeePaing");

    const isHideAndRemoveValidation = dataField.value !== 1 ? true : false;

    const newCssValue = isHideAndRemoveValidation ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    const newValidationValue = isHideAndRemoveValidation ? [{ type: "require" }] : [];
    const updatedFormData = { ...formData };
    if (residentStatusIndex !== -1) {
        updatedFormData.formFields[residentStatusIndex].style.containerCss = newCssValue;
        updatedFormData.formFields[residentStatusIndex].validation = newValidationValue;
    }

    if (isUpgrade === false) {
        if (upgradeMembershipDetailsIndex !== -1) {
            updatedFormData.formFields[upgradeMembershipDetailsIndex].style.containerCss = "col-lg-12 mb-2 text-left form-tab-header d-none";
        }
        if (currentMembershipTierIndex !== -1) {
            updatedFormData.formFields[currentMembershipTierIndex].style.containerCss = "col-lg-6 mb-3 d-none";
        }
        if (currentTierApplicationFeeIndex !== -1) {
            updatedFormData.formFields[currentTierApplicationFeeIndex].style.containerCss = "col-md-3 mb-3 d-none";
        }
        if (currentTierAnnualFeeIndex !== -1) {
            updatedFormData.formFields[currentTierAnnualFeeIndex].style.containerCss = "col-md-3 mb-3 d-none";
        }

        if (newMembershipTierIndex !== -1) {
            updatedFormData.formFields[newMembershipTierIndex].style.containerCss = "col-lg-6 mb-3 d-none";
        }
        if (newTierApplicationFeeIndex !== -1) {
            updatedFormData.formFields[newTierApplicationFeeIndex].style.containerCss = "col-md-3 mb-3 d-none";
        }
        if (newTierAnnualFeeIndex !== -1) {
            updatedFormData.formFields[newTierAnnualFeeIndex].style.containerCss = "col-md-3 mb-3 d-none";
        }

        if (subscriptionEndDateForUpgradeIndex !== -1) {
            updatedFormData.formFields[subscriptionEndDateForUpgradeIndex].style.containerCss = "col-lg-6 mb-3 d-none";
        }
        if (applicationFeePaingIndex !== -1) {
            updatedFormData.formFields[applicationFeePaingIndex].style.containerCss = "col-md-3 mb-3 d-none";
        }
        if (annualFeePaingIndex !== -1) {
            updatedFormData.formFields[annualFeePaingIndex].style.containerCss = "col-md-3 mb-3 d-none";
        }

    }
    return { ...updatedFormData };
}


export const forUserCreatedByAdmin = (dataField, formData) => {
    const fieldsToUpdate = ["lastName", "howWould", "isGenderMale", "nationalityID", "countryOfResID", "residentStatus", "race",];
    const isAdmin = dataField ? dataField.includes("ADM") : false;
    const newValidationValue = isAdmin ? [] : [{ type: "require" }];

    const updatedFormData = {
        ...formData,
        formFields: formData.formFields.map(field =>
            fieldsToUpdate.includes(field.id)
                ? { ...field, validation: newValidationValue }
                : field
        )
    };
    return { ...updatedFormData };
};

export const hideUpgradeDetailsSection = (isUpgradeFlag, formData) => {
    const fieldStyles = [
        { id: "upgradeSeperator", cssValue: "separation-line pl-0 pr-0" },
        { id: "upgradeMembershipDetails", cssValue: "col-lg-12 mb-2 text-left form-tab-header" },
        { id: "currentMembershipTier", cssValue: "col-lg-6 mb-3" },
        { id: "currentTierApplicationFee", cssValue: "col-md-6 mb-3" },
        { id: "currentTierAnnualFee", cssValue: "col-md-6 mb-3" },
        { id: "newMembershipTier", cssValue: "col-lg-6 mb-3" },
        { id: "newTierApplicationFee", cssValue: "col-md-6 mb-3" },
        { id: "newTierAnnualFee", cssValue: "col-md-6 mb-3" },
        { id: "subscriptionEndDateForUpgrade", cssValue: "col-lg-6 mb-3" },
        { id: "applicationFeePaing", cssValue: "col-md-6 mb-3" },
        { id: "annualFeePaing", cssValue: "col-md-6 mb-3" },
    ];

    const updatedFormData = { ...formData };
    const visibilitySuffix = isUpgradeFlag ? "" : " d-none";

    fieldStyles.forEach(({ id, cssValue }) => {
        const fieldIndex = formData.formFields.findIndex(field => field.id === id);
        if (fieldIndex !== -1) {
            updatedFormData.formFields[fieldIndex].style.containerCss = `${cssValue}${visibilitySuffix}`;
        }
    });

    return { ...updatedFormData };
};
