import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";

const authapi = createApi({
    baseQuery: defautBaseQuery, // Replace with your API endpoint
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/Account/AdminLogin', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(credentials)
            }),
            invalidatesTags: ['authentication'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        ValidateAdminUserPassword: builder.mutation({
            query: (credentials) => ({
                url: '/Account/ValidateAdminUserPassword', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(credentials)
            }),
            invalidatesTags: ['authentication'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        GetQRCode: builder.mutation({
            query: (credentials) => ({
                url: '/QR/GetQRCode', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(credentials)
            }),
            invalidatesTags: ['authentication'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        ValidateOTP: builder.mutation({
            query: (credentials) => ({
                url: '/QR/ValidateOTP', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(credentials)
            }),
            invalidatesTags: ['authentication'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        ForgotPasswordWeb: builder.mutation({
            query: (credentials) => ({
                url: '/WebMembership/ForgotPasswordWeb', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(credentials)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        ResetPasswordForAdmin: builder.mutation({
            query: (credentials) => ({
                url: '/WebMembership/ResetPasswordForAdmin', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(credentials)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),


    }),
});
export const {
    useLoginMutation,
    useValidateAdminUserPasswordMutation,
    useGetQRCodeMutation,
    useValidateOTPMutation,
    useForgotPasswordWebMutation,
    useResetPasswordForAdminMutation,
} = authapi;
export default authapi;
