import React from "react";
import EmailManagementForm from "../../features/emailManagement/EmailManagementForm";
import "./EmailManagement.scss";

function EmailManagement() {
  return (
    <>
      <div className="email-page-main">
        <div className="filter-sec">
          <EmailManagementForm />
        </div>
      </div>
    </>
  );
}
export default EmailManagement;