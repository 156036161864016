import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";

const ViewCorporateMemberApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'ViewCorporateMemberApi',
    endpoints: (builder) => ({
        getCorporateMemberList: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/GetCorporateMemberList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        // GetAllDropdownsForCorporateMember: builder.query({
        //     query: () => ({
        //         url: encryptQueryString('/Common/GetAllDropdownsForCorporateMember'),
        //         method: 'GET',
        //     }),
        //     transformResponse: transformSucessResponse,
        //     transformErrorResponse: transformErrorResponse,
        // }),
        getCorporateMemberDetailById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CorporateMember/GetAllCorporateInformationDetailsById?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getCorporateSIISAccountInfo: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CorporateMember/GetCorporateSIISAccountInformationById?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateCorporateMemberById: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/UpdateCorporateMemberById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        loginSuperUserModal: builder.mutation({
            query: (details) => ({
                url: '/CorporateMember/LoginToCppAppMembersForSuperUser',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
})

export const {
    useGetCorporateMemberListMutation,
    useLazyGetCorporateMemberDetailByIdQuery,
    useLazyGetCorporateSIISAccountInfoQuery,
    useUpdateCorporateMemberByIdMutation,
    useLoginSuperUserModalMutation

} = ViewCorporateMemberApi;

export default ViewCorporateMemberApi;