import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";

export const UploadMemberExcelFileData={
    name: 'Upload EDMMember',
    initialState: {
        source: "",
    },
    formFields: [
        {
            id: "source",
            lable: "Source Tag",
            Field_Name: "source",
            fieldType: FormFieldTypes.SELECT,
            dataField: "TagName",
            fieldSetting: {
                isMultiSelect: true,
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}
const cssstyle=(value) => {
    return value===true? "success":"danger";
};


export const NonMembeEDMTableConfig={
    columns: [
        {
            name: "First Name",
            fieldName: "memberFirstName",
            allowShort: false,
        },
        {
            name: "Last Name",
            fieldName: "memberLastName",
            allowShort: false,
        },
        {
            name: "Email",
            fieldName: "email",
            allowShort: false,
        },
        {
            name: "Source Tag",
            fieldName: "sourceTag",
            allowShort: false,
        },
        {
            name: "EDM",
            fieldName: "edm",
            colType: GridColumnType.LABLE,
            colSettings: {
                valueField: 'newsLetter',
                getLableClass: (value) => {
                    return cssstyle(value)
                }
            }
        },
        {
            name: "Bulletin",
            fieldName: "bulletin",
            colType: GridColumnType.LABLE,
            colSettings: {
                valueField: 'newsLetter',
                getLableClass: (value) => {
                    return cssstyle(value)
                }
            }
        },
        {
            name: "Newsletter",
            fieldName: "newsLetter",
            colType: GridColumnType.LABLE,
            colSettings: {
                valueField: 'newsLetter',
                getLableClass: (value) => {
                    return cssstyle(value)
                }
            }
        },

        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "View",onClickAction: true },
                { name: "Delete",onClickAction: true },
            ],
        },
    ],
};

export const NonMembeEDMFormData={
    name: 'Edit Non-Member',
    initialState: {
        memberFirstName: "",
        memberLastName: "",
        email: "",
        source: "",
        EDMCheckbox: "",
        bulletCheckbox: "",
        newsLetterCheckBox: ""

    },
    formFields: [
        {
            id: "memberFirstName",
            lable: "First Name",
            Field_Name: "First Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: ["memberFirstName"],
            fieldSetting: {
                placeholder: "",
                allowSpace: true,

            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "memberLastName",
            lable: "Last Name ",
            Field_Name: "Last Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "memberLastName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "email",
            lable: "Email Address",
            Field_Name: "Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "email",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" },{ type: "email" }],
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "source",
            lable: "Source Tag",
            Field_Name: "source",
            fieldType: FormFieldTypes.SELECT,
            dataField: "source",
            fieldSetting: {
                isMultiSelect: true,
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "EDMCheckbox",
            lable: "EDM",
            Field_Name: "EDM",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "EDMCheckbox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-1",
            },
        },
        {
            id: "bulletCheckbox",
            lable: "Bulletin",
            Field_Name: "Bulletin",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "bulletCheckbox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-1",
            },
        },
        {
            id: "newsLetterCheckBox",
            lable: "Newsletter",
            Field_Name: "Newsletter",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "newsLetterCheckBox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-1",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}