import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react';

import CorporateTaskListApi from './services/Corporate/CorporateTaskListApi';
import IndividualTaskListApi from './services/Individual/IndividualTaskListApi';
import ViewAcadmicMemberApi from './services/Academic/ViewAcadmicMemberApi';
import ViewCorporateMemberApi from './services/Corporate/ViewCorporateMemberApi';
import ViewIndividualMemberApi from './services/Individual/ViewIndividualMemberApi';
import commonApi from './services/Common/CommonApi';
import membershipPricingAPI from "./services/PricingServices/MembershipPricingAPI";
import membershipFinancialService from './services/membershipFinancialService/MembershipFinancialServices';
import EmailCampaignApi from './services/EmailCampaign/EmailCampaignApi';
import authapi from './services/AdminServices/authApi';
import authReducer from './slice/authSlice';
import DropdownManagementApi from './services/DropdoenManagement/DropdownManagementApi';
import EmailTemplateManagementApi from './FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi';
import AccountManagementApi from './services/AccountManagement/AccountManagementApi';
import AcademicTask_ListApi from './services/MembershipService/Academic/AcademicTask_ListApi';
import AcademicTaskListApi from './services/Academic/AcademicTaskListApi';
import ReportApi from './services/Reports/ReportsApi';
import EDMApi from './services/EDM/EDMApi';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authapi.reducerPath]: authapi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [CorporateTaskListApi.reducerPath]: CorporateTaskListApi.reducer,
    [IndividualTaskListApi.reducerPath]: IndividualTaskListApi.reducer,
    [AcademicTask_ListApi.reducerPath]: AcademicTask_ListApi.reducer,
    [AcademicTaskListApi.reducerPath]: AcademicTaskListApi.reducer,
    [ViewAcadmicMemberApi.reducerPath]: ViewAcadmicMemberApi.reducer,
    [ViewCorporateMemberApi.reducerPath]: ViewCorporateMemberApi.reducer,
    [ViewIndividualMemberApi.reducerPath]: ViewIndividualMemberApi.reducer,
    [membershipPricingAPI.reducerPath]: membershipPricingAPI.reducer,
    [membershipFinancialService.reducerPath]: membershipFinancialService.reducer,
    [EmailCampaignApi.reducerPath]: EmailCampaignApi.reducer,
    [DropdownManagementApi.reducerPath]: DropdownManagementApi.reducer,
    [EmailTemplateManagementApi.reducerPath]: EmailTemplateManagementApi.reducer,
    [AccountManagementApi.reducerPath]: AccountManagementApi.reducer,
    [AcademicTaskListApi.reducerPath]: AcademicTaskListApi.reducer,
    [ReportApi.reducerPath]: ReportApi.reducer,
    [EDMApi.reducerPath]: EDMApi.reducer

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    authapi.middleware,
    CorporateTaskListApi.middleware,
    IndividualTaskListApi.middleware,
    AcademicTask_ListApi.middleware,
    ViewAcadmicMemberApi.middleware,
    ViewCorporateMemberApi.middleware,
    ViewIndividualMemberApi.middleware,
    commonApi.middleware,
    membershipPricingAPI.middleware,
    membershipFinancialService.middleware,
    commonApi.middleware,
    EmailCampaignApi.middleware,
    DropdownManagementApi.middleware,
    EmailTemplateManagementApi.middleware,
    AccountManagementApi.middleware,
    AcademicTaskListApi.middleware,
    ReportApi.middleware,
    EDMApi.middleware
  )
})
setupListeners(store.dispatch);