import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";

const ViewAcademicMembersFormData = {
    name: "memberShipTaskData",
    initialState: {
        name: "",
        subscriptionEndDate: "",
        statusId: "",
        membershipTypeId: "",
    },
    formFields: [
        {
            id: "name",
            lable: "Name/Membership ID/Email",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "subscriptionEndDate",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "statusId",
            lable: "Status",
            Field_Name: "status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "statusId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [
                    { value: 8, label: "Active" },
                    { value: 16, label: "Lapsed" },
                    { value: 17, label: "Expired" },
                    { value: 18, label: "Cancelled" },
                    { value: 19, label: "Pending" },
                    { value: 20, label: "Withdrawn" },
                    { value: 24, label: "Graduated" },
                    { value: 25, label: "Duplicate" },
                ],
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "membershiptype",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [

                    { value: 11, label: "Affiliate" },
                    { value: 5, label: "Associate" },
                    { value: 12, label: "Fellow" },
                    { value: 1, label: "AVIP" },
                    { value: 2, label: "Ordinary Path 1" },
                    { value: 3, label: "Ordinary Path 2" },
                    { value: 4, label: "Ordinary Path 3" },
                ],
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },

    ],
};

export default ViewAcademicMembersFormData;



export const GridConfig = {
    columns: [
        {
            name: "Membership ID",
            fieldName: "siisIndividualId",
            allowShort: false,
        },
        {
            name: "Name",
            fieldName: "name",
            allowShort: false,
        },
        {
            name: "Email Address",
            fieldName: "businessEmail",
            allowShort: false,
        },
        {
            name: "Subscription End Date",
            fieldName: "subscriptionEndDate",
            allowShort: false,
            colSettings: {
                format: "DD MMM YYYY",
            },
        },
        {
            name: "Membership Type",
            fieldName: "membershipTypeName",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "statusName",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "View", onClickAction: true },
                { name: "Delete", onClickAction: true },
            ],
        },
    ],
};

export const tableData = [
    {
        membershipID: "AISP123",
        nameOfContactPerson: "John Tan ",
        emailAddress: "john@gmail.com",
        subscriptionEndDate: "30 Apr 2025",
        status: "Active",
        isActive: true,
    },
    {
        membershipID: "AISP122",
        nameOfContactPerson: "Elise Yeo",
        emailAddress: "eliseyeo@gmail.com",
        subscriptionEndDate: "30 Apr 2025",
        status: "Active",
        isActive: true,
    },
    {
        membershipID: "AISP121",
        nameOfContactPerson: "James Lim",
        emailAddress: "james@gmail.com",
        subscriptionEndDate: "30 Apr 2025",
        status: "Active",
        isActive: true,
    },

];

