import React, { useEffect, useRef, useState } from 'react'
import FormCreator from '../../components/Forms/FormCreator';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useAddEditEmailTemplateMutation, useLazyGetAllDropdownsForAISP1Query, useLazyGetEmailTemplateByIdQuery  } from '../../app/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi';
import DataLoader from '../../components/ui/dataLoader/DataLoader';
import Buttons from '../../components/ui/button/Buttons';
import SwalAlert from '../../services/swalService/SwalService';
import EditEmailTempData from './data/EditEmailTemp.Data';


const EditEmailTemp = () => {
    const EditEmailTempRef = useRef();
    const navigate = useNavigate();
    const { id } = useParams()

    const { success, error } = SwalAlert()

    const [getEmailTemplateByid,
        {
            isSuccess: isSuccessEmailById,
            isFetching: isFetchingEmailById,
            data: emailByIdResponse
        }] = useLazyGetEmailTemplateByIdQuery();

    const [getAllProjectTypeDropdown, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetAllDropdownsForAISP1Query()

    const [addEditEmailTemplate, {
        isSuccess: isSuccessAddEditEmail,
        isFetching: isFetchingAddEditEmail,
        data: addEditEmailResponse
    }] = useAddEditEmailTemplateMutation()

    useEffect(() => {
        if (!isFetchingAddEditEmail && isSuccessAddEditEmail && addEditEmailResponse) {
            if (addEditEmailResponse.hasError === false) {
                success("Email update Successfully")
            }
            else {
                error(addEditEmailResponse.errorMessage)
            }
        }
    }, [isFetchingAddEditEmail, isSuccessAddEditEmail, addEditEmailResponse]);


    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            let field = EditEmailTempData?.formFields?.find(obj => obj.id === "project")
            field.fieldSetting.options = dropdownResponse?.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);

    useEffect(() => {
        if (!isFetchingEmailById && isSuccessEmailById && emailByIdResponse) {
            let tempInit = EditEmailTempData.initialState;
            tempInit.projectType = emailByIdResponse.projectTypeId;
            tempInit.subject = emailByIdResponse.subject;
            tempInit.emailName = emailByIdResponse.emailName;
            tempInit.body = emailByIdResponse.description;
            tempInit.WhereUsed = emailByIdResponse.whereUsed;


        }
    }, [isFetchingEmailById, isSuccessEmailById, emailByIdResponse]);

    useEffect(() => {
        if (id) { getEmailTemplateByid(id); }
        getAllProjectTypeDropdown();
    }, [])

    const handleaUpdate = () => {
        const formData = EditEmailTempRef.current.getFormData();
        const request = {
            emailTemplateId: emailByIdResponse?.emailTemplateId || 0,
            EmailName: formData.emailName,
            Subject: formData.subject,
            WhereUsed: formData.WhereUsed,
            ProjectTypeId: formData.projectType.value ? formData.projectType.value : formData.projectType,
            Description: formData.body,
            CreatedBy: 115,
            CreatedAppId: 1
        }

        addEditEmailTemplate(request)
    }

    const handleBack = () => { navigate('/emailTemplateManagement') }

    return (
        <>
            {dropdownResponse && emailByIdResponse ?
                <div className='d-flex flex-wrap'>
                    <div className='row'>
                        <FormCreator
                            ref={EditEmailTempRef}
                            {...EditEmailTempData}
                        >
                        </FormCreator>
                    </div>

                </div>
                :
                <DataLoader />
            }

            <div className='d-flex justify-content-between'>
                <div>
                    <Buttons
                        buttonText="Back"
                        buttonTypeClassName="outline-btn"
                        onClick={() => handleBack()}
                    />
                </div>
                <div>
                    <Buttons
                        buttonText="Update"
                        buttonTypeClassName="outline-btn"
                        onClick={() => handleaUpdate()}
                    />
                </div>
            </div>
        </>
    )
}

export default EditEmailTemp