import React from 'react'
import './MembershipPricingTable.scss'
import MembershipPricingTableForm from '../../../features/membership/membershipPricingTable/MembershipPricingTableForm'

const MembershipPricing = () => {
  return (
    <MembershipPricingTableForm />
  )
}

export default MembershipPricing