import IndividualAccountInformationData from "../component/accountInformation/data/IndividualAccountInformation.data";
import { EngagementTrackingNotesData } from "../component/engagementTrackingNotes/data/EngagementTrackingAndNotes.data";
import EdcuationInformationData from "../component/edcuationInformation/data/EdcuationInformation.data";
import { EmploymentInformationData } from "../component/employmentInformation/data/EmploymentInformation.data";
import MembershipApplicationData from "../component/membershipApplication/data/MembershipApplication.data";
import PersonalInformationData from "../component/personalInformation/data/PersonalInformation.data";
import ProfessionalCertificationDataGeneral from "../component/professionalCertification/data/ProfessionalCertification.data";
import ProfessionalCertificationData from "../component/professionalCertificationDetails/data/professionalCertificationDetails.data";
import { OrdinaryPathData } from "../component/forOrdinaryPath3/Data/forOrdinaryPath3.Data";
import OrganisationDetailsData from "../component/organisationDetails/data/OrganisationDetails.data";
import CommunicationData from "../component/communication/data/Communication.data";

export const setViewIndividualMemberDropDown = (apiResponse) => {
    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: dropdownName === "ProfessionCodeDropdown" ? `${item.additionalInfo} - ${item.name}` : item.name,
                    additionalInfo: item.additionalInfo
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "accountStatus": filterDropDownList("Status"),
        "membershipTenure": filterDropDownList("MembershipTenure").filter(obj => obj.value !== 2),
        "contactPersonsDesignation": filterDropDownList("Designation"),
        "membershipType": filterDropDownList("IndividualMembershipType"),
        "nationality": filterDropDownList("Country"),
        "countryOfResidence": filterDropDownList("Country"),
        "excoPositionIdForNominator1": filterDropDownList("EXCOPosition"),
        "excoTermIdForNominator1": filterDropDownList("EXCOTerm"),
        "excoPositionIdForNominator2": filterDropDownList("EXCOPosition"),
        "excoTermIdForNominator2": filterDropDownList("EXCOTerm"),
        "residentStatusId": filterDropDownList("ResidentStatus"),
        "salutationsID": filterDropDownList("Salutation"),
        "raceId": filterDropDownList("Race"),
        "nationality": filterDropDownList("NationalityType"),
        "professionCodeId": filterDropDownList("ProfessionCodeDropdown"),
        "year": filterDropDownList("ProfessionalCertificateYears"),
        "yearOfComplition": filterDropDownList("Year"),
        "edcuationInstitution": filterDropDownList("EducationInstitution"),
        "highestEducationalQualification": filterDropDownList("HighestEducationalQualification"),
        "jobFunctionId": filterDropDownList("JobFunction"),
        "industryCodeId": filterDropDownList("IndustryCode"),
        "jobLevelId": filterDropDownList("JobLevel"),
        "corporateIndustryCodeId": filterDropDownList("CorporateIndustryCode"),
        "tagId": filterDropDownList("TagName"),
        "billingCategory": filterDropDownList("Individual Billing Category"),
        "engagementType": filterDropDownList("EngagementType"),
        "recordedBy": filterDropDownList("RecordedBy")
    };

    const formFields = [
        ...IndividualAccountInformationData.formFields,
        ...MembershipApplicationData.formFields,
        ...PersonalInformationData.formFields,
        ...ProfessionalCertificationDataGeneral.formFields,
        ...ProfessionalCertificationData.formFields,
        ...EdcuationInformationData.formFields,
        ...EmploymentInformationData.formFields,
        ...OrdinaryPathData.formFields,
        ...OrganisationDetailsData.formFields,
        ...CommunicationData.formFields,
        ...EngagementTrackingNotesData.formFields
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
