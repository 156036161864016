import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLoader from "../../components/ui/pageLoader/PageLoader";
import Layout from "../../layouts/Layout";
import ProtectedRoute from "../Routes/ProtectedRoute";
import Authentication from "../../pages/login/Authentication";
import AuthMiddleware from "../../middleware";
import ForgetPassword from "../../pages/forgetPassword/ForgetPassword";
import ResetPassword from "../../pages/resetPassword/ResetPassword";
import ThankYouForSentMailSuccess from "../../pages/forgetPassword/ThankYou";

const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const Login = React.lazy(() => import("../../pages/login/Login"));

const AppRoutes = (props) => {
  return (
    <Router>
      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          <Route
            name="Layout"
            path="/"
            element={
              <AuthMiddleware>
                <Layout componentRoutes={props.componentRoutes}></Layout>
              </AuthMiddleware>
            }
          >
            {props.componentRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                name={"test"}
                element={
                  <AuthMiddleware>
                    <route.component />
                  </AuthMiddleware>
                }
              />
            ))}
          </Route>
          <Route exact name="AuthLogin" path="/AuthLogin/:memberid" element={<Authentication />} />
          <Route exact name="Login" path="/login" element={<Login />} />
          <Route exact name="ForgetPassword" path="/forgetPassword" element={<ForgetPassword />} />
          <Route exact name="ResetPassword" path="/resetPassword/:memberid" element={<ResetPassword />} />
          <Route exact name="ThankYou" path="/resetPassword/ThankYou" element={<ThankYouForSentMailSuccess />} />
          <Route path="*" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
};
export default AppRoutes;
