import { forwardRef, React, useImperativeHandle, useRef } from 'react'
import InstitutionDetailsData from "./data/InstitutionDetails.data";
import FormCreator from '../../../../components/Forms/FormCreator';

const InstitutionDetails = forwardRef(({ formData, handleOnChangeFileUpload, handleChangeFileDownload }, ref) => {

  const InstitutionDetailsFormRef = useRef();

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = InstitutionDetailsFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const handleFormFieldChange = (dataField, value) => {
    if ((dataField === "acraBizFile") && value) {
      handleOnChangeFileUpload(dataField, value)
    }
  };

  // Form action handler to manage different form actions
  const formActionHandler = {
    // CLEAR: handleClear,
    DOWNLOAD: handleChangeFileDownload,
  }
  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={InstitutionDetailsFormRef}
          {...formData}
          onActionChange={formActionHandler}
          onFormFieldValueChange={handleFormFieldChange}
        ></FormCreator>
      </div>
    </div>
  )
}
)
export default InstitutionDetails