import { forwardRef, React, useImperativeHandle, useRef } from 'react'
import MembershipSubscriptionData from "./data/MembershipSubscription.data";
import FormCreator from '../../../../components/Forms/FormCreator';

const MembershipSubscription = forwardRef(({ formData }, ref) => {
  const MembershipSubscriptionFormRef = useRef();
  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = MembershipSubscriptionFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));
  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={MembershipSubscriptionFormRef}
          {...formData}
        ></FormCreator>
      </div>
    </div>

  )
}
)
export default MembershipSubscription