import React from "react";
import "./Login.scss";
import LoginForm from "../../features/login/Login";
import ForgetPasswordForm from "../../features/forgetPassword/ForgetPassword";

const ForgetPassword = () => {
  return (
    <div className="login-section">
      <div className="right-form-section">
        <ForgetPasswordForm />
      </div>
    </div>
  );
};

export default ForgetPassword;
