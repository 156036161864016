import { forwardRef, React, useImperativeHandle, useRef } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';

const OrdinaryPath3 = forwardRef(({ formData, handleOnChangeFileUpload, handleChangeOnFileDownload }, ref) => {
    const OrdinaryPathFormRef = useRef();
  
    const handleFormFieldChange = (dataField, value) => {
      if ((dataField === "uploadEndorsement") && value) {
        handleOnChangeFileUpload(dataField, value)
      }
      else if ((dataField === "uploadEndorsement2") && value) {
        handleOnChangeFileUpload(dataField, value)
      }
    };

    const handleDownload = (dataField, value) => {
      handleChangeOnFileDownload(dataField, value)
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => {
          if (ref?.current) {
            const data = OrdinaryPathFormRef.current.getFormData();
            return data;
          }
          return null;
        }
      }));

      const formActionHandler = {
        DOWNLOAD: handleDownload,
      }

      return (
        <div className="form-field-sec">
          <div className="row gx-5">
            <FormCreator
              ref={OrdinaryPathFormRef}
              onActionChange={formActionHandler}
              {...formData}
              onFormFieldValueChange={handleFormFieldChange}
            ></FormCreator>
          </div>
        </div>
    
      )
    }
    )
    export default OrdinaryPath3;