import React, { useRef, useEffect, useState } from 'react'
import FormCreator from '../../components/Forms/FormCreator.js';
import { CreateAdminAccountFormData } from './data/CreateAdminAccountForm.data.js';
import Buttons from '../../components/ui/button/Buttons.js';
import { useNavigate } from 'react-router-dom';
import DataLoader from '../../components/ui/dataLoader/DataLoader';
// Import API services
import {
    useAddEditAdminDetailsMutation,
    useLazyGetRolesForAdminQuery
} from '../../app/services/AccountManagement/AccountManagementApi.js';
import {
    useStoreBase64ImageMutation,
} from '../../app/services/Common/CommonApi.js';
import SwalAlert from '../../services/swalService/SwalService.js';
import ToastService from '../../services/toastService/ToastService.js';

const CreateAdminAccountForm = (ActionHandler) => {
    const CreateAdminAccountFormRef = useRef();
    const navigate = useNavigate();
    const { success, error } = SwalAlert()
    const [uploadImageName, setUploadImageName] = useState("");

    // Upload image api call
    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: profileImageUpload }
    ] = useStoreBase64ImageMutation();
    // On handle admin account image upload 
    const handleImageUpload = async (data) => {
        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "AdminImage",
        }
        const response = await saveMainImage(saveFileRequest);
        setUploadImageName(response?.data?.fileName);
    }
    // On handle form field change method
    const handleFormFieldChange = (dataField, value) => {
        if ((dataField === "fileUpload") && value) {
            handleImageUpload(value)
        }
    };
    // Get admin account roles api params
    const [getRolesForAdmin, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetRolesForAdminQuery()
    // Get admin account roles method
    useEffect(() => {
        getRolesForAdmin();
    }, [])
    // Add admin account api params
    const [addAdminDetails, {
        isSuccess: isSuccessAddAdmin,
        isFetching: isFetchingAddAdmin,
        data: addAdminResponse
    }] = useAddEditAdminDetailsMutation()
    // On add admin account response
    useEffect(() => {
        if (!isFetchingAddAdmin && isSuccessAddAdmin && addAdminResponse) {
            if (addAdminResponse.hasError === false) {
                success("Account Created Successfully");
                handleBack();
            }
            else {
                error(addAdminResponse.errorMessage)
            }
        }
    }, [isFetchingAddAdmin, isSuccessAddAdmin, addAdminResponse]);
    //Image upload response
    useEffect(() => {
        if (!isLoadingImage && isSuccessImage && profileImageUpload) {
            ToastService.success("Image uploaded successfully !")
        }
    }, [isLoadingImage, isSuccessImage, profileImageUpload,]);
    //on get dropdown response
    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            let field = CreateAdminAccountFormData?.formFields?.find(obj => obj.id === "Role")
            field.fieldSetting.options = dropdownResponse?.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);
    // On add admin account method
    const handleaAdd = () => {
        const formData = CreateAdminAccountFormRef.current.getFormData();
        const request = {
            accountId: 0,
            Name: formData.name,
            LastName: formData.lastName,
            Email: formData.email,
            Password: formData.password,
            RoleId: formData.role.value ? formData.role.value : formData.role,
            fileUpload: uploadImageName,
            CreatedBy: 115,
            CreatedAppId: 1
        }
        addAdminDetails(request)
    }
    // On handle back click method
    const handleBack = () => { navigate('/ViewEditAdminAccount') }

    return (
        <div className="create-admin-accountform">
            <div className="row gx-4 align-items-center card-bg">
                {dropdownResponse ?
                    <FormCreator
                        ref={CreateAdminAccountFormRef}
                        {...CreateAdminAccountFormData}
                        onFormFieldValueChange={handleFormFieldChange}
                    />
                    :
                    <DataLoader />
                }
                <div className="button-part d-flex justify-content-end mt-4">
                    <Buttons
                        buttonTypeClassName="theme-btn"
                        buttonText="Create"
                        onClick={() => handleaAdd()}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateAdminAccountForm