import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";

const ViewAcadmicMemberApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'ViewAcadmicMemberApi',
    endpoints: (builder) => ({
        getAcademicMemberList: builder.mutation({
            query: (details) => ({
                url: '/AcademicMember/GetAcademicMemberList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        // getAllDropdownsForAcademicMembers: builder.query({
        //     query: () => ({
        //         url: encryptQueryString('/Common/GetAllDropdownsForAcademicMembers'),
        //         method: 'GET',
        //     }),
        //     transformResponse: transformSucessResponse,
        //     transformErrorResponse: transformErrorResponse,
        // }),

        getAcadmicMemberDetailById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/AcademicMember/GetAllAcedemicInformationDetailsById?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAcadmicSIISAccountInfo: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/AcademicMember/GetAcademicSIISAccountInformationInformation?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateAcademicMemberById: builder.mutation({
            query: (details) => ({
                url: '/AcademicMember/UpdateAcademicMemberById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
})

export const {
    useGetAcademicMemberListMutation,
    useLazyGetAcadmicMemberDetailByIdQuery,
    useLazyGetAcadmicSIISAccountInfoQuery,
    useUpdateAcademicMemberByIdMutation,
} = ViewAcadmicMemberApi;

export default ViewAcadmicMemberApi;