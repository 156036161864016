import React, { useRef } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';
import OrganisationDetailsData from './data/OrganisationDetails.data'

const OrganisationDetails = () => {
  const OrganisationDetailsFormRef = useRef();

  return (
    <div>
      <div className="row align-items-center card-bg">
        <FormCreator
          ref={OrganisationDetailsFormRef}
          {...OrganisationDetailsData}
        ></FormCreator>
      </div>
    </div>
  )
}

export default OrganisationDetails