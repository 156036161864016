import { forwardRef, React, useImperativeHandle, useRef } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';


const Communication = forwardRef(({ formData, handleNationalityDropdown }, ref) => {
    const communicationFormRef = useRef();
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const data = communicationFormRef.current.getFormData();
                return data;
            }
            return null;
        }
    }));
    // Handle dropdown change
    const handleDropdownChange = (data, dataField) => {
        if (dataField === "nationality") {
            handleNationalityDropdown(data, dataField)
        }
    };

    const formActionHandler = {
        DDL_CHANGED: handleDropdownChange
    }
    return (
        <div className="form-field-sec">
            <div className="row gx-5">
                <FormCreator
                    ref={communicationFormRef}
                    onActionChange={formActionHandler}
                    {...formData}
                ></FormCreator>
            </div>
        </div>
    )
}
)
export default Communication