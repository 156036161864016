import { NavigateButton } from "../../../components/ui/navigateButton/NavigateButton";
import Buttons from "../../../components/ui/button/Buttons";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";
import GenratePDF from "../component/GenratePDF";
import AuditLogForm from "../AuditLogForm";
export const individualMemberTasklistTableConfig = {
    columns: [
        {
            name: "Name",
            fieldName: "fullName",
            allowShort: false,
        },
        {
            name: "Email Address",
            fieldName: "email",
            allowShort: false,
        },
        {
            name: "Membership Type",
            fieldName: "membershipTypeName",
            allowShort: false,
        },
        {
            name: "Transaction Type",
            fieldName: "transactionTypeName",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "statusName",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "View", onClickAction: true },
                { name: "Delete", onClickAction: true },
            ],
        },
    ],
};

export const auditLogTableConfig = {
    columns: [
        {
            name: "Date",
            fieldName: "createdAt",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Name",
            fieldName: "updateByUser",
            allowShort: false,
        },
        {
            name: "Decision",
            fieldName: "decision",
            allowShort: false,
        },
        {
            name: "Internal Notes",
            fieldName: "internalNotes",
            allowShort: false,
        }
    ],
};


const inidividualTasklistFormData = {
    name: "memberShipTaskData",
    initialState: {
        name: "",
        membershipTypeId: "",
        statusId: "",
    },
    formFields: [
        {
            id: "name",
            lable: "Name",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-3",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
        {
            id: "statusId",
            lable: "Status",
            Field_Name: "status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "statusId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            //  validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
    ],
};

export default inidividualTasklistFormData;

export const inidividualFormData = {
    name: "inidividualFormData",
    initialState: {
        "salutationsID": '',
        "fullName": '',
        "lastName": '',
        "contact": '',
        "howWould": '',
        "isGenderMale": "male",
        "nationalityID": '',
        "countryOfResID": '',
        "residentStatus": '',
        "race": '',
        "bwEmailAddress": '',
        "awEmailAddress": '',
        "linkedinProfile": '',
        "billingCategory": '',
        "updatedBy": '',
        // "subscriptionStartDate": '',
        // "subscriptionEndDate": '',
        // "subscriptionLastDate": '',
        "membershipTypeId": '',
        "membershipTenureId": '',
        "isStartMembershipOn": false,
        "startMembershipDate": '',
        "entrenceFee": '',
        "annualFee": '',
        "paymentTerms": '',
        "comment": '',
        "internalNotes": "",
        "currentMembershipTier": "",
        "currentTierPaidAmount": "",
        "newMembershipTier": "",
        "newTierAmountToPaid": "",
        "subscriptionEndDateForUpgrade": "",
        "amountPaing": "",
    },
    formFields: [
        {
            id: "personalInfo",
            lable: "",
            Field_Name: "personalInfo",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "personalInfo",
            fieldSetting: {
                formTitle: "Personal Information",
            },
            style: {
                containerCss: "col-lg-6 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "generatePdf",
            dataField: 'generatePdf',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <GenratePDF type="Individual" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-6 mb-2 d-flex justify-content-end form-tab-header",
            }
        },
        {
            id: "salutationsID",
            lable: "First Name as in NRIC/Passport",
            Field_Name: "Salutations & Full Name",
            fieldType: FormFieldTypes.INPUTGROUPSELECT,
            dataField: ["salutationsID", "fullName"],
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
                options: [],
                isEdit: true,
                // isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "lastName",
            lable: "Last Name as in NRIC/Passport",
            Field_Name: "Last Name as in NRIC/Passport",
            fieldType: FormFieldTypes.INPUT,
            dataField: "lastName",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "contact",
            lable: "Contact Number",
            Field_Name: "Contact Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "contact",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "howWould",
            lable: "How would you like us to address you",
            Field_Name: "How would you like us to address you",
            fieldType: FormFieldTypes.INPUT,
            dataField: "howWould",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "isGenderMale",
            label: "Gender",
            Field_Name: "Gender",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isGenderMale",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Male", value: "male", isDisable: false, optionClass: "", },
                    { label: "Female", value: "female", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },
        {
            id: "nationalityID",
            lable: "Nationality",
            Field_Name: "Nationality",
            fieldType: FormFieldTypes.SELECT,
            dataField: "nationalityID",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "countryOfResID",
            lable: "Country of Residence",
            Field_Name: "Country of Residence",
            fieldType: FormFieldTypes.SELECT,
            dataField: "countryOfResID",
            fieldSetting: {
                placeholder: " ",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "residentStatus",
            lable: "Resident Status",
            Field_Name: "Resident Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "residentStatus",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "race",
            lable: "Race",
            Field_Name: "Race",
            fieldType: FormFieldTypes.SELECT,
            dataField: "race",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "bwEmailAddress",
            lable: "Primary Email Address",
            Field_Name: "Primary Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "bwEmailAddress",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
                isDisable: false,
            },
            validation: [{ type: "require" }, { type: "email" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "awEmailAddress",
            lable: "Alternate Email Address",
            Field_Name: "Alternate Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "awEmailAddress",
            fieldSetting: {
                placeholder: "",
                allowSpace: false,
                isDisable: false,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "linkedinProfile",
            lable: "LinkedIn Profile",
            Field_Name: "LinkedIn Profile",
            fieldType: FormFieldTypes.INPUT,
            dataField: "linkedinProfile",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "navigatePath",
            dataField: 'navigatePath',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <NavigateButton name={"View Individual Information"} path={'/editIndividualMembers'} navStyle="theme-btn" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-end",
            }
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "individualMemberShip",
            lable: "",
            Field_Name: "individualMemberShip",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "individualMemberShip",
            fieldSetting: {
                formTitle: "Individual Membership Subscription",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },

        {
            id: "billingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "billingCategory",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "updatedBy",
            lable: "Updated By",
            Field_Name: "Updated By",
            fieldType: FormFieldTypes.INPUT,
            dataField: "updatedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "subscriptionStartDate",
            lable: "Subscription Start Date",
            Field_Name: "Subscription Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionStartDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            id: "subscriptionEndDate",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true

            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            id: "subscriptionLastDate",
            lable: "Subscription Last Date",
            Field_Name: "Subscription Last Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionLastDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true

            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "pricingTable",
            lable: "pricingTable",
            Field_Name: "pricingTable",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "pricingTable",
            fieldSetting: {
                formTitle: "Pricing Table",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },

        {
            id: "isStartMembershipOn",
            lable: "Start Membership On",
            Field_Name: "Start Membership On",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isStartMembershipOn",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-2 mt-4 text-nowrap ",
            },
        },
        {
            id: "startMembershipDate",
            lable: "   ",
            Field_Name: "StartMembership Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "startMembershipDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,
            },
            style: {
                containerCss: "col-lg-3 mb-3 ",
            },
        },
        {
            id: "br1",
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-3 ",
            }
        },

        {
            id: "paymentTerms",
            lable: "Payment Terms",
            Field_Name: "Payment Terms",
            fieldType: FormFieldTypes.SELECT,
            dataField: "paymentTerms",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },
        {
            id: "br2",
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-8 ",
            }
        },
        {
            id: "membershipTenureId",
            lable: "Membership Tenure",
            Field_Name: "Membership Tenure",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTenureId",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3 "
            }
        },
        {
            id: "br3",
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-8",
            }
        },
        {
            id: "entrenceFee",
            lable: "Entrance Fee",
            Field_Name: "Entrance Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "entrenceFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: false
            },
            style: {
                containerCss: "col-md-2 mb-3",
            },
        },
        {
            id: "annualFee",
            lable: "Annual Fee",
            Field_Name: "Annual Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "annualFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: false
            },
            style: {
                containerCss: "col-md-2 mb-3",
            },
        },
        {
            id: "seprator2",
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "auditLog",
            lable: "",
            Field_Name: "auditLog",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "auditLog",
            fieldSetting: {
                formTitle: "Audit Log",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header ",
            },
        },
        {
            id: "auditLogComponent",
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <AuditLogForm />,
            style: {
                containerCss: "separation-line pl-0 pr-0 ",
            }
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "invoiceGeneration",
            lable: "",
            Field_Name: "invoiceGeneration",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "invoiceGeneration",
            fieldSetting: {
                formTitle: "Invoice Generation",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "comment",
            lable: "Comments to member",
            Field_Name: "Comments to member",
            fieldType: FormFieldTypes.INPUT,
            dataField: "comment",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },
        //  Upgrade Details
        {
            id: "upgradeSeperator",
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0 d-none",
            },
        },
        {
            id: "upgradeMembershipDetails",
            lable: "",
            Field_Name: "upgrade Membership Details",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "upgradeDetails",
            fieldSetting: {
                formTitle: "Upgrade Details",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header d-none ",
            },
        },
        {
            id: "currentMembershipTier",
            lable: "Current Membership Tier",
            Field_Name: "Current Membership Tier",
            fieldType: FormFieldTypes.SELECT,
            dataField: "currentMembershipTier",
            fieldSetting: {
                allowSpace: true,
                isDisable: true,
                options: []
            },
            style: {
                containerCss: "col-lg-6 mb-3  d-none",
            },
        },
        {
            id: "currentTierApplicationFee",
            lable: "Current Tier Application Fee",
            Field_Name: "Current Tier Application Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "currentTierApplicationFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-md-3 mb-3  d-none",
            },
        },
        {
            id: "currentTierAnnualFee",
            lable: "Current Tier Annual Fee",
            Field_Name: "Current Tier Annual Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "currentTierAnnualFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-md-3 mb-3 d-none",
            },
        },
        {
            id: "newMembershipTier",
            lable: "New Membership Tier",
            Field_Name: "New Membership Tier",
            fieldType: FormFieldTypes.SELECT,
            dataField: "newMembershipTier",
            fieldSetting: {
                allowSpace: true,
                isDisable: true,
                options: []

            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none ",
            },
        },
        {
            id: "newTierApplicationFee",
            lable: "New Tier Application Fee",
            Field_Name: "New Tier Application Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "newTierApplicationFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-md-3 mb-3 d-none",
            },
        },
        {
            id: "newTierAnnualFee",
            lable: "New Tier Annual Fee",
            Field_Name: "New Tier Annual Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "newTierAnnualFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-md-3 mb-3 d-none",
            },
        },
        {
            id: "subscriptionEndDateForUpgrade",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDateForUpgrade",
            fieldSetting: {
                placeholder: "",
                allowSpace: true
            },
            style: {
                containerCss: "col-lg-6 mb-3 d-none",
            },
        },
        {
            id: "applicationFeePaing",
            lable: "Application Fee Amount",
            Field_Name: "Application Amount",
            fieldType: FormFieldTypes.INPUT,
            dataField: "applicationFeePaing",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                //isDisable: true
            },
            style: {
                containerCss: "col-md-3 mb-3 d-none",
            },
        },
        {
            id: "annualFeePaing",
            lable: "Annual Fee Amount",
            Field_Name: "Annual Amount",
            fieldType: FormFieldTypes.INPUT,
            dataField: "annualFeePaing",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                //isDisable: true
            },
            style: {
                containerCss: "col-md-3 mb-3 d-none",
            },
        },
    ],
};