import React, { useRef, useEffect, useState } from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import loginFormData from "./ForgetPasswordForm.data";
import forgetPasswordFormData from "./ForgetPasswordForm.data";
import { useNavigate } from "react-router-dom";
import Image from "../../components/image/Image";
import { AppIcons } from "../../data/appIcons";
import SwalAlert from "../../services/swalService/SwalService";
import { useDispatch, useSelector } from "react-redux";
import ToastService from "../../services/toastService/ToastService";
import { authentication } from "../../app/slice/authSlice";
import { useForgotPasswordWebMutation } from "../../app/services/AdminServices/authApi";
import { useRoutes } from "react-router-dom";

const ForgetPasswordForm = (props) => {
  const forgetPasswordFromRef = useRef();
  const { isLogedin } = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Declare reset password sent mail API
  const [resetPasswordLinkSent, { isLoading: isLoading, isSuccess: isSuccess, data: resetPasswordResponse }] = useForgotPasswordWebMutation();

  useEffect(() => {
    if (!isLoading && isSuccess && resetPasswordResponse || resetPasswordResponse === null) {
      if (resetPasswordResponse === null) {
        ToastService.error("Member details not exist");
      }
      else {
        navigate('/resetPassword/ThankYou');
      }
    }
  }, [isLoading, isSuccess, resetPasswordResponse]);

  const handleResetPasswordSentLink = () => {
    // Retrieve form data
    const formData = forgetPasswordFromRef.current?.getFormData();

    // Check if form data exists
    if (!formData) return;

    // Create the request object
    const request = {
      Email: formData?.emailAddress || null,
      IsAdmin: true,
      IsCorporate: false,
      IsAcademic: false,
      CompanyUEN: null,
    };

    // Proceed with the login if form data exists
    resetPasswordLinkSent(request);
  };

  const handleFormDataChange = (formData) => { };

  // useEffect(() => {
  //   if (!loginLoading && loginSuccess && authData) {
  //     // if (authData.isAuthenticated === false) {
  //     //   ToastService.error(authData.message);
  //     // } else if (authData.isAuthenticated === true) {
  //     //   saveData("isPIC1", authData.isPIC1);
  //     //   saveData("pic1Name", authData.name);
  //     //   saveData("authData", authData);
  //     //   ToastService.success("You are login successfully", "Success");
  //     //   dispatch(authentication(authData));
  //     // }
  //   }
  // }, [loginLoading, loginSuccess, authData]);


  // Effect to handle navigation based on the authentication


  return (
    <div className="login-form-section">
      <div className="logo-section">
        <Image
          imagePath={AppIcons.logoImage}
          imgCustomClassName="open-bar"
          altText="Icon"
        ></Image>
      </div>
      <h2>Reset Password</h2>
      <div className="row">
        <FormCreator
          ref={forgetPasswordFromRef}
          {...forgetPasswordFormData}
          onFormDataUpdate={handleFormDataChange}
        ></FormCreator>
      </div>
      <div className="button-part">
        <Buttons
          buttonTypeClassName="theme-btn"
          buttonText="Reset your password"
          onClick={handleResetPasswordSentLink}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ForgetPasswordForm;
