import { FormFieldTypes } from "../../../../../data/formFieldType"
import { GridColumnType } from "../../../../../data/gridColumnType"

export const professionalTableConfig = {
    columns: [
        {
            name: "Professional Certificates",
            fieldName: "professionalCertificationName",
            allowShort: false
        },
        {
            name: "Year",
            fieldName: "yearName",
            allowShort: false
        },
        {
            name: "Certificates",
            fieldName: "professionalInformationSecurityCertificatesFileName",
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
                allowDownload: true,
            }

        },
    ]
}
export const ProfessionalCertificationData = {
    name: 'Professional',
    initialState: {
        professionalCertifications: '',
        professionalInformationFile: '',
        year: null,
    },
    formFields: [
        // {
        //     id: "professionalCertificationTitle",
        //     lable: "",
        //     Field_Name: "professional Certification",
        //     fieldType: FormFieldTypes.MAINFORMTITLE,
        //     dataField: "professionalCertificationTitle",
        //     fieldSetting: {
        //         formTitle: "Professional Certification (Details)",
        //     },
        //     style: {
        //         containerCss: "col-lg-12 mb-2 text-left",
        //     },
        // },
        {
            id: "professionalInfoTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "professionalInfoTable",
            fieldSetting: {
                header: professionalTableConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-left",
            },
        },
        {
            id: "professionalCertifications",
            lable: "Professional Certifications",
            Field_Name: "Professional Certifications",
            fieldType: FormFieldTypes.INPUT,
            dataField: "professionalCertifications",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "year",
            lable: "Year",
            Field_Name: "Year",
            fieldType: FormFieldTypes.SELECT,
            dataField: "year",
            fieldSetting: {
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "professionalInformationFile",
            lable: 'Professional Information Security Certificates (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'Professional Information Security Certificates (please upload in pdf/jpg format max 2mb)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'professionalInformationFile',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                // isDownloadable: false,
                acceptedFiles: '.pdf',
                imageCrop: false,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}


export default ProfessionalCertificationData