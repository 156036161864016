
import React from 'react';
import Image from '../../components/image/Image';
import { AppIcons } from '../../data/appIcons';
import { Link } from 'react-router-dom';

const ThankYouForSentMailSuccess = () => {
    return (
        <div className='container'>
            <div className='row d-flex justify-content-center vh-100'>
                <div className='col-lg-4 my-5'>
                    <div className="card border-0 text-center p-4" style={{ maxWidth: '400px' }}>
                        <div className="mb-4">
                            <Image
                                alt="Thank You"
                                imgCustomClassName="main-logo-sec"
                                imagePath={AppIcons.TickIcon.src}
                            />
                        </div>
                        <h3>Thank you!</h3>
                        <p>
                            An email with a password reset link has been sent to your registered email address. Please follow the provided instructions.
                        </p>
                        <div className="d-flex justify-content-center mt-4">
                            <Link className='nav-link text-decoration-underline' to={'/login'}>Back to Login </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ThankYouForSentMailSuccess