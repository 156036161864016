import { NavigateButton } from "../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../data/formFieldType";


const loginFormData = {
    name: "Login From",
    initialState: {
        emailAddress: "",
        password: "",
        rememberMe: "",
        navigatePath: "",
    },
    formFields: [
        {
            id: "emailAddress",
            lable: "",
            Field_Name: "email address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "emailAddress",
            fieldSetting: {
                placeholder: "Email Address",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
        {
            id: "password",
            lable: "",
            Field_Name: "password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "password",
            fieldSetting: {
                placeholder: "Password",
            },
            style: {
                containerCss: "col-md-12"
            },
            validation: [{ type: "require" }]
        },
        // {
        //     id: "rememberMe",
        //     lable: "Remember me",
        //     Field_Name: "Remember me",
        //     fieldType: FormFieldTypes.CHECKBOX,
        //     dataField: "rememberMe",
        //     fieldSetting: {
        //         placeholder: "",
        //         allowSpace: true,
        //     },
        //     style: {
        //         containerCss: "col-lg-5 col-sm-5 col-md-5 col-5 mt-4 text-nowrap",
        //     },
        // },
        {
            id: "navigatePath",
            dataField: 'navigatePath',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <NavigateButton name={"Forgot your password?"} path={'/forgetPassword'} navStyle="btn-link" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-12 col-sm-12 col-md-12 col-12 mt-4 text-end",
            }
        },

    ],
};
export default loginFormData;

export const authanticationFormData = {
    name: "Authantication From",
    initialState: {
        otp: "",
    },
    formFields: [
        {
            id: "otp",
            lable: "",
            Field_Name: "OTP",
            fieldType: FormFieldTypes.INPUT,
            dataField: "otp",
            fieldSetting: {
                placeholder: "Enter Your OTP",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
    ],
};