
export const setFieldParameter=(apiResponse,data) => {
    let formData={ ...data }
    const safeSplit=(value,from=',') => {
        return (typeof value==='string'&&value.trim()!=='')
            ? value.split(from).map(item => Number(item.trim()))
            :[];
    };
    formData.initialState.memberFirstName=apiResponse?.memberFirstName;
    formData.initialState.memberLastName=apiResponse?.memberLastName;
    formData.initialState.email=apiResponse?.email;
    formData.initialState.source=safeSplit(apiResponse?.sourceTag);
    formData.initialState.EDMCheckbox=apiResponse?.edm;
    formData.initialState.bulletCheckbox=apiResponse?.bulletin;
    formData.initialState.newsLetterCheckBox=apiResponse?.newsLetter;

    return formData;
};

