import React from 'react'

const AdditionalComponent = (props) => {
    return (
        <div className={props.containerCss}>
            {React.cloneElement(props?.content, { identifyNumber: props?.identifyNumber })}
        </div>
    )
}

export default AdditionalComponent