import { enc } from "crypto-js";
import { encryptAES } from "../../services/CryptoService";


// const isEncryptionEnabled = process.env.REACT_APP_IsEncryption;

const isEncryptionEnabled = true;

export const transformRequest = (reqData) => {
  var data = { data: (isEncryptionEnabled) ? encryptAES(reqData, 1) : reqData };
  return data;
}


export const encryptQueryString = (url) => {

  if (isEncryptionEnabled) {
    const urlParts = url.split("=");
    if (urlParts.length === 2) {
      const queryString = urlParts[1];
      const encryptedQueryString = encryptAES(queryString, 1); // Replace with your encryption logic
      return `${urlParts[0]}=${encryptedQueryString}`;
    }
  }
  // If the URL doesn't contain query parameters or is in an invalid format, return the original URL.
  return url;
}
