import { GridColumnType } from "../../../data/gridColumnType";

export const GridConfig = {
    columns: [
        {
            name: "Name",
            fieldName: "name",
            allowShort: false,
        },
        {
            name: "	Email",
            fieldName: "email",
            allowShort: false,
        },
        {
            name: "Image",
            fieldName: "profilePic",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }
        },
    ],
};

export const tableData = [
    {
        name: "Corporate",
        email: "itsupport@flyingcape.com.sg",
        image: "image",
        emailWhereUsed: "Invoice use for membership task list process",
    },
    {
        name: "Corporate",
        email: "itsupport@flyingcape.com.sg",
        image: "image",
        emailWhereUsed: "Invoice use for membership task list process",
    },
    {
        name: "Corporate",
        email: "itsupport@flyingcape.com.sg",
        image: "image",
        emailWhereUsed: "Invoice use for membership task list process",
    },
]