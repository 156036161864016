import { forwardRef, React, useImperativeHandle, useRef, useState } from 'react'
import AccountInformationData from "./data/AccountInformation.data";
import FormCreator from '../../../../components/Forms/FormCreator';
import Buttons from '../../../../components/ui/button/Buttons';
import CenterModel from '../../../../components/ui/centerModel/CenterModel';
import SuperUserLoginModal from '../../../viewCorporateMember/component/SuperUserLoginModal';

const AccountInformation = forwardRef(({ formData, uenNumber }, ref) => {
  const accountInformationFormRef = useRef();
  const [isModalOpenUser, setIsModalOpenUser] = useState(false);


  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = accountInformationFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const handleOpenSuperUserModal = () => {
    setIsModalOpenUser(!isModalOpenUser);
  };

  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={accountInformationFormRef}
          {...formData}
        ></FormCreator>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <div className="mt-4">
            <Buttons
              buttonText="Login to Super User"
              buttonTypeClassName="theme-btn "
              onClick={handleOpenSuperUserModal}
            />
          </div>
        </div>
      </div>
      {
        isModalOpenUser && (
          <CenterModel
            modelTitle={"Super login"}
            show={"test"}
            handleClose={handleOpenSuperUserModal}
          >
            <>
              <SuperUserLoginModal
                uenNumber={uenNumber}
                isCorporate={false}
                handleOpenLoginModal={handleOpenSuperUserModal}
              />
            </>
          </CenterModel>
        )
      }
    </div>

  )
}
)
export default AccountInformation