import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import extractInvoiceFormData, { extractInvicesTableConfig } from "./data/ExtractInvoiceTable.Data";
import MolGrid from "../../components/Grid/MolGrid";
import formatDate from "../../lib/formatDate";
import { saveAs } from "file-saver";
// Import API sservice
import SwalAlert from "../../services/swalService/SwalService";
import ToastService from "../../services/toastService/ToastService";
import { useLazyGetPDFQuery } from "../../app/services/Common/CommonApi";
import {
  useGetAllExtractInvoicesListMutation,
  useZipInvoicesAsyncMutation,
  useLazyFinanceGetInvoiceQuery
} from "../../app/services/membershipFinancialService/MembershipFinancialServices";

const ExtractInvoiceForm = forwardRef((props, ref) => {
  const [formData, SetFormData] = useState(
    extractInvoiceFormData
  );
  const formRef = useRef();
  const molGridRef = useRef();
  const { success, error, warning } = SwalAlert();
  const [
    extractinvoicesList,
    setExtractinvoicesList,
  ] = useState([]);
  const [paginationRequest, setPaginationRequest] = useState({
    PageNo: 1,
    PageSize: 10,
    OrderByColumn: "",
    OrderFlag: 0,
    DateFrom: "",
    DateTo: "",
    FilterbyInvoiceNumber: "",
    totalCount: 0

  });


  const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();

  // Extract invoices API call
  const [
    extractInvoicesList,
    {
      isSuccess: isSuccessList,
      isLoading: isLoadingList,
      data: extractInvoicesListResponse,
    },
  ] = useGetAllExtractInvoicesListMutation();


  const [
    financeGetInvoice,
    {
      isSuccess: isSuccessfinanceGetInvoice,
      isLoading: isLoadingfinanceGetInvoice,
      data: financeGetInvoiceResponse,
    },
  ] = useLazyFinanceGetInvoiceQuery();

  // Make zip API call
  const [
    extractInvoicesZip,
    {
      isSuccess: isSuccessZip,
      isLoading: isLoadingZip,
      data: extractInvoicesZipResponse,
    },
  ] = useZipInvoicesAsyncMutation();


  useEffect(() => {
    if (!isLoadingList && isSuccessList && extractInvoicesListResponse) {
      debugger
      let finalExtractInvoiceList = extractInvoicesListResponse.itemList.map(
        (obj) => {
          return {
            ...obj,
            invoiceDate: formatDate(obj?.invoiceDate, "DD/MM/YYYY"),
          };
        }
      );
      setPaginationRequest({
        ...paginationRequest,
        totalCount: extractInvoicesListResponse.totalCount,
      });
      setExtractinvoicesList(finalExtractInvoiceList);
    }
  }, [isLoadingList, isSuccessList, extractInvoicesListResponse]);

  useEffect(() => {
    if (!isLoadingGetPDF && isSuccessGetPDF && getPDFData) {
      if (getPDFData) {
        saveAs(getPDFData?.fileData, getPDFData?.fileName);
      } else {
        ToastService.error("Something Went Wrong")
      }
    }
  }, [isLoadingGetPDF, isSuccessGetPDF, getPDFData]);

  useEffect(() => {
    if (!isLoadingZip && isSuccessZip && extractInvoicesZipResponse) {
      if (extractInvoicesZipResponse) {
        financeGetInvoice(extractInvoicesZipResponse)
      } else {
        ToastService.error("Something Went Wrong")
      }
    }
  }, [isLoadingZip, isSuccessZip, extractInvoicesZipResponse]);


  useEffect(() => {
    if (!isLoadingfinanceGetInvoice && isSuccessfinanceGetInvoice && financeGetInvoiceResponse) {
      const now = new Date();
      const formattedDate = `${String(now.getDate()).padStart(2, '0')}${String(now.getMonth() + 1).padStart(2, '0')}${now.getFullYear()}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;

      const fileName = `Membership_Invoice_${formattedDate}_${formattedTime}.zip`;

      saveAs(financeGetInvoiceResponse.fileData, fileName);
    }
  }, [isLoadingfinanceGetInvoice, isSuccessfinanceGetInvoice, financeGetInvoiceResponse]);

  // Extract invoices list method 
  const getExtractInvoicesList = (flag) => {
    const searchFormData = formRef.current.getFormData();
    if (searchFormData) {
      const request = {
        PageNo: 1,
        PageSize: 10,
        OrderByColumn: "",
        OrderFlag: 0,
        DateFrom: searchFormData?.dateFrom || null,
        DateTo: searchFormData?.dateTo || null,
        FilterbyInvoiceNumber: searchFormData?.invoiceNo,

      };
      // setPaginationRequest(request);
      extractInvoicesList(request);
    }
    else {
      error("Fill in all required fields");
    }

  };
  // Zip invoices list method 
  // const getZipInvoices = (flag) => {
  //   debugger
  //   const searchFormData = formRef.current.getFormData();
  //   let pagination = {
  //     PageNo: 1,
  //     PageSize: 10,
  //     OrderByColumn: "",
  //     OrderFlag: 0,
  //     DateFrom: searchFormData.dateFrom,
  //     DateTo: searchFormData.dateTo,
  //     FilterbyInvoiceNumber: searchFormData.invoiceNo,
  //   }
  //   const request = {
  //     Pagination: pagination,
  //     ExtractInvoicesList: extractinvoicesList.map(invoice => ({
  //       ...invoice,
  //       invoiceDate: convertToDate(invoice.invoiceDate),
  //     })),

  //   };
  //   extractInvoicesZip(request);
  // };

  const getZipInvoices = (flag) => {
    const searchFormData = formRef.current.getFormData();
    const request = {
      DateFrom: searchFormData?.dateFrom || null,
      DateTo: searchFormData?.dateTo || null,
    };
    extractInvoicesZip(request);
  };

  function convertToDate(dateStr) {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  const handlePageChange = (newPage) => {
    const searchFormData = formRef.current.getFormData();
    setPaginationRequest((previous) => {
      const tempPagination = {
        pageNo: newPage.pageNumber,
        pageSize: newPage.pageSize,
        OrderByColumn: "",
        OrderFlag: 0,
        DateFrom: searchFormData.dateFrom,
        DateTo: searchFormData.dateTo,
        FilterbyInvoiceNumber: searchFormData.invoiceNo,
      };
      extractInvoicesList(tempPagination)
      return tempPagination;
    });
  };

  const handleViewInvoice = async (flag, name) => {
    const fileName = name.split('\\').pop();
    let request = {
      type: flag,
      fileName: fileName,
    }
    getPDF(request);
  }
  const handleViewReceipt = async (flag, name) => {
    const fileName = name.split('\\').pop();
    let request = {
      type: flag,
      fileName: fileName,
    }
    getPDF(request);
  }

  const handleCustomActionClick = (rowData, buttonName) => {
    if (buttonName === "View Invoice") {
      handleViewInvoice("MembershipInvoice", rowData.invoicePath);
    } else {
      handleViewReceipt("MembershipPaymentReceipt", rowData.receiptPath);
    }
  };

  const actionHandler = {
    "CUSTOM_ACTION_CLICK": handleCustomActionClick
  }
  return (
    <React.Fragment>
      <div className="row gx-5 bg-light tasklist-form">
        <FormCreator ref={formRef} {...formData} />
        <div className="col-lg-2 col-md-4 mb-1 d-flex justify-content-start align-items-center mb-3">
          <div className="">
            <div className="custom-button payment">
              <Buttons
                buttonText="Search"
                buttonTypeClassName="theme-btn "
                onClick={getExtractInvoicesList}
              />
              <Buttons
                buttonText="Create Zip"
                buttonTypeClassName="theme-btn "
                onClick={getZipInvoices}
              />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-span-12">
            <div className="card-section mt-4 overflow-hidden">
              <MolGrid
                ref={molGridRef}
                configuration={extractInvicesTableConfig}
                dataSource={extractinvoicesList}
                allowPagination={true}
                // isLoading={isLoadingList}
                onPageChange={handlePageChange}
                pagination={paginationRequest}
                onActionChange={actionHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
export default ExtractInvoiceForm;