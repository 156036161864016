import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";

export const extractInvicesTableConfig = {
    columns: [
        {
            name: "Invoice Number",
            fieldName: "invoiceNo",
            allowShort: false,
        },
        {
            name: "Invoice Date",
            fieldName: "invoiceDate",
            allowShort: false,
        },
        {
            name: "Invoice Status",
            fieldName: "invoiceStatus",
            allowShort: false,
        },
        // {
        //     name: "Invoice Type",
        //     fieldName: "invoiceType",
        //     allowShort: false,
        // },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "View Invoice", onClickAction: true },
                { name: "View Receipt", onClickAction: true },
            ],
        },
    ],
};


const extractInvoiceFormData = {
    name: "paymentTaskData",
    initialState: {
        invoiceNo: "",
        dateFrom: "",
        dateTo: ""
    },
    formFields: [
        {
            id: "invoiceNo",
            lable: "Invoice Number",
            Field_Name: "Invoice Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "invoiceNo",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3",
            },
        },
        // {
        //     id: "invoiceType",
        //     lable: "Invoice Type",
        //     Field_Name: "Invoice Type",
        //     fieldType: FormFieldTypes.SELECT,
        //     dataField: "invoiceType",
        //     fieldSetting: {
        //         placeholder: "",
        //         allowSpace: true,
        //         options: [
        //             { value: 3, label: "Membership" },                   
        //         ]
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "col-md-3 mb-3"
        //     }
        // },
        {
            id: "dateFrom",
            lable: "Date From",
            Field_Name: "Date From",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateFrom",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                dateFormat: "dd/MM/yyyy",
                //useMaxDate: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3"
            }
        },
        {
            id: "dateTo",
            lable: "Date To",
            Field_Name: "Date To",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateTo",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                // options: [],
                //useMaxDate: true
                dateFormat: "dd/MM/yyyy",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3"
            }
        },
    ],
};
export default extractInvoiceFormData;

