import { React, useRef, useEffect, useState } from 'react';
import FormCreator from '../../components/Forms/FormCreator.js';
import { EditAdminAccountData } from './data/EditAdminAccountForm.data.js';
import Buttons from '../../components/ui/button/Buttons.js';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// Import API services
import {
    useLazyGetAdminDetailByIdQuery,
    useLazyGetRolesForAdminQuery,
    useAddEditAdminDetailsMutation
} from '../../app/services/AccountManagement/AccountManagementApi.js';
import {
    useStoreBase64ImageMutation,
} from '../../app/services/Common/CommonApi.js';
import SwalAlert from '../../services/swalService/SwalService';
import ToastService from '../../services/toastService/ToastService.js';

const EditAdminAccountForm = () => {
    const [uploadImageName, setUploadImageName] = useState("");
    const EditAdminAccountFormFormRef = useRef();
    const { success, error } = SwalAlert();
    const navigate = useNavigate();
    const { id } = useParams();

    // Get admin account details by id api call
    const [getAdminDetailById,
        {
            isSuccess: isSuccessAccountById,
            isFetching: isFetchingAccountById,
            data: accountByIdResponse
        }] = useLazyGetAdminDetailByIdQuery();
    // Get admin roles dropdown api call
    const [getRolesForAdmin, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetRolesForAdminQuery();
    // Update admin account detail api call
    const [UpdateAdminDetails, {
        isSuccess: isSuccessAddAdmin,
        isFetching: isFetchingAddAdmin,
        data: addAdminResponse
    }] = useAddEditAdminDetailsMutation();
    // Upload image api call
    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: profileImageUpload }
    ] = useStoreBase64ImageMutation();
    // Get admin role api response
    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            if (id) {
                getAdminDetailById(id);
            }
            let field = EditAdminAccountData?.formFields?.find(obj => obj.id === "Role")
            field.fieldSetting.options = dropdownResponse?.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);
    // Get admin account details by id api response
    useEffect(() => {
        if (!isFetchingAccountById && isSuccessAccountById && accountByIdResponse) {
            let tempInit = EditAdminAccountData.initialState;
            tempInit.firstName = accountByIdResponse.name;
            tempInit.lastName = accountByIdResponse.lastName;
            tempInit.email = accountByIdResponse.email;
            tempInit.password = accountByIdResponse.password;
            tempInit.role = accountByIdResponse.roleId;
            tempInit.fileUpload = accountByIdResponse.profilePic;
            tempInit.isAdminQRScan = accountByIdResponse.isQRScan;

            setUploadImageName(accountByIdResponse.profilePic);
        }
    }, [isFetchingAccountById, isSuccessAccountById, accountByIdResponse]);
    // Call admin details and admin roles by id method
    useEffect(() => {
        getRolesForAdmin();
    }, [])
    // Update admin account details response
    useEffect(() => {
        if (!isFetchingAddAdmin && isSuccessAddAdmin && addAdminResponse) {
            if (addAdminResponse.hasError === false) {
                success("Account Updated Successfully");
                handleBack();
            }
            else {
                error(addAdminResponse.errorMessage)
            }
        }
    }, [isFetchingAddAdmin, isSuccessAddAdmin, addAdminResponse]);
    //Image upload response
    useEffect(() => {
        if (!isLoadingImage && isSuccessImage && profileImageUpload) {
            ToastService.success("Image uploaded successfully !")
        }
    }, [isLoadingImage, isSuccessImage, profileImageUpload,]);
    // On handle admin accont details method
    const handleaUpdate = () => {
        const formData = EditAdminAccountFormFormRef.current.getFormData();
        const request = {
            adminID: accountByIdResponse?.adminID || 0,
            Name: formData.firstName,
            LastName: formData.lastName,
            Email: formData.email,
            RoleId: formData.role.value ? formData.role.value : formData.role,
            fileUpload: uploadImageName,
            IsQRScan: formData.isAdminQRScan,
            CreatedBy: 115,
            CreatedAppId: 1
        }
        UpdateAdminDetails(request)
    }
    // On handle admin account image upload 
    const handleImageUpload = async (data) => {
        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "AdminImage",
        }
        const response = await saveMainImage(saveFileRequest);
        setUploadImageName(response?.data?.fileName);
    }
    // On handle form field change method
    const handleFormFieldChange = (dataField, value) => {
        if ((dataField === "fileUpload") && value) {
            handleImageUpload(value)
        }
    };
    // On handle back click
    const handleBack = () => { navigate('/ViewEditAdminAccount') }

    return (
        <div className="create-admin-accountform">
            <div className="row gx-4 align-items-center card-bg">
                <FormCreator
                    ref={EditAdminAccountFormFormRef}
                    {...EditAdminAccountData}
                    onFormFieldValueChange={handleFormFieldChange}
                ></FormCreator>
                <div className="button-part d-flex justify-content-end mt-4">
                    <Buttons
                        buttonTypeClassName="theme-btn"
                        buttonText="Update"
                        onClick={() => handleaUpdate()}
                    />
                </div>
            </div>
        </div>
    )
}
export default EditAdminAccountForm