import { FormFieldTypes } from "../../../../../data/formFieldType";

export const OrdinaryPathData = {
    name: "OrdinaryPathData",
    initialState: {
        nameofNominator1: "",
        membershipNumberForNominator1: "",
        excoPositionIdForNominator1: "",
        excoTermIdForNominator1: "",
        uploadEndorsement: "",
        nameofNominator2: "",
        membershipNumberForNominator2: "",
        excoPositionIdForNominator2: "",
        excoTermIdForNominator2: "",
        uploadEndorsement2: "",
    },
    formFields: [
        {
            id: "nameofNominator1",
            lable: "Name of Nominator 1",
            Field_Name: "Name of Nominator 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameofNominator1",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  ",
            },
        },
        {
            id: "membershipNumberForNominator1",
            lable: "AISP Membership Number",
            Field_Name: "AISP Membership Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "membershipNumberForNominator1",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  ",
            },
        },
        {
            id: "excoPositionIdForNominator1",
            lable: "EXCO Position",
            Field_Name: "EXCO Position",
            fieldType: FormFieldTypes.SELECT,
            dataField: "excoPositionIdForNominator1",
            fieldSetting: {
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },

        {
            id: "excoTermIdForNominator1",
            lable: "EXCO Term",
            Field_Name: "EXCO Term",
            fieldType: FormFieldTypes.INPUT,
            dataField: "excoTermIdForNominator1",
            fieldSetting: {
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
        {
            id: "uploadEndorsement",
            lable: 'Upload Endorsement with Signature (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'Upload Endorsement with Signature (please upload in pdf/jpg format max 2mb)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadEndorsement',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
                // imageCrop: true,
            },
            // validation: [],
            style: {
                containerCss: "col-lg-12 col-md-12 mb-12"
            }
        },
        {
            id: "nameofNominator2",
            lable: "Name of Nominator 2",
            Field_Name: "Name of Nominator 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameofNominator2",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  ",
            },
        },
        {
            id: "membershipNumberForNominator2",
            lable: "AISP Membership Number",
            Field_Name: "AISP Membership Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "membershipNumberForNominator2",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  ",
            },
        },

        {
            id: "excoPositionIdForNominator2",
            lable: "EXCO Position",
            Field_Name: "EXCO Position",
            fieldType: FormFieldTypes.SELECT,
            dataField: "excoPositionIdForNominator2",
            fieldSetting: {
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
        {
            id: "excoTermIdForNominator2",
            lable: "EXCO Term",
            Field_Name: "EXCO Term",
            fieldType: FormFieldTypes.INPUT,
            dataField: "excoTermIdForNominator2",
            fieldSetting: {
                options: []
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
        {
            id: "uploadEndorsement2",
            lable: 'Upload Endorsement with Signature (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'Upload Endorsement with Signature (please upload in pdf/jpg format max 2mb)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadEndorsement2',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
                //imageCrop: true,
            },
            // validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
};