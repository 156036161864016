import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import FormCreator from '../../../../components/Forms/FormCreator';

const OrganisationDetails = forwardRef(({ formData, handleOnChangeFileUpload, handleChangeFileDownload }, ref) => {
  const organisationDetailsFormRef = useRef();

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = organisationDetailsFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const handleFormFieldChange = (dataField, value) => {
    if ((dataField === "acraBizFile") && value) {
      handleOnChangeFileUpload(dataField, value)
    }
  };
  // Form action handler to manage different form actions
  const formActionHandler = {
    // CLEAR: handleClear,
    DOWNLOAD: handleChangeFileDownload,
  }
  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={organisationDetailsFormRef}
          {...formData}
          onActionChange={formActionHandler}
          onFormFieldValueChange={handleFormFieldChange}
        ></FormCreator>
      </div>
    </div>

  )
}
)
export default OrganisationDetails