import React from 'react'

const Line = (props) => {
    return (
        <div className={props.containerCss}>
            <hr />
        </div>
    )
}

export default Line