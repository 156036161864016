import { FormFieldTypes } from "../../../data/formFieldType";

export const membershipPricingTableFormData = {
  name: 'CareerHistory',

  initialState: {

    cppMemberPricingid: null,
    cppStartUpApplicationFee: null,
    cppsmeApplicationFee: null,
    cppApplicationFee: null,
    cpP100ApplicationFee: null,
    cppStartUpFirstYearFee: null,
    cppsmeFirstYearFee: null,
    cppFirstYearFee: null,
    cpP100FirstYearFee: null,
    cppStartUpSubsequentYearFee: null,
    cppsmeSubsequentYearFee: null,
    cppSubsequentYearFee: null,
    cpP100SubsequentYearFee: null,
    cppStartUp2yearFee: null,
    cppsmE2yearFee: null,
    cpP2yearFee: null,
    cpP1002yearFee: null,
    cppStartUpComplimentaryindividualMembership: "Up to 100",
    cppsmeComplimentaryindividualMembership: "Up to 100",
    cppComplimentaryindividualMembership: "Up to 100",
    cpP100ComplimentaryindividualMembership: "No Limit",
    individualMemberPricingid: null,

    avipApplicationFee: null,
    ordinaryApplicationFee: null,
    ordinaryPath1ApplicationFee: null,
    ordinaryPath2ApplicationFee: null,
    ordinaryPath3ApplicationFee: null,
    associateApplicationFee: null,
    affilateApplicationFee: null,

    avipAnnualFee: null,
    ordinaryAnnualFee: null,
    ordinaryPath1AnnualFee: null,
    ordinaryPath2AnnualFee: null,
    ordinaryPath3AnnualFee: null,
    associateAnnualFee: null,
    affilateAnnualFee: null,

    aviP3yearFee: null,
    ordinary3yearFee: null,
    ordinaryPathOne3yearFee: null,
    ordinaryPathTwo3yearFee: null,
    ordinaryPathThree3yearFee: null,
    associate3yearFee: null,
    affilate3yearFee: null,

    avipProRatedFeeafter30thjune: null,
    ordinaryPath1ProRatedFeeafter30thjune: null,
    ordinaryPath2ProRatedFeeafter30thjune: null,
    ordinaryPath3ProRatedFeeafter30thjune: null,
    associateProRatedFeeafter30thjune: null,
    affilateProRatedFeeafter30thjune: null,

    academicMemberPricingid: null,
    appApplicationFee: null,
    appAnnualFee: null,
    appSecondYearFee: null,
    appEmployeeControl: "Up to 100",
    appStudentControl: "Unlimited"

  },

  formFields: [

    // title object start
    {
      id: "IndividualFee",
      lable: "",
      Field_Name: "Individual Fee",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "IndividualFee",
      fieldSetting: {
        formTitle: "Individual Fee",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "applicationFee",
      lable: "",
      Field_Name: "Application Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "applicationFee",
      fieldSetting: {
        formTitle: "Application Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "firstYearAnnualFee",
      lable: "",
      Field_Name: "1 Year Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "firstYearAnnualFee",
      fieldSetting: {
        formTitle: "1 Year Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "subsequentAnnualFee",
      lable: "",
      Field_Name: "Subsequent Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "subsequentAnnualFee",
      fieldSetting: {
        formTitle: "Subsequent Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "maintainingCertification",
      lable: "",
      Field_Name: "3 Year Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "maintainingCertification",
      fieldSetting: {
        formTitle: "3 Year Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid last-grid",
      },
    },

    // AVIP start

    {
      id: "AVIP",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "AVIP",
      fieldSetting: {
        formTitle: "AVIP",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "avipApplicationFee",
      lable: "",
      Field_Name: "apApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "avipAnnualFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "avipProRatedFeeafter30thjune",
      lable: "",
      Field_Name: "avipProRatedFeeafter30thjune",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipProRatedFeeafter30thjune",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "aviP3yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "aviP3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    // AVIP end


    // Ordinary Path 1 start
    {
      id: "OrdinaryPath1",
      lable: "",
      Field_Name: "Ordinary Path 1",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "OrdinaryPath1",
      fieldSetting: {
        formTitle: "Ordinary Path 1",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "ordinaryApplicationFee",
      lable: "",
      Field_Name: "aApApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryAnnualFee",
      lable: "",
      Field_Name: "ordinaryAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPath1ProRatedFeeafter30thjune",
      lable: "",
      Field_Name: "ordinaryPath1ProRatedFeeafter30thjune",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath1ProRatedFeeafter30thjune",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinary3yearFee",
      lable: "",
      Field_Name: "ordinary3yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinary3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //Ordinary Path 1 end


    // Ordinary Path 2 start
    {
      id: "OrdinaryPath2",
      lable: "",
      Field_Name: "Ordinary Path 2",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "OrdinaryPath2",
      fieldSetting: {
        formTitle: "Ordinary Path 2",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "ordinaryPath2ApplicationFee",
      lable: "",
      Field_Name: "aApApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath2ApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPath2AnnualFee",
      lable: "",
      Field_Name: "ordinaryAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath2AnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPath2ProRatedFeeafter30thjune",
      lable: "",
      Field_Name: "ordinaryPath2ProRatedFeeafter30thjune",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath2ProRatedFeeafter30thjune",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPathTwo3yearFee",
      lable: "",
      Field_Name: "ordinary3yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPathTwo3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //Ordinary Path 2 end


    // Ordinary Path 3 start
    {
      id: "OrdinaryPath3",
      lable: "",
      Field_Name: "Ordinary Path 3",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "OrdinaryPath3",
      fieldSetting: {
        formTitle: "Ordinary Path 3",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "ordinaryPath3ApplicationFee",
      lable: "",
      Field_Name: "aApApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath3ApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPath3AnnualFee",
      lable: "",
      Field_Name: "ordinaryAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath3AnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPath3ProRatedFeeafter30thjune",
      lable: "",
      Field_Name: "ordinaryPath3ProRatedFeeafter30thjune",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPath3ProRatedFeeafter30thjune",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "ordinaryPathThree3yearFee",
      lable: "",
      Field_Name: "ordinary3yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "ordinaryPathThree3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //Ordinary Path 3 end



    //  Associate object start
    {
      id: "Associate",
      lable: "",
      Field_Name: "Associate",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "Associate",
      fieldSetting: {
        formTitle: "Associate",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "associateApplicationFee",
      lable: "",
      Field_Name: "associateApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "associateApplicationFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "associateAnnualFee",
      lable: "",
      Field_Name: "associateAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "associateAnnualFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "associateProRatedFeeafter30thjune",
      lable: "",
      Field_Name: "associateProRatedFeeafter30thjune",
      fieldType: FormFieldTypes.INPUT,
      dataField: "associateProRatedFeeafter30thjune",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "associate3yearFee",
      lable: "",
      Field_Name: "associate3yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "associate3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //Associate object end


    // Affiliate charges
    {
      id: "Affiliate",
      lable: "",
      Field_Name: "Affiliate",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "Affiliate",
      fieldSetting: {
        formTitle: "Affiliate",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "affilateApplicationFee",
      lable: "",
      Field_Name: "affilateApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "affilateApplicationFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "affilateAnnualFee",
      lable: "",
      Field_Name: "affilateAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "affilateAnnualFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "affilateProRatedFeeafter30thjune",
      lable: "",
      Field_Name: "affilateProRatedFeeafter30thjune",
      fieldType: FormFieldTypes.INPUT,
      dataField: "affilateProRatedFeeafter30thjune",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "affilate3yearFee",
      lable: "",
      Field_Name: "affilate3yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "affilate3yearFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid last-grid",
      },
    },
    // Affiliate object end


    // title object start
    {
      id: "CorporateFee",
      lable: "",
      Field_Name: "Corporate Fee",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CorporateFee",
      fieldSetting: {
        formTitle: "Corporate Fee",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "applicationFee",
      lable: "",
      Field_Name: "Application Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "applicationFee",
      fieldSetting: {
        formTitle: "Application Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "firstYearAnnualFee",
      lable: "",
      Field_Name: "1 Year Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "firstYearAnnualFee",
      fieldSetting: {
        formTitle: "1 Year Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "subsequentAnnualFee",
      lable: "",
      Field_Name: "Subsequent Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "subsequentAnnualFee",
      fieldSetting: {
        formTitle: "Subsequent Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "maintainingCertification",
      lable: "",
      Field_Name: "3 Year Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "maintainingCertification",
      fieldSetting: {
        formTitle: "2 Year Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid last-grid",
      },
    },

    // CPP Start Up start

    {
      id: "CPPStartUp",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPPStartUp",
      fieldSetting: {
        formTitle: "CPP Start Up",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cppStartUpApplicationFee",
      lable: "",
      Field_Name: "apApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppStartUpApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppStartUpFirstYearFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppStartUpFirstYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppStartUpSubsequentYearFee",
      lable: "",
      Field_Name: "apSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppStartUpSubsequentYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppStartUp2yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppStartUp2yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    // CPP Start Up end


    // CPP SME start
    {
      id: "CPPSME",
      lable: "",
      Field_Name: "CPPSME",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP SME",
      fieldSetting: {
        formTitle: "CPP SME",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cppsmeApplicationFee",
      lable: "",
      Field_Name: "aApApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppsmeApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppsmeFirstYearFee",
      lable: "",
      Field_Name: "cppsmeFirstYearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppsmeFirstYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppsmeSubsequentYearFee",
      lable: "",
      Field_Name: "cppsmeSubsequentYearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppsmeSubsequentYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppsmE2yearFee",
      lable: "",
      Field_Name: "cppsmE2yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppsmE2yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //CPP SME end


    // CPP 100 start
    {
      id: "CPP100",
      lable: "",
      Field_Name: "CPP 100",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP100",
      fieldSetting: {
        formTitle: "CPP 100",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cpP100ApplicationFee",
      lable: "",
      Field_Name: "cpP100ApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cpP100ApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cpP100FirstYearFee",
      lable: "",
      Field_Name: "cpP100FirstYearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cpP100FirstYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cpP100SubsequentYearFee",
      lable: "",
      Field_Name: "cpP100SubsequentYearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cpP100SubsequentYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cpP1002yearFee",
      lable: "",
      Field_Name: "cpP1002yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cpP1002yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //CPP 100 end


    // CPP start
    {
      id: "CPP",
      lable: "",
      Field_Name: "CPP",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP",
      fieldSetting: {
        formTitle: "CPP",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cppApplicationFee",
      lable: "",
      Field_Name: "cppApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppFirstYearFee",
      lable: "",
      Field_Name: "cppFirstYearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppFirstYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cppSubsequentYearFee",
      lable: "",
      Field_Name: "cppSubsequentYearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppSubsequentYearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "cpP2yearFee",
      lable: "",
      Field_Name: "cpP2yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cpP2yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    //CPP end



    // title object start
    {
      id: "AcademicFee",
      lable: "",
      Field_Name: "Academic Fee",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "AcademicFee",
      fieldSetting: {
        formTitle: "Academic Fee",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "academinApplicationFee",
      lable: "",
      Field_Name: "Application Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "academinApplicationFee",
      fieldSetting: {
        formTitle: "Application Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "academinAnnualFee",
      lable: "",
      Field_Name: "1 Year Annual Fee (Before GST)111",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "academinAnnualFee",
      fieldSetting: {
        formTitle: "1 Year Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "appSubsequentAnnualFee",
      lable: "",
      Field_Name: "Subsequent Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "appSubsequentAnnualFee",
      fieldSetting: {
        formTitle: "Subsequent Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "app3yearFee",
      lable: "",
      Field_Name: "3 Year Annual Fee (Before GST)",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "app3yearFee",
      fieldSetting: {
        formTitle: "3 Year Annual Fee (Before GST)",
      },
      style: {
        containerCss: "custom-grid last-grid",
      },
    },

    // Academic start

    {
      id: "Academic",
      lable: "",
      Field_Name: "Academic",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "Academic",
      fieldSetting: {
        formTitle: "Academic",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "appApplicationFee",
      lable: "",
      Field_Name: "appApplicationFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "appApplicationFee",
      fieldSetting: {
        placeholder: "$ ",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "appAnnualFee",
      lable: "",
      Field_Name: "appAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "appAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "appSubsequentAnnualFee",
      lable: "",
      Field_Name: "appSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "appSubsequentAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "app3yearFee",
      lable: "",
      Field_Name: "app3yearFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "app3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid",
      },
    },
    // CPP Start Up end


    // Number of Complimentary Membership start
    {
      id: "NumberofComplimentaryMembership",
      lable: "",
      Field_Name: "Number of Complimentary Membership",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "NumberofComplimentaryMembership",
      fieldSetting: {
        formTitle: "Number of Complimentary Membership",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "UpTo",
      lable: "",
      Field_Name: "Up to",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "UpTo",
      fieldSetting: {
        formTitle: "Up to",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "",
      },
      style: {
        containerCss: "custom-grid",
      },
    },

    {
      id: "",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "",
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "",
      },
      style: {
        containerCss: "custom-grid last-grid",
      },
    },

    // CPP Start Up start  
    {
      id: "CPPStartUp",
      lable: "",
      Field_Name: "CPPStartUp",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP Start Up",
      fieldSetting: {
        formTitle: "CPP Start Up",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cppStartUpComplimentaryindividualMembership",
      lable: "",
      Field_Name: "cppStartUpComplimentaryindividualMembership",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppStartUpComplimentaryindividualMembership",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    // {
    //   id: "cppStartUpComplimentaryindividualMembership",
    //   lable: "",
    //   Field_Name: "CPP Start Up",
    //   fieldType: FormFieldTypes.INPUT,
    //   dataField: "cppStartUpComplimentaryindividualMembership",
    //   fieldSetting: {
    //     placeholder: " ",
    //     allowSpace: true,
    //     maskFormat: " ",
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "custom-grid",
    //   },
    // },
    {
      id: "avipAnnualFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "greenMarkApFees03",
      lable: "",
      Field_Name: "apSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "apSubsequentAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "aviP3yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "aviP3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    // CPP Start Up End

    // CPP SME start  
    {
      id: "CPPSME",
      lable: "",
      Field_Name: "CPPSME",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP SME",
      fieldSetting: {
        formTitle: "CPP SME",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cppsmeComplimentaryindividualMembership",
      lable: "",
      Field_Name: "cppsmeComplimentaryindividualMembership",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppsmeComplimentaryindividualMembership",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    // {
    //   id: "cppsmeComplimentaryindividualMembership",
    //   lable: "",
    //   Field_Name: "CPP Start Up",
    //   fieldType: FormFieldTypes.INPUT,
    //   dataField: "cppsmeComplimentaryindividualMembership",
    //   fieldSetting: {
    //     placeholder: " ",
    //     allowSpace: true,
    //     maskFormat: " ",
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "custom-grid",
    //   },
    // },
    {
      id: "avipAnnualFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "greenMarkApFees03",
      lable: "",
      Field_Name: "apSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "apSubsequentAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "aviP3yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "aviP3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    // CPP SME End

    // CPP 100 start  
    {
      id: "CPP100",
      lable: "",
      Field_Name: "CPP100",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP 100",
      fieldSetting: {
        formTitle: "CPP 100",
      },
      style: {
        containerCss: "category-title",
      },
    },
    // {
    //   id: "cpP100ComplimentaryindividualMembership",
    //   lable: "",
    //   Field_Name: "cpP100ComplimentaryindividualMembership",
    //   fieldType: FormFieldTypes.INPUT,
    //   dataField: "cpP100ComplimentaryindividualMembership",
    //   fieldSetting: {
    //     placeholder: " ",
    //     allowSpace: true,
    //     maskFormat: " ",
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "custom-grid",
    //   },
    // },
    {
      id: "cpP100ComplimentaryindividualMembership",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cpP100ComplimentaryindividualMembership",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    {
      id: "avipAnnualFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "greenMarkApFees03",
      lable: "",
      Field_Name: "apSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "apSubsequentAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "aviP3yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "aviP3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    // CPP 100 End

    // CPP start  
    {
      id: "CPP",
      lable: "",
      Field_Name: "CPP",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "CPP",
      fieldSetting: {
        formTitle: "CPP",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "cppComplimentaryindividualMembership",
      lable: "",
      Field_Name: "cppComplimentaryindividualMembership",
      fieldType: FormFieldTypes.INPUT,
      dataField: "cppComplimentaryindividualMembership",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    // {
    //   id: "cppComplimentaryindividualMembership",
    //   lable: "",
    //   Field_Name: "cppComplimentaryindividualMembership",
    //   fieldType: FormFieldTypes.INPUT,
    //   dataField: "cppComplimentaryindividualMembership",
    //   fieldSetting: {
    //     placeholder: " ",
    //     allowSpace: true,
    //     maskFormat: " ",
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "custom-grid",
    //   },
    // },
    {
      id: "avipAnnualFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "greenMarkApFees03",
      lable: "",
      Field_Name: "apSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "apSubsequentAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "aviP3yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "aviP3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    // CPP End

    // APP start  
    {
      id: "APP",
      lable: "",
      Field_Name: "APP",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "APP",
      fieldSetting: {
        formTitle: "APP",
      },
      style: {
        containerCss: "category-title",
      },
    },
    {
      id: "appEmployeeControl",
      lable: "",
      Field_Name: "cppComplimentaryindividualMembership",
      fieldType: FormFieldTypes.INPUT,
      dataField: "appEmployeeControl",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "custom-grid",
      },
    },
    // {
    //   id: "appEmployeeControl",
    //   lable: "",
    //   Field_Name: "appEmployeeControl",
    //   fieldType: FormFieldTypes.INPUT,
    //   dataField: "appEmployeeControl",
    //   fieldSetting: {
    //     placeholder: " ",
    //     allowSpace: true,
    //     maskFormat: " ",
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "custom-grid",
    //   },
    // },
    {
      id: "avipAnnualFee",
      lable: "",
      Field_Name: "apFirstYearAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "avipAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",

      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "greenMarkApFees03",
      lable: "",
      Field_Name: "apSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "apSubsequentAnnualFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    {
      id: "aviP3yearFee",
      lable: "",
      Field_Name: "aApSubsequentAnnualFee",
      fieldType: FormFieldTypes.INPUT,
      dataField: "aviP3yearFee",
      fieldSetting: {
        placeholder: "$",
        allowSpace: true,
        maskFormat: "$ ",
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "custom-grid invisible",
      },
    },
    // APP End
    //Number of Complimentary Membership end 

  ],
  formSetting: {
    isViewOnly: false,
  },
};

