import { EditCampaignFormData } from "../data/EditCampaignForm.data";

export const setEditEmailCampaignDropDown = (apiResponse) => {
    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "recipientsByTaggingSelect": filterDropDownList("Tag"),
        "recipientsByIndustrySelect": filterDropDownList("IndustryCode"),
        "recipientsByGenderSelect": filterDropDownList("Gender"),
        "recipientsByJobTitleSelect": filterDropDownList("JobLevel"),
        "recipientsByNationalitySelect": filterDropDownList("Nationality"),
        "recipientsByRaceSelect": filterDropDownList("Race"),
        "recipientsByResidenceStatusSelect": filterDropDownList("ResidentStatus"),
        "recipientsByStatusSelect": filterDropDownList("Status").filter(obj => [8, 16, 17, 18, 19, 20].includes(obj.value)),
        "recipientsByCampaignTypeSelect": filterDropDownList("CampaignType"),
    };

    const formFields = [
        ...EditCampaignFormData.formFields,
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
