import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";

const ViewIndividualMemberApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'ViewIndividualMemberApi',
    endpoints: (builder) => ({
        getIndividualMemberList: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/GetIndividualMemberList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        // GetAllDropdownsForIndividualMembers: builder.query({
        //     query: () => ({
        //         url: encryptQueryString('/Common/GetAllDropdownsForIndividualMembers'),
        //         method: 'GET',
        //     }),
        //     transformResponse: transformSucessResponse,
        //     transformErrorResponse: transformErrorResponse,
        // }),
        getIndividuaMemberDetailById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GetAllIndividualInformationDetailsById?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getIndividuaSIISAccountInfo: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GetIndividualSIISAccountInformationInformation?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        updateIndividuaMemberById: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/UpdateIndividualDetailsById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEngagementTrackingDetails: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/AddEngagementTrackingDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getIndividualEngagementTrackingList: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/IndividualMember/GetIndividualEngagementTrackingList?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        inserLogForIndividualMembershipAndSubscriptionChanges: builder.mutation({
            query: (details) => ({
                url: '/IndividualMember/inserLogForIndividualMembershipAndSubscriptionChanges',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetIndividualMemberListMutation,
    useLazyGetIndividuaMemberDetailByIdQuery,
    useLazyGetIndividuaSIISAccountInfoQuery,
    useUpdateIndividuaMemberByIdMutation,
    useAddEngagementTrackingDetailsMutation,
    useLazyGetIndividualEngagementTrackingListQuery,
    useInserLogForIndividualMembershipAndSubscriptionChangesMutation,
} = ViewIndividualMemberApi;

export default ViewIndividualMemberApi;