import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import FormCreator from '../../../../components/Forms/FormCreator';


const ProfessionalCertification = forwardRef(({ formData }, ref) => {
    const personalInformationFormRef = useRef();
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const data = personalInformationFormRef.current.getFormData();
                return data;
            }
            return null;
        }
    }));
    return (
        <div className="form-field-sec">
            <div className="row gx-5">
                <FormCreator
                    ref={personalInformationFormRef}
                    {...formData}
                ></FormCreator>
            </div>
        </div>
    )
})

export default ProfessionalCertification