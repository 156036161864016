import inidividualTasklistFormData from "../data/IndividualMemberTable.Data";
import { auditLogData } from "../data/AuditLog.Data";
import corporateMemberTasklistFormData, { inidividualFormData } from "../data/IndividualMemberTable.Data";

export const setAllDropDownField = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "statusId": filterDropDownList("TaskListStatus"),
        "salutationsID": filterDropDownList("Salutation"),
        "membershipTypeId": filterDropDownList("IndividualMembershipType").filter(obj => obj.value !== 12),
        "nationalityID": filterDropDownList("NationalityType"),
        "countryOfResID": filterDropDownList("Country"),
        "billingCategory": filterDropDownList("Individual Billing Category"),
        "updatedBy": filterDropDownList("IndividualMembershipType").filter(obj => obj.value !== 12),
        "membershipType": filterDropDownList("IndividualMembershipType").filter(obj => obj.value !== 12),
        "membershipTenureId": filterDropDownList("MembershipTenure").filter(obj => obj.value !== 2),
        "paymentTerms": filterDropDownList("Payment Terms"),
        "residentStatus": filterDropDownList("ResidentStatus"),
        "race": filterDropDownList("Race"),
        "currentMembershipTier": filterDropDownList("IndividualMembershipType").filter(obj => obj.value !== 12),
        "newMembershipTier": filterDropDownList("IndividualMembershipType").filter(obj => obj.value !== 12),
    };

    const formFields = [
        ...inidividualFormData.formFields,
        ...inidividualTasklistFormData.formFields
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};

export const setAuditLogList = (auditList) => {
    const auditLogInfoIndex = auditLogData.formFields.findIndex(field => field.id === "auditLogInfoTable");
    if (auditLogInfoIndex !== -1) {
        // Create a copy of careerHistoryData to avoid mutating state directly
        const updatedFormData = { ...auditLogData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[auditLogInfoIndex].fieldSetting.dataSource = auditList;
        return updatedFormData;
    }
}

export const hideShowAuditLog = (isPIC1) => {
    const auditLogSeperatorIndex = inidividualFormData.formFields.findIndex(field => field.id === "auditLog");
    const auditLogInfoTableIndex = inidividualFormData.formFields.findIndex(field => field.id === "auditLogInfoTable");
    const auditLogInternalNotesIndex = inidividualFormData.formFields.findIndex(field => field.id === "internalNotes");
    const sentBackToPIC1Index = inidividualFormData.formFields.findIndex(field => field.id === "sendBackToPIC1");

    const newCssValue = isPIC1 === false ? "col-lg-12 mb-2 text-left form-tab-header d-none" : "col-lg-12 mb-2 text-left form-tab-header";
    const newCssValue2 = isPIC1 === false ? "col-lg-12 mb-3 text-left d-none" : "col-lg-12 mb-3 text-left";
    const newCssValue3 = isPIC1 === false ? "col-lg-12 mb-3 d-none" : "col-lg-12 mb-3";
    const newCssValue4 = isPIC1 === false ? "col-lg-12 mb-3 text-end d-none" : "col-lg-12 mb-3 text-end";

    if (auditLogSeperatorIndex !== -1) {
        // Create a copy of careerHistoryData to avoid mutating state directly
        const updatedFormData = { ...inidividualFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[auditLogSeperatorIndex].style.containerCss = newCssValue;
    }
    if (auditLogInfoTableIndex !== -1) {
        // Create a copy of careerHistoryData to avoid mutating state directly
        const updatedFormData = { ...inidividualFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[auditLogInfoTableIndex].style.containerCss = newCssValue2;
    }
    if (auditLogInternalNotesIndex !== -1) {
        // Create a copy of careerHistoryData to avoid mutating state directly
        const updatedFormData = { ...inidividualFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[auditLogInternalNotesIndex].style.containerCss = newCssValue3;
    }
    if (sentBackToPIC1Index !== -1) {
        // Create a copy of careerHistoryData to avoid mutating state directly
        const updatedFormData = { ...inidividualFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[sentBackToPIC1Index].style.containerCss = newCssValue4;
    }
}
