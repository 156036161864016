import { FormFieldTypes } from "../../../../../data/formFieldType";

export const EmploymentInformationData = {
    name: "EmploymentInformationData",
    initialState: {
        organisationName : "",
        officeNumber : "",
        jobFunctionId: "",
        jobLevelId : "",
        industryCodeId : "",
        resumeFileName: "",
    },
    formFields: [
       
        {
            id: "organisationName",
            lable: "Organisation",
            Field_Name: "Organisation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "organisationName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-3  ",
            },
        },
        {
            id: "officeNo",
            lable: "Office Number",
            Field_Name: "Office Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "officeNo",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-3  ",
            },
        },
        {
            id: "jobFunctionId",
            lable: "Job Designation",
            Field_Name: "Job Designation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "jobFunctionId",
            fieldSetting: {
                options: []
            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
        {
            id: "jobLevelId",
            lable: "Job Level",
            Field_Name: "Job Level",
            fieldType: FormFieldTypes.SELECT,
            dataField: "jobLevelId",
            fieldSetting: {
                options: []
            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },

        {
            id: "industryCodeId",
            lable: "Industry Code",
            Field_Name: "Industry Code",
            fieldType: FormFieldTypes.SELECT,
            dataField: "industryCodeId",
            fieldSetting: {
                options: []
            },
            validation: [],
            style: {
                containerCss: "col-md-6 mb-3  "
            }
        },
        {
            id: "resumeFileName",
            lable: 'CV/Resume (please upload in pdf/jpg format max 2mb)',
            Field_Name: 'CV/Resume (please upload in pdf/jpg format max 2mb)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'resumeFileName',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.jpg, .png, .pdf',
                // imageCrop: true,
            },
            validation: [],
            style: {
                containerCss: "col-md-12 mb-12  "
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
};





