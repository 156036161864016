import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";

export const OutstandingInvoiceDetailModelData = {
    name: 'OutstandingInvoiceDetailModelData',
    initialState: {
        addPaymentProof: "",
        paymentComment: "",
    },
    formFields: [
        {
            id: "addPaymentProof",
            lable: "",
            Field_Name: "Payment Proof",
            fieldType: FormFieldTypes.FILE,
            dataField: "addPaymentProof",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isHideLabel: true,
                isHideViewButton: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-2 col-md-2 col-sm-3 col-12 mb-3 upload-btn",
            },
        },
        {
            id: "comment",
            lable: "",
            Field_Name: "Comments",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "Comments",
            fieldSetting: {
                formTitle: "Comments",
            },
            style: {
                containerCss: "col-lg-2 col-md-3 col-sm-3 col-12 mb-3 text-left mt-2 comments-title",
            },
        },
        {
            id: "paymentComment",
            lable: "",
            Field_Name: "",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "paymentComment",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-8 col-md-6 col-sm-6 col-12 mb-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}


export const PaymmentOutstandingInvoiceListConfig = {
    columns: [
        {
            name: "Invoice No",
            fieldName: "invoiceNo",
            allowShort: false,
        },
        {
            name: "Total Amount",
            fieldName: "totalAmount",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "paymentStatus",
            allowShort: false,
        },
        {
            name: "Date",
            fieldName: "membershipInvoiceDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {
                    name: "VIEW",
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],

        },
    ],
};
