import { FormFieldTypes } from "../../data/formFieldType";
import { GridColumnType } from "../../data/gridColumnType";


export const EmailManagementGridConfig = {
    columns: [
        {
            name: "Campaign Name",
            fieldName: "campaignName",
            allowShort: false,
        },
        {
            name: "Recipients",
            fieldName: "recipients",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "status",
            allowShort: false,
            // colSettings: {
            //     isOpenNewTab: true,
            //     url: "$website",
            // },
        },
        {
            name: "Date",
            fieldName: "date",
            colType: GridColumnType.DATE,
            allowShort: false,
            colSettings: {
                format: "DD/MMM/YYYY",
            },
        },
        {
            name: "Time",
            fieldName: "time",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "Edit", onClickAction: true },
                { name: "Preview", onClickAction: true },
                { name: "Duplicate", onClickAction: true },
                { name: "Delete", onClickAction: true },

            ],
            // actionHandler: {
            //   onEditAction: (data) => {
            //     alert("Edit called"); // Check navigate
            //   },
            //   onDeleteAction: (data) => {
            //     alert("delete called");
            //   },
            // },
        },
    ],
};
const EmailManagementFormData = {
    name: "Email From",
    initialState: {
        campaignName: null,
        fromDate: null,
        toDate: null,
        recipients: null,
    },
    formFields: [
        {
            id: "Campaign Name",
            lable: "Campaign Name",
            Field_Name: "campaignName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "campaignName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-11 col-xl-10 col-md-10 mb-3 "
            }
        },
        {
            id: "fromDate",
            lable: "From Date",
            Field_Name: "fromDate",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "fromDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-3 col-xl-3 col-md-3"
            }
        },
        {
            id: "toDate",
            lable: "To Date",
            Field_Name: "toDate",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "toDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-3 col-xl-3 col-md-3"
            }
        },
        {
            id: "Recipients",
            lable: "Recipients",
            Field_Name: "recipients",
            fieldType: FormFieldTypes.SELECT,
            dataField: "recipients",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [],
                // isMultiSelect: true,

            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-3 col-xl-4 col-md-4"
            }
        },
    ],
};

export default EmailManagementFormData;


