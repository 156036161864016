import React, { useEffect, useRef } from 'react'
import FormCreator from '../../components/Forms/FormCreator'
import CreateEmailTemplateFormData from './data/CreateEmailTemplateForm.Data.js';
import Buttons from '../../components/ui/button/Buttons';
import { useNavigate } from 'react-router-dom';
import SwalAlert from '../../services/swalService/SwalService';
import { useAddEditEmailTemplateMutation,useLazyGetAllDropdownsForAISP1Query } from '../../app/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi';
import DataLoader from '../../components/ui/dataLoader/DataLoader';





const CreateEmailTemplateForm = () => {
    const CreateEmailTemplateFormRef = useRef();
    const navigate = useNavigate();

    const { success, error } = SwalAlert()

    const [getAllDropdownsForAISP, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetAllDropdownsForAISP1Query()



    const [addEditEmailTemplate, {
        isSuccess: isSuccessAddEditEmail,
        isFetching: isFetchingAddEditEmail,
        data: addEditEmailResponse
    }] = useAddEditEmailTemplateMutation()




    useEffect(() => {
        if (!isFetchingAddEditEmail && isSuccessAddEditEmail && addEditEmailResponse) {
            if (addEditEmailResponse.hasError === false) {
                success("Email Added Successfully");
                handleBack();
            }
            else {
                error(addEditEmailResponse.errorMessage)
            }
        }
    }, [isFetchingAddEditEmail, isSuccessAddEditEmail, addEditEmailResponse]);




    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            let field = CreateEmailTemplateFormData?.formFields?.find(obj => obj.id === "project")
            field.fieldSetting.options = dropdownResponse?.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);

    useEffect(() => {
        getAllDropdownsForAISP();
    }, [])

    const handleaAdd = () => {
        const formData = CreateEmailTemplateFormRef.current.getFormData();
        const request = {
            emailTemplateId: 0,
            EmailName: formData.emailName,
            Subject: formData.subject,
            WhereUsed: formData.WhereUsed,
            ProjectTypeId: formData.projectType.value ? formData.projectType.value : formData.projectType,
            Description: formData.body,
            CreatedBy: 115,
            CreatedAppId: 1
        }

        addEditEmailTemplate(request)
    }

    const handleBack = () => { navigate('/emailTemplateManagement') }

    return (
        <div className="create-email-template">
            <div className="row align-items-center card-bg">
                <div className="custom-table-sec">
                    <div className='sub-title'>
                        <span>Email Template</span>
                        <hr />
                    </div>
                </div>
                {dropdownResponse ?
                    <div className='d-flex flex-wrap'>
                        <div className='row'>
                            <FormCreator
                                ref={CreateEmailTemplateFormRef}
                                {...CreateEmailTemplateFormData}
                            ></FormCreator>
                        </div>

                    </div>
                    :
                    <DataLoader />
                }

                <div className='col-12'>
                    <div className="button-part d-flex justify-content-between mt-2">

                        <div>
                            <Buttons
                                buttonText="Back"
                                buttonTypeClassName="outline-btn"
                                onClick={() => handleBack()}
                            />
                        </div>
                        <div>
                            <Buttons
                                buttonText="+ Add"
                                buttonTypeClassName="outline-btn"
                                onClick={() => handleaAdd()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateEmailTemplateForm

