/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FormCreator from "../../../components/Forms/FormCreator";
import { setAllDropDownField } from "../utils/setAllDropDownField";
import academicMemberTasklistFormData from "../data/AcademicMemberTable.Data";
import { useLazyGetAllDropdownsForAcademicMembersQuery } from "../../../app/services/Academic/AcademicTaskListApi";
import ModuleLoader from "../../../components/ui/moduleLoader/ModuleLoader";

const AcademicMemberSearchForm = forwardRef((props, ref) => {
    const searchFormRef = useRef();
    const [searchFormData, SetSearchForm] = useState(academicMemberTasklistFormData);

    const [getAllDropDowns, {
        data: dropdownResponse,
        isSuccess: isSuccess,
        isLoading: isLoading,
    }] = useLazyGetAllDropdownsForAcademicMembersQuery();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const searchFormResponse = searchFormRef.current.getFormData();
                return searchFormResponse;
            }
            return null;
        }
    }));

    useEffect(() => {
        if (!isLoading && isSuccess && dropdownResponse) {
            setAllDropDownField(dropdownResponse)
        }
    }, [isLoading, isSuccess, dropdownResponse])

    useEffect(() => { getAllDropDowns() }, [])

    return (isSuccess ? <FormCreator ref={searchFormRef} {...searchFormData} /> : <ModuleLoader />)

})

export default AcademicMemberSearchForm