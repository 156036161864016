import { NavigateButton } from "../../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";



const reportSearchFormData = {
  name: "paymentTaskData",
  initialState: {
    startdate: "",
    enddate: ""
  },
  formFields: [


    // {
    //   id: "startdate",
    //   lable: "Start Date",
    //   Field_Name: "StartDate",
    //   fieldType: FormFieldTypes.DATEPICKER,
    //   dataField: "startdate",
    //   fieldSetting: {
    //     placeholder: "",
    //     allowSpace: true,
    //     dateFormat: "dd/MM/yyyy",
    //     options: [],
    //     useMaxDate: true
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "col-md-4 mb-3"
    //   }
    // },
    // {
    //   id: "enddate",
    //   lable: "End Date",
    //   Field_Name: "EndDate",
    //   fieldType: FormFieldTypes.DATEPICKER,
    //   dataField: "enddate",
    //   fieldSetting: {
    //     placeholder: "",
    //     allowSpace: true,
    //     dateFormat: "dd/MM/yyyy",
    //     options: [],
    //     useMaxDate: true
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "col-md-4 mb-3"
    //   }
    // },

  ],
};
export default reportSearchFormData;