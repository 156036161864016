import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import {encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";

const AccountManagementApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'AccountManagementApi',
    endpoints: (builder) => ({
     
        addEditAdminDetails: builder.mutation({
            query: (details) => ({
                url: '/Account/AddEditAdminDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getRolesForAdmin: builder.query({
            query: (details) => ({
                url: `/Account/GetRolesForAdmin`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAdminDetailList: builder.mutation({
            query: (details) => ({
                url: '/Account/GetAdminDetailList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteAdminDetailsById: builder.mutation({
            query: (details) => ({
                url: '/Account/DeleteAdminDetailsById',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAdminDetailById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/Account/GetAdminDetailById?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
       
    }),
})

export const {
    useAddEditAdminDetailsMutation,
    useLazyGetRolesForAdminQuery,
    useGetAdminDetailListMutation,
    useDeleteAdminDetailsByIdMutation,
    useLazyGetAdminDetailByIdQuery

} = AccountManagementApi;

export default AccountManagementApi;