/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FormCreator from "../../../components/Forms/FormCreator";
import reportSearchFormData from "../data/ReportSearchForm.Data";

const ReportSearchForm = forwardRef((props, ref) => {
    const searchFormRef = useRef();
    const [searchFormData, SetSearchForm] = useState(reportSearchFormData);

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const searchFormResponse = searchFormRef.current.getFormData();
                return searchFormResponse;
            }
            return null;
        }
    }));
    return (<FormCreator ref={searchFormRef} {...searchFormData} />)
})

export default ReportSearchForm