import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import FormCreator from '../../../../components/Forms/FormCreator';

const CorporatePointOfContact = forwardRef(({ formData }, ref) => {
  const pointOfContactFormRef = useRef();

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = pointOfContactFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={pointOfContactFormRef}
          {...formData}
        ></FormCreator>
      </div>
    </div>
  )
}
)
export default CorporatePointOfContact