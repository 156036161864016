import React from 'react'
import './ViewEditAdminAccount.scss'
import ViewEditAdminAccountForm from '../../features/viewEditAdminAccount/ViewEditAdminAccountForm'

const ViewEditAdminAccount = () => {
    return (
        <div>
            <ViewEditAdminAccountForm />
        </div>
    )
}

export default ViewEditAdminAccount