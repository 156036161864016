import academicMemberTasklistFormData, { academicFormData } from "../data/AcademicMemberTable.Data";

export const setAllDropDownField = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "statusId": filterDropDownList("TaskListStatus"),
        "membershipTypeId": filterDropDownList("AcademicMembershipType"),
        "billingCategory": filterDropDownList("Academic Billing Category"),
        "updatedBy": filterDropDownList(""),
        "pricingMembershipType": filterDropDownList("AcademicMembershipType"),
        "paymentTerms": filterDropDownList("Payment Terms"),
        "membershipTenureId": filterDropDownList("MembershipTenure"),
    };

    const formFields = [
        ...academicMemberTasklistFormData.formFields,
        ...academicFormData.formFields
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
