import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";

// Transaction report table config
export const transactionReportTableConfig = {
    columns: [
        {
            name: "Invoice Number",
            fieldName: "invoiceNo",
            allowShort: false,
        },
        {
            name: "Payment Status",
            fieldName: "paymentStatus",
            allowShort: false,
        },
        {
            name: "payment Mode",
            fieldName: "paymentVia",
            allowShort: false,
        },
        {
            name: "Total Invoice Amount",
            fieldName: "totalPrice",
            allowShort: false,
        },
        {
            name: "Payment Date",
            fieldName: "invoiceDate",
            allowShort: false,
        },
        {
            name: "Order Id",
            fieldName: "transactionId",
            allowShort: false,
        },


    ],
};

// Trasaction report filter fields
const transactionReportFormData = {
    name: "transactionReportData",
    initialState: {
        startDate: "",
        endDate: "",
        // trasactionReportFor: ""
    },
    formFields: [
        {
            id: "startDate",
            lable: "Start Date",
            Field_Name: "Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "startDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                // options: [],
                //useMaxDate: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3"
            }
        },
        {
            id: "endDate",
            lable: "End Date",
            Field_Name: "End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "endDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                // options: [],
                //useMaxDate: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3"
            }
        },
        {
            id: "trasactionReportFor",
            lable: "Trasaction Report for",
            Field_Name: "Trasaction Report for",
            fieldType: FormFieldTypes.SELECT,
            dataField: "trasactionReportFor",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [
                    { value: 1, label: "Membership" },
                    { value: 2, label: "External Invoice" },
                ]
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-4 mb-3 invisible"
            }
        },
    ],
};
export default transactionReportFormData;